import _ from "lodash";
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));
        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(
          email

          //   ,{
          //   url:
          //     window.location.protocol + "//" + window.location.host + "/login",
          // }
        )
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**_______________________________________Database Start___________________________________________**/

  getUser = async () => {
    return new Promise((resolve, reject) => {
      return firebase.auth().onAuthStateChanged((user) => {
        if (user) resolve(user);
        else reject("No User");
      });
    });
  };
  //USER DETAILS
  readUserData = async (user_uid) => {
    return firebase
      .database()
      .ref(`partner/users/${user_uid}`)
      .once("value")
      .then((snapshot) => {
        const userdata = snapshot.val();
        if (userdata) {
          return userdata;
        } else {
          return "No User";
        }
      })
      .catch((error) => {
        this._handleError(error);
        return "No User";
      });

    // return new Promise((resolve, reject) => {
    //   return firebase
    //     .database()
    //     .ref(`partner/users/${user_uid}`)
    //     .on("value", (snapshot) => {
    //       const userdata = snapshot.val();
    //       if (userdata) {
    //         resolve(userdata);
    //       } else {
    //         reject("No User");
    //       }
    //     });
    // });
  };
  writeUserData = async () => {
    // return new Promise((resolve, reject) => {
    //   resolve("good");
    // });
    return "Good";
  };

  //SURVEY DATA AND ATTENDANCE
  readSurveyAndAttendaceData = async (userData) => {
    var { company_or_institution_name, all_programs } = userData;

    all_programs = _.map(all_programs, (val, uid) => {
      return { ...val, uid };
    });
    all_programs = all_programs[0].access;

    return firebase
      .database()
      .ref(
        `QR_Code/Survey_Submitted/${company_or_institution_name}/${all_programs}/All_Responses_Combined/all_questionnaire/All`
      )
      .once("value")
      .then((snapshot) => {
        const data = snapshot.val();
        return data;
      })
      .catch((error) => {
        this._handleError(error);
        return "No User";
      });
  };

  writeSurveyAndAttendaceData = async () => {
    // return new Promise((resolve, reject) => {
    //   resolve("good");
    // });
    return "Good";
  };

  //ARCHIVE SURVEY DATA AND ATTENDANCE
  read_archive_SurveyAndAttendaceData = async (userData) => {
    var { company_or_institution_name, all_programs } = userData;

    all_programs = _.map(all_programs, (val, uid) => {
      return { ...val, uid };
    });
    all_programs = all_programs[0].access;

    return firebase
      .database()
      .ref(
        `QR_Code/Archived_Survey_Submitted/${company_or_institution_name}/${all_programs}/All_Responses_Combined/all_questionnaire/All`
      )
      .once("value")
      .then((snapshot) => {
        const data = snapshot.val();
        return data;
      })
      .catch((error) => {
        this._handleError(error);
        return "No User";
      });
  };

  write_archive_SurveyAndAttendaceData = async () => {
    // return new Promise((resolve, reject) => {
    //   resolve("good");
    // });
    return "Good";
  };

  //BUILDER AND MANAGEMENT
  readQuestionnaire = async (userData) => {
    //CHANGE THIS RIGHT NOW
    var { company_or_institution_name, all_programs } = userData;

    all_programs = _.map(all_programs, (val, uid) => {
      return { ...val, uid };
    });
    all_programs = all_programs[0].access;
    var array = [];

    var main_url = `QR_Code/questionnaire/${company_or_institution_name}/${all_programs}`;
    var questionnaire_url = `QR_Code/questionnaire/${company_or_institution_name}/${all_programs}`;

    return firebase
      .database()
      .ref(main_url)
      .once("value")
      .then((snapshot) => {
        var data = snapshot.val();
        var data_array = _.map(snapshot.val(), (val, uid) => {
          return { ...val, uid };
        });

        var data_array_without_uid = _.map(snapshot.val(), (val, uid) => {
          return val;
        });

        array.push({
          program_name: all_programs,
          company_or_institution_name: company_or_institution_name,
          data,
          data_array,
          data_array_without_uid,
          user_details: userData,
          main_url,
          questionnaire_url,
        });
        return array;
      })
      .catch((error) => {
        this._handleError(error);
        return "No User";
      });
  };
  writeQuestionnaire = async () => {
    // return new Promise((resolve, reject) => {
    //   resolve("good");
    // });
    return "Good";
  };
  readQuestionnaireTemplate = async (userData) => {
    //CHANGE THIS RIGHT NOW
    var { company_or_institution_name, all_programs } = userData;

    all_programs = _.map(all_programs, (val, uid) => {
      return { ...val, uid };
    });
    all_programs = all_programs[0].access;
    var array = [];

    var main_url = `QR_Code/questionnaire_templates/${company_or_institution_name}/${all_programs}`;
    var questionnaire_url = `QR_Code/questionnaire/${company_or_institution_name}/${all_programs}`;

    return firebase
      .database()
      .ref(main_url)
      .once("value")
      .then((snapshot) => {
        var data = snapshot.val();
        var data_array = _.map(snapshot.val(), (val, uid) => {
          return { ...val, uid };
        });

        var data_array_without_uid = _.map(snapshot.val(), (val, uid) => {
          return val;
        });

        array.push({
          program_name: all_programs,
          company_or_institution_name: company_or_institution_name,
          data,
          data_array,
          data_array_without_uid,
          user_details: userData,
          main_url,
          questionnaire_url,
        });
        return array;
      })
      .catch((error) => {
        this._handleError(error);
        return "No User";
      });
  };
  writeQuestionnaireTemplate = async () => {
    // return new Promise((resolve, reject) => {
    //   resolve("good");
    // });
    return "Good";
  };

  readQRcode = async (userData) => {
    //CHANGE THIS RIGHT NOW
    var { company_or_institution_name, all_programs } = userData;

    all_programs = _.map(all_programs, (val, uid) => {
      return { ...val, uid };
    });
    all_programs = all_programs[0].access;
    var array = [],
      partial_questionaire_link = "",
      partial_unique_link = "";

    return firebase
      .database()
      .ref(
        `QR_Code/questionnaire/${company_or_institution_name}/${all_programs}`
      )
      .once("value")
      .then((snapshot) => {
        partial_questionaire_link = `QR_Code/questionnaire/${company_or_institution_name}/${all_programs}`;
        partial_unique_link = `QR_Code/unique/${company_or_institution_name}/${all_programs}`;

        const QR_Codes = _.map(snapshot.val(), (val, uid) => {
          const newVal = _.map(val, (val_, uid_) => {
            return { ...val_, uid_ };
          });
          return { data: newVal, uid };
        });

        array.push({
          program_name: all_programs,
          QR_Codes,
          partial_questionaire_link,
          partial_unique_link,
        });
        return array;
      })
      .catch((error) => {
        this._handleError(error);
        return "No User";
      });
  };
  writeQRcode = async () => {
    // return new Promise((resolve, reject) => {
    //   resolve("good");
    // });
    return "Good";
  };

  readAccess = async (userData) => {
    var { company_or_institution_name, all_programs, user_type } = userData;

    all_programs = _.map(all_programs, (val, uid) => {
      return { ...val, uid };
    });
    all_programs = all_programs[0].access;
    var array = [],
      partial_url = "";
    partial_url = `partner/user_access/${user_type}/${company_or_institution_name}/${all_programs}/Facilitator`;
    try {
      var data_access = await firebase
        .database()
        .ref(partial_url)
        .once("value")
        .then((snapshot) => {
          var data = snapshot.val();
          var data_array = _.map(snapshot.val(), (val, uid) => {
            return { ...val, uid };
          });
          return { data, data_array };
        })
        .catch((error) => {
          this._handleError(error);
          return "No User";
        });
    } catch (error) {
      this._handleError(error);

      return error;
    }

    return firebase
      .database()
      .ref(
        `QR_Code/questionnaire/${company_or_institution_name}/${all_programs}`
      )
      .once("value")
      .then((snapshot) => {
        const list_of_access_items = _.map(snapshot.val(), (val, uid) => {
          return { ...val, uid };
        });
        array.push({
          program_name: all_programs,
          data: data_access.data,
          data_array: data_access.data_array,
          details: userData,
          partial_url,
          list_of_access_items,
        });
        return array;
      })
      .catch((error) => {
        this._handleError(error);
        return "No User";
      });
  };
  writeAccess = async () => {
    // return new Promise((resolve, reject) => {
    //   resolve("good");
    // });
    return "Good";
  };

  /**_______________________________________Database End___________________________________________**/

  setLoggeedInUser = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
