import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
  //take,
} from "redux-saga/effects";
import {
  /* USER DETAILS */
  USER_DATA_READ,
  USER_DATA_WRITE,
  /* SURVEY DATA AND ATTENDANCE */
  QR_SURVEY_DATA_AND_ATTENDANCE_READ,
  QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,

  /* ARCHIVE SURVEY DATA AND ATTENDANCE */
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ,
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,

  /* QR CODE */
  QR_CODE_READ,
  QR_CODE_WRITE,
  /* BUILDER AND MANAGEMENT */
  QUESTIONNAIRE_READ,
  QUESTIONNAIRE_WRITE,
  QUESTIONNAIRE_TEMPLATE_READ,
  QUESTIONNAIRE_TEMPLATE_WRITE,
  /* ACCESS */
  ACCESS_READ,
  ACCESS_WRITE,
} from "./actionTypes";

import {
  /* USER DETAILS */
  user_data_read_async,
  user_data_write_async,
  /* SURVEY DATA AND ATTENDANCE */
  qr_survey_data_and_attendance_read_async,
  qr_survey_data_and_attendance_write_async,

  /* ARCHIVE SURVEY DATA AND ATTENDANCE */
  archive_qr_survey_data_and_attendance_read_async,
  archive_qr_survey_data_and_attendance_write_async,

  /* QR CODE */
  qr_code_read_async,
  qr_code_write_async,
  /* BUILDER AND MANAGEMENT */
  questionnaire_read_async,
  questionnaire_write_async,
  questionnaire_template_read_async,
  questionnaire_template_write_async,
  /* ACCESS */
  access_read_async,
  access_write_async,
  /*ERROR*/
  detail_error,
} from "./actions";

import { getFirebaseBackend } from "../../helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

/* USER DETAILS */
//generators
function* userDataRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);
    if (user && user !== "No User") {
      var user_uid = user.uid;
      const response = yield call(fireBaseBackend.readUserData, user_uid);
      yield put(user_data_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}
function* userDataWrite() {
  try {
    const response = yield call(fireBaseBackend.writeUserData, "SOSM", "SOM");
    yield put(user_data_write_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }
}

//watchers
export function* watchUserDataRead() {
  yield takeLatest(USER_DATA_READ, userDataRead);
  // yield takeEvery(USER_DATA_READ, userDataRead);
  // yield take(USER_DATA_READ, userDataRead);
}
export function* watchUserDataWrite() {
  yield takeEvery(USER_DATA_WRITE, userDataWrite);
}

/* SURVEY DATA AND ATTENDANCE */

//generators
function* qrSurveyDataAndAttendanceRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);
    if (user && user !== "No User") {
      var user_uid = user.uid;
      const userData = yield call(fireBaseBackend.readUserData, user_uid);
      const response = yield call(
        fireBaseBackend.readSurveyAndAttendaceData,
        userData
      );
      yield put(qr_survey_data_and_attendance_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}
function* qrSurveyDataAndAttendanceWrite() {
  try {
    const response = yield call(
      fireBaseBackend.writeSurveyAndAttendaceData,
      "SOSM",
      "SOM"
    );
    yield put(qr_survey_data_and_attendance_write_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }
}

//watchers
export function* watchQrSurveyDataAndAttendanceRead() {
  yield takeLatest(
    //yield takeEvery(
    QR_SURVEY_DATA_AND_ATTENDANCE_READ,
    qrSurveyDataAndAttendanceRead
  );
}
export function* watchQrSurveyDataAndAttendanceWrite() {
  yield takeEvery(
    QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,
    qrSurveyDataAndAttendanceWrite
  );
}

/* ARCHIVE SURVEY DATA AND ATTENDANCE */

//generators
function* archive_qrSurveyDataAndAttendanceRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);
    if (user && user !== "No User") {
      var user_uid = user.uid;
      const userData = yield call(fireBaseBackend.readUserData, user_uid);
      const response = yield call(
        fireBaseBackend.read_archive_SurveyAndAttendaceData,
        userData
      );
      yield put(archive_qr_survey_data_and_attendance_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}
function* archive_qrSurveyDataAndAttendanceWrite() {
  try {
    const response = yield call(
      fireBaseBackend.write_archive_SurveyAndAttendaceData,
      "SOSM",
      "SOM"
    );
    yield put(archive_qr_survey_data_and_attendance_write_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }
}

//watchers
export function* watch_archive_QrSurveyDataAndAttendanceRead() {
  yield takeLatest(
    //yield takeEvery(
    ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ,
    archive_qrSurveyDataAndAttendanceRead
  );
}
export function* watch_archive_QrSurveyDataAndAttendanceWrite() {
  yield takeEvery(
    ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,
    archive_qrSurveyDataAndAttendanceWrite
  );
}

/* QR CODE */
function* qrCodeRead() {
  /* try {
    const response = yield call(fireBaseBackend.readQRcode, "SOSM", "SOM");
    yield put(qr_code_read_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }
  */

  try {
    const user = yield call(fireBaseBackend.getUser);
    if (user && user !== "No User") {
      var user_uid = user.uid;
      const userData = yield call(fireBaseBackend.readUserData, user_uid);
      const response = yield call(fireBaseBackend.readQRcode, userData); //
      yield put(qr_code_read_async(response)); //
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}
function* qrCodeWrite() {
  try {
    const response = yield call(fireBaseBackend.writeQRcode, "SOSM", "SOM");
    yield put(qr_code_write_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }
}

//watchers
export function* watchQrCodeRead() {
  yield takeLatest(QR_CODE_READ, qrCodeRead);
}
export function* watchQrCodeWrite() {
  yield takeEvery(QR_CODE_WRITE, qrCodeWrite);
}

/* BUILDER AND MANAGEMENT */

function* questionnaireRead() {
  /* try {
    const response = yield call(
      fireBaseBackend.readQuestionnaire,
      "SOSM",
      "SOM"
    );
    yield put(questionnaire_read_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }*/
  try {
    const user = yield call(fireBaseBackend.getUser);
    if (user && user !== "No User") {
      var user_uid = user.uid;
      const userData = yield call(fireBaseBackend.readUserData, user_uid);
      const response = yield call(fireBaseBackend.readQuestionnaire, userData); //
      yield put(questionnaire_read_async(response)); //
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}
function* questionnaireWrite() {
  try {
    const response = yield call(
      fireBaseBackend.writeQuestionnaire,
      "SOSM",
      "SOM"
    );
    yield put(questionnaire_write_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }
}

function* questionnaireTemplateRead() {
  /*try {
    const response = yield call(
      fireBaseBackend.readQuestionnaireTemplate,
      "SOSM",
      "SOM"
    );
    yield put(questionnaire_template_read_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }*/
  try {
    const user = yield call(fireBaseBackend.getUser);
    if (user && user !== "No User") {
      var user_uid = user.uid;
      const userData = yield call(fireBaseBackend.readUserData, user_uid);
      const response = yield call(
        fireBaseBackend.readQuestionnaireTemplate,
        userData
      ); //
      yield put(questionnaire_template_read_async(response)); //
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}
function* questionnaireTemplateWrite() {
  try {
    const response = yield call(
      fireBaseBackend.writeQuestionnaireTemplate,
      "SOSM",
      "SOM"
    );
    yield put(questionnaire_template_write_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }
}

//watchers
export function* watchQuestionnaireRead() {
  yield takeLatest(QUESTIONNAIRE_READ, questionnaireRead);
}
export function* watchQuestionnaireWrite() {
  yield takeEvery(QUESTIONNAIRE_WRITE, questionnaireWrite);
}
export function* watchQuestionnaireTemplateRead() {
  yield takeLatest(QUESTIONNAIRE_TEMPLATE_READ, questionnaireTemplateRead);
}
export function* watchQuestionnaireTemplateWrite() {
  yield takeEvery(QUESTIONNAIRE_TEMPLATE_WRITE, questionnaireTemplateWrite);
}

/* ACCESS */

/* QR CODE */
function* accessRead() {
  /*try {
    const response = yield call(fireBaseBackend.readAccess, "SOSM", "SOM");
    yield put(access_read_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }*/

  try {
    const user = yield call(fireBaseBackend.getUser);
    if (user && user !== "No User") {
      var user_uid = user.uid;
      const userData = yield call(fireBaseBackend.readUserData, user_uid);
      const response = yield call(fireBaseBackend.readAccess, userData); //
      yield put(access_read_async(response)); //
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}
function* accessWrite() {
  try {
    const response = yield call(fireBaseBackend.writeAccess, "SOSM", "SOM");
    yield put(access_write_async(response));
  } catch (error) {
    yield put(detail_error(error));
  }
}

//watchers
export function* watchAccessRead() {
  yield takeLatest(ACCESS_READ, accessRead); //takeEvery(ACCESS_READ, accessRead);
}
export function* watchAccessWrite() {
  yield takeEvery(ACCESS_WRITE, accessWrite);
}

function* ProfileSaga() {
  yield all([
    // call(watchUserDataRead),
    // call(watchUserDataWrite),
    // call(watchQrSurveyDataAndAttendanceRead),
    // call(watchQrSurveyDataAndAttendanceWrite),
    // call(watchQrCodeRead),
    // call(watchQrCodeWrite),
    // call(watchQuestionnaireRead),
    // call(watchQuestionnaireWrite),
    // call(watchQuestionnaireTemplateRead),
    // call(watchQuestionnaireTemplateWrite),
    // call(watchAccessRead),
    // call(watchAccessWrite),

    fork(watchUserDataRead),
    fork(watchUserDataWrite),
    fork(watchQrSurveyDataAndAttendanceRead),
    fork(watchQrSurveyDataAndAttendanceWrite),
    fork(watchQrCodeRead),
    fork(watchQrCodeWrite),
    fork(watchQuestionnaireRead),
    fork(watchQuestionnaireWrite),
    fork(watchQuestionnaireTemplateRead),
    fork(watchQuestionnaireTemplateWrite),
    fork(watchAccessRead),
    fork(watchAccessWrite),

    fork(watch_archive_QrSurveyDataAndAttendanceRead),
    fork(watch_archive_QrSurveyDataAndAttendanceWrite),
  ]);
}

export default ProfileSaga;
