import React, { Component } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import { Link } from "react-router-dom";

import ReactApexChart from "react-apexcharts";
import ReactEcharts from "echarts-for-react";

class Earning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: "series1",
          data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76],
        },
      ],
      options: {
        chart: {
          toolbar: "false",
          dropShadow: {
            enabled: !0,
            color: "#000",
            top: 18,
            left: 7,
            blur: 8,
            opacity: 0.2,
          },
        },
        dataLabels: {
          enabled: !1,
        },
        colors: ["#556ee6"],
        stroke: {
          curve: "smooth",
          width: 3,
        },
      },
    };
  }

  chart_processesing() {
    var very_satisfied = this.props.data.csat.very_satisfied;
    var satisfied = this.props.data.csat.satisfied;
    var neutral = this.props.data.csat.neutral;
    var dissatisfied = this.props.data.csat.dissatisfied;
    var very_dissatisfied = this.props.data.csat.very_dissatisfied;

    var total_csat_questions = this.props.data.total_questions;

    var data_csat_graph = {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c} ({d}%)",
      },

      color: ["#64d39c", "#80CBC4", "#f9b683", "#E57373", "#c94552"],
      series: [
        {
          type: "pie",
          radius: ["65%", "85%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "24",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            {
              value: very_satisfied,
              name: "Very Satisfied",
            },
            {
              value: satisfied,
              name: "Satisfied",
            },
            {
              value: neutral,
              name: "Neutral",
            },
            {
              value: dissatisfied,
              name: "Unsatisfied",
            },
            {
              value: very_dissatisfied,
              name: "Very Unsatisfied",
            },
          ],
        },
      ],
    };
    var positive_score_add = (
      (very_satisfied / total_csat_questions) * 100 +
      (satisfied / total_csat_questions) * 100
    ).toFixed(1);

    return { data_csat_graph, positive_score_add };
  }
  render_custom_legend() {
    var very_satisfied = this.props.data.csat.very_satisfied;
    var satisfied = this.props.data.csat.satisfied;
    var neutral = this.props.data.csat.neutral;
    var dissatisfied = this.props.data.csat.dissatisfied;
    var very_dissatisfied = this.props.data.csat.very_dissatisfied;

    var total_csat_questions = this.props.data.total_questions;

    return (
      <Row>
        <Col lg="12">
          <div className="table-responsive mt-4">
            <Table className="table-centered mb-0">
              <tbody>
                <tr>
                  <td>
                    <i
                      className="bx bx-happy-alt"
                      style={{ fontSize: 32, color: "#64d39c" }}
                    ></i>
                  </td>

                  <td>
                    <div id="radialchart-1" className="apex-charts">
                      <h5 className="font-size-14 mb-1">Very Satisfied</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="mb-0" style={{ color: "#64d39c" }}>
                      {((very_satisfied / total_csat_questions) * 100).toFixed(
                        1
                      )}
                      %
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i
                      className="bx bx-smile"
                      style={{ fontSize: 32, color: "#80CBC4" }}
                    ></i>
                  </td>

                  <td>
                    <div id="radialchart-2" className="apex-charts">
                      <h5 className="font-size-14 mb-1">Satisfied</h5>
                    </div>
                  </td>
                  <td>
                    <h5 style={{ color: "#80CBC4" }} className="mb-0">
                      {((satisfied / total_csat_questions) * 100).toFixed(1)}%
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i
                      className="bx bx-meh"
                      style={{ fontSize: 32, color: "#f9b683" }}
                    ></i>
                  </td>

                  <td>
                    <div id="radialchart-3" className="apex-charts">
                      <h5 className="font-size-14 mb-1">Neutral</h5>
                    </div>
                  </td>
                  <td>
                    <h5 style={{ color: "#f9b683" }} className="mb-0">
                      {((neutral / total_csat_questions) * 100).toFixed(1)} %
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i
                      className="bx bx-sad"
                      style={{ fontSize: 32, color: "#E57373" }}
                    ></i>
                  </td>

                  <td>
                    <div id="radialchart-3" className="apex-charts">
                      <h5 className="font-size-14 mb-1">Unsatisfied</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="mb-0" style={{ color: "#E57373" }}>
                      {((dissatisfied / total_csat_questions) * 100).toFixed(1)}{" "}
                      %
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i
                      className="bx bx-angry"
                      style={{ fontSize: 32, color: "#c94552" }}
                    ></i>
                  </td>

                  <td>
                    <div id="radialchart-3" className="apex-charts">
                      <h5 className="font-size-14 mb-1">Very Unsatisfied</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="mb-0" style={{ color: "#c94552" }}>
                      {(
                        (very_dissatisfied / total_csat_questions) *
                        100
                      ).toFixed(1)}{" "}
                      %
                    </h5>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    if (this.props.data === null) {
      return null;
    }
    /// return <h4 className="card-title mb-4">Customer Satisfaction</h4>;
    const { data_csat_graph, positive_score_add } = this.chart_processesing();
    return (
      <React.Fragment>
        {/*} <Col xl="8">
        <Card>*/}
        <div style={{ paddingTop: 20 }}>
          <CardBody>
            {/*   <div className="clearfix">
            <h4 className="card-title mb-4">Customer Satisfaction</h4>
          </div>*/}

            <Row>
              <Col lg="12">
                <h3 className="text-center">
                  {positive_score_add}% Positive Experiences
                </h3>
              </Col>
              <Col lg="7">
                <div className="text-muted text-center">
                  <div id="doughnut-chart" className="e-chart">
                    <ReactEcharts option={data_csat_graph} />
                  </div>
                </div>
              </Col>

              <Col lg="5">{this.render_custom_legend()}</Col>
            </Row>
          </CardBody>
        </div>
        {/* </Card>
        </Col>*/}
      </React.Fragment>
    );
  }
}

export default Earning;
