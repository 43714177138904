import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Dashboard from "./Dashboard";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const {
    //   user_details,
    //   surveys_and_attendance,
    //   qr_details,
    //   access,
    //   questionnaire,
    //   questionnaire_template,
    //   error,
    //   loading,
    // } = this.props;
    // console.log({
    //   user_details,
    //   surveys_and_attendance,
    //   qr_details,
    //   access,
    //   questionnaire,
    //   questionnaire_template,
    //   error,
    //   loading,
    // });

    //  return <Dashboard />;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
            <Row>
              <Col lg="12">
                <Dashboard />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, null)(withNamespaces()(Index))
);

/*
import React, { Component } from "react";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// // actions
// import {
//   // USER DETAILS
//   user_data_read,
//   user_data_write,
//   //SURVEY DATA AND ATTENDANCE 
//   qr_survey_data_and_attendance_read,
//   qr_survey_data_and_attendance_write,
//   //QR CODE 
//   qr_code_read,
//   qr_code_write,
//   // BUILDER AND MANAGEMENT
//   questionnaire_read,
//   questionnaire_write,
//   questionnaire_template_read,
//   questionnaire_template_write,
//   // ACCESS 
//   access_read,
//   access_write,
//   // ERROR
//   detail_error,
// } from "../../store/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   //USER DETAILS 
  //   this.props.user_data_read();
  //   this.props.user_data_write();
  //   //SURVEY DATA AND ATTENDANCE 
  //   this.props.qr_survey_data_and_attendance_read();
  //   this.props.qr_survey_data_and_attendance_write();
  //   // QR CODE 
  //   this.props.qr_code_read();
  //   this.props.qr_code_write();
  //   // BUILDER AND MANAGEMENT 
  //   this.props.questionnaire_read();
  //   this.props.questionnaire_write();
  //   this.props.questionnaire_template_read();
  //   this.props.questionnaire_template_write();
  //   // ACCESS 
  //   this.props.access_read();
  //   this.props.access_write();
  //   //ERROR
  //   this.props.detail_error();
  // }

  render() {
    const {
      user_details,
      surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;
    console.log({
      user_details,
      surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    });
    return (
      <React.Fragment>
        <div className="page-content"></div>
      </React.Fragment>
    );
  }
}

//export default withNamespaces()(Dashboard);

const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(
    mapStatetoProps,
    null
    //   {
    //   // USER DETAILS
    //   user_data_read,
    //   user_data_write,
    //   // SURVEY DATA AND ATTENDANCE
    //   qr_survey_data_and_attendance_read,
    //   qr_survey_data_and_attendance_write,
    //   // QR CODE
    //   qr_code_read,
    //   qr_code_write,
    //   // BUILDER AND MANAGEMENT
    //   questionnaire_read,
    //   questionnaire_write,
    //   questionnaire_template_read,
    //   questionnaire_template_write,
    //   // ACCESS
    //   access_read,
    //   access_write,
    //   // ERROR
    //   detail_error,
    // }
  )(withNamespaces()(Index))
);

*/
