import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
// Redux
import { connect } from "react-redux";

// actions
import {
  /* USER DETAILS */
  user_data_read,
  user_data_write,
  /* SURVEY DATA AND ATTENDANCE */
  qr_survey_data_and_attendance_read,
  qr_survey_data_and_attendance_write,
  /* QR CODE */
  qr_code_read,
  qr_code_write,
  /* BUILDER AND MANAGEMENT */
  questionnaire_read,
  questionnaire_write,
  questionnaire_template_read,
  questionnaire_template_write,
  /* ACCESS */
  access_read,
  access_write,
  /*ERROR*/
  detail_error,
} from "../../store/actions"; //"../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
    /* USER DETAILS */
    this.props.user_data_read();
    this.props.user_data_write();
    /* SURVEY DATA AND ATTENDANCE */
    this.props.qr_survey_data_and_attendance_read();
    this.props.qr_survey_data_and_attendance_write();
    /* QR CODE */
    this.props.qr_code_read();
    this.props.qr_code_write();
    /* BUILDER AND MANAGEMENT */
    this.props.questionnaire_read();
    this.props.questionnaire_write();
    this.props.questionnaire_template_read();
    this.props.questionnaire_template_write();
    /* ACCESS */
    this.props.access_read();
    this.props.access_write();
    /*ERROR*/
    this.props.detail_error();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  full_permission_retail_menu() {
    return (
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{this.props.t("Menu")}</li>
          <li>
            <Link to="/#" className="waves-effect">
              <i className="bx bx-home-circle"></i>
              <span>{this.props.t("Dashboard")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/dashboard">{this.props.t("Performance")}</Link>
              </li>
              <li>
                <Link to="/charts">{this.props.t("Pie Charts")}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/QR" className="waves-effect">
              <i className="bx bx-barcode"></i>
              <span>{this.props.t("Survey QR")}</span>
            </Link>
          </li>
          {/*<li>
            <Link to="/attendance" className="waves-effect">
              <i className="bx bx-user"></i>
              <span>{this.props.t("Attendance")}</span>
            </Link>
          </li>*/}
          <li>
            <Link to="/surveyBuilder" className="waves-effect">
              <i className="bx bx-building"></i>
              <span>{this.props.t("Survey Builder")}</span>
            </Link>
          </li>
          <li>
            <Link to="/surveyManagement" className="waves-effect">
              <i className="bx bx-message-square-edit"></i>
              <span>{this.props.t("Survey Management")}</span>
            </Link>
          </li>
          <li>
            <Link to="/access" className="waves-effect">
              <i className="bx bx-filter"></i>
              <span>{this.props.t("Access")}</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }

  full_permission_menu_starter() {
    return (
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{this.props.t("Menu")}</li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-home-circle"></i>
              <span>{this.props.t("Dashboard")}</span>
            </Link>
          </li>

          <li>
            <Link to="/QR" className="waves-effect">
              <i className="bx bx-barcode"></i>
              <span>{this.props.t("Survey QR")}</span>
            </Link>
          </li>
          <li>
            <Link to="/attendance" className="waves-effect">
              <i className="bx bx-user"></i>
              <span>{this.props.t("Attendance")}</span>
            </Link>
          </li>
          <li>
            <Link to="/surveyBuilder" className="waves-effect">
              <i className="bx bx-building"></i>
              <span>{this.props.t("Survey Builder")}</span>
            </Link>
          </li>
          <li>
            <Link to="/surveyManagement" className="waves-effect">
              <i className="bx bx-message-square-edit"></i>
              <span>{this.props.t("Survey Management")}</span>
            </Link>
          </li>

          {/*<li>
            <Link to="/access" className="waves-effect">
              <i className="bx bx-filter"></i>
              <span>{this.props.t("Access")}</span>
            </Link>
          </li>*/}
        </ul>
      </div>
    );
  }

  full_permission_menu() {
    return (
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{this.props.t("Menu")}</li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-home-circle"></i>
              {/* <span className="badge badge-pill badge-info float-right">
                  03
               </span>*/}
              <span>{this.props.t("Dashboard")}</span>
            </Link>
            {/*  <ul className="sub-menu" aria-expanded="false">
                  <li><Link to="/dashboard">{this.props.t('Default') }</Link></li>
            </ul>*/}
          </li>

          <li>
            <Link to="/QR" className="waves-effect">
              <i className="bx bx-barcode"></i>
              <span>{this.props.t("Survey QR")}</span>
            </Link>
          </li>
          <li>
            <Link to="/attendance" className="waves-effect">
              <i className="bx bx-user"></i>
              <span>{this.props.t("Attendance")}</span>
            </Link>
          </li>
          <li>
            <Link to="/surveyBuilder" className="waves-effect">
              <i className="bx bx-building"></i>
              <span>{this.props.t("Survey Builder")}</span>
            </Link>
          </li>
          <li>
            <Link to="/surveyManagement" className="waves-effect">
              <i className="bx bx-message-square-edit"></i>
              <span>{this.props.t("Survey Management")}</span>
            </Link>
          </li>
          <li>
            <Link to="/access" className="waves-effect">
              <i className="bx bx-filter"></i>
              <span>{this.props.t("Access")}</span>
            </Link>
          </li>

          <li>
            <Link to="/#" className="waves-effect">
              <i className="bx bx-archive"></i>
              <span>{this.props.t("Archive")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/archived-dashboard">
                  {this.props.t("Dashboard")}
                </Link>
              </li>
              <li>
                <Link to="/archived-attendance">
                  {this.props.t("Attendance")}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }

  full_restricted_menu() {
    const { user_details } = this.props;
    const {
      user_type,
      role,
      survey_builder_access,
      qr_access_granted,
      dashboard_access,
    } = user_details;
  

    if (dashboard_access || qr_access_granted || survey_builder_access) {
      var dash_access = false,
        qr_access = false,
        builder_access = false;

      if (dashboard_access) {
        dash_access = dashboard_access.access;
      }
      if (qr_access_granted) {
        qr_access = qr_access_granted.access;
      }
      if (survey_builder_access) {
        builder_access = survey_builder_access.access;
      }

      return (
        <React.Fragment>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              {dash_access === true || dash_access === "true" ? (
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="bx bx-home-circle"></i>

                    <span>{this.props.t("Dashboard")}</span>
                  </Link>
                </li>
              ) : null}

              {qr_access === true || qr_access === "true" ? (
                <li>
                  <Link to="/QR" className="waves-effect">
                    <i className="bx bx-barcode"></i>
                    <span>{this.props.t("Survey QR")}</span>
                  </Link>
                </li>
              ) : null}

              {builder_access === true || builder_access === "true" ? (
                <li>
                  <Link to="/surveyBuilder" className="waves-effect">
                    <i className="bx bx-building"></i>
                    <span>{this.props.t("Survey Builder")}</span>
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
            </ul>
          </div>
        </React.Fragment>
      );
    }
  }



  individual_user_menu(){
   


    return (
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{this.props.t("Menu")}</li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-home-circle"></i>
    
              <span>{this.props.t("Dashboard")}</span>
            </Link>
           
          </li>

          <li>
            <Link to="/QR" className="waves-effect">
              <i className="bx bx-barcode"></i>
              <span>{this.props.t("Survey QR")}</span>
            </Link>
          </li>
          {/*<li>
            <Link to="/attendance" className="waves-effect">
              <i className="bx bx-user"></i>
              <span>{this.props.t("Attendance")}</span>
            </Link>
          </li>*/}
          <li>
            <Link to="/surveyBuilder" className="waves-effect">
              <i className="bx bx-building"></i>
              <span>{this.props.t("Survey Builder")}</span>
            </Link>
          </li>
          <li>
            <Link to="/surveyManagement" className="waves-effect">
              <i className="bx bx-message-square-edit"></i>
              <span>{this.props.t("Survey Management")}</span>
            </Link>
          </li>
     

          <li>
            <Link to="/#" className="waves-effect">
              <i className="bx bx-archive"></i>
              <span>{this.props.t("Archive")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/archived-dashboard">
                  {this.props.t("Dashboard")}
                </Link>
              </li>
              <li>
                <Link to="/archived-attendance">
                  {this.props.t("Attendance")}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );



  }
  render() {
    const { user_details } = this.props;
    // console.log(user_details);
    if (user_details === null) {
      return (
        <React.Fragment>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
            </ul>
          </div>
        </React.Fragment>
      );
    } else {
      const {
        user_type,
        role,
        plan_selected,
        survey_builder_access,
        qr_access_granted,
        dashboard_access,
      } = user_details;

      if (user_type === "institute") {
        if (plan_selected === "Starter") {
          return (
            <React.Fragment>
              {this.full_permission_menu_starter()}
            </React.Fragment>
          );
        } else {
          if (role === "Facilitator") {
            return (
              <React.Fragment>{this.full_restricted_menu()}</React.Fragment>
            );
          }
          else if (role === "Individual Users"){

return (              <React.Fragment>{this.individual_user_menu()}</React.Fragment>
)
           


          }
          return <React.Fragment>{this.full_permission_menu()}</React.Fragment>;
        }
      } else {
        return (
          <React.Fragment>{this.full_permission_retail_menu()}</React.Fragment>
        );
      }
    }
  }
}

//export default withRouter(withNamespaces()(SidebarContent));
const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    /* USER DETAILS */
    user_data_read,
    user_data_write,
    /* SURVEY DATA AND ATTENDANCE */
    qr_survey_data_and_attendance_read,
    qr_survey_data_and_attendance_write,
    /* QR CODE */
    qr_code_read,
    qr_code_write,
    /* BUILDER AND MANAGEMENT */
    questionnaire_read,
    questionnaire_write,
    questionnaire_template_read,
    questionnaire_template_write,
    /* ACCESS */
    access_read,
    access_write,
    /*ERROR*/
    detail_error,
  })(withNamespaces()(SidebarContent))
);
