import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import ReactEcharts from "echarts-for-react";

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  isOdd(num) {
    return num % 2;
  }

  // getOption = () => {

  getOption(data) {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c} ({d}%)",
      },

      legend: {
        //   orient: "vertical",
        orient: "horizontal",
        x: "left",
        data: data.labels,
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
      series: [
        {
          //   name: "Total sales",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },

          data: data.data,
        },
      ],
    };
  }

  render() {
    const { showcase_data } = this.props;

    var single_choice_data = showcase_data.multiple_choice_item_render;

    if (single_choice_data) {
      //   console.log(single_choice_data);
      var show = [];
      var isOdd = this.isOdd(single_choice_data.length);
      single_choice_data.forEach((element, i) => {
        // console.log(i);
        if (isOdd === 1) {
          if (single_choice_data.length - 1 === i) {
            show.push(
              <Col lg="12" key={i}>
                <Card>
                  <CardBody>
                    <CardTitle>{element.question}</CardTitle>
                    <div id="doughnut-chart" className="e-chart">
                      <ReactEcharts
                        style={{
                          height: "450px",
                          //height: "350px"
                        }}
                        option={this.getOption(element)}
                      />{" "}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          } else {
            show.push(
              <Col lg="6" key={i}>
                <Card>
                  <CardBody>
                    <CardTitle>{element.question}</CardTitle>
                    <div id="doughnut-chart" className="e-chart">
                      <ReactEcharts
                        style={{
                          height: "450px",
                          //height: "350px"
                        }}
                        option={this.getOption(element)}
                      />{" "}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          }
          //
        } else {
          show.push(
            <Col lg="6" key={i}>
              <Card>
                <CardBody>
                  <CardTitle>{element.question}</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <ReactEcharts
                      style={{
                        height: "450px",
                        //height: "350px"
                      }}
                      option={this.getOption(element)}
                    />{" "}
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        }
      });

      //var show_;
      //  });
      //show
      return (
        <React.Fragment>
          <Row>{show} </Row>
        </React.Fragment>
      );
    }
    return null;
  }
}

export default Charts;
