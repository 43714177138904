import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  Label,
  Input,
  Form,
  ModalHeader,
  Alert,
} from "reactstrap";

import { withRouter, Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

// users
import firebase from "firebase/app";
import "firebase/database";

// users
// import user1 from "../../../assets/images/users/avatar-1.jpg";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: "",
      password: "",
      re_type_password: "",
      modal: false,
      modal_email: false,
      reLogin: false,
      success: "",
      error: "",
      show_message: false,
      email: "",
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  onSubmit(event) {
    event.preventDefault();

    var user = firebase.auth().currentUser;
    const { password, re_type_password } = this.state;

    if (password === re_type_password) {
      user
        .updatePassword(password)
        .then(() => {
          this.setState({
            success: "Your password has been updated!",
            show_message: true,
            password: "",
            re_type_password: "",
          });
        })
        .catch((error) => {
          var temp_error = JSON.stringify(error);

          if (error.code === "auth/requires-recent-login") {
            this.setState({
              reLogin: true,
              password: "",
              re_type_password: "",
            });
          } else {
            alert(error.message);
          }
        });
    } else {
      this.setState({
        error: "Passwords do not match. Please re-enter again.",
        show_message: true,
        password: "",
        re_type_password: "",
      });
    }
  }
  modal() {
    const { delete_user_data } = this.state;
    var user = firebase.auth().currentUser;

    var user_uid = "";
    if (user) {
      user_uid = user.uid;
    }

    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              this.setState((prevState) => ({
                modal: !prevState.modal,
                menu: false,
                name: "",
                password: "",
                re_type_password: "",
                modal: false,
                reLogin: false,
                success: "",
                error: "",
                show_message: false,
              }));
            }}
          >
            Change Password
          </ModalHeader>

          <ModalBody>
            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              <div className="form-group">
                {this.state.reLogin !== true ? (
                  <div>
                    <Form onSubmit={this.onSubmit.bind(this)}>
                      <div>
                        <Label htmlFor="password">New Password : </Label>
                        <Input
                          minlength="6"
                          id="password"
                          placeholder="Enter New Password"
                          className="form-control"
                          required
                          type="password"
                          value={this.state.password}
                          onChange={(event) => {
                            event.preventDefault();
                            this.setState({
                              password: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Label htmlFor="repassword">
                          Re-type New Password :{" "}
                        </Label>
                        <Input
                          minlength="6"
                          id="repassword"
                          placeholder="Enter New Password"
                          className="form-control"
                          required
                          type="password"
                          value={this.state.re_type_password}
                          onChange={(event) => {
                            event.preventDefault();

                            this.setState({
                              re_type_password: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div>
                        {user_uid === "tfVyqHtcJXVfQWfuGEzbMWugbWx1" ? (
                          <div>
                            <Button type="submit" color="primary" disabled>
                              Change Password
                            </Button>
                            <p style={{ color: "#B00020" }}>
                              Since this is a demo account, updating password is
                              disabled!
                            </p>
                          </div>
                        ) : (
                          <Button type="submit" color="primary">
                            Change Password
                          </Button>
                        )}
                      </div>
                    </Form>

                    {this.state.show_message === true ? (
                      <div>
                        {this.state.success !== "" ||
                        this.state.error !== "" ? (
                          <div>
                            {this.state.success === "" ? (
                              <div>
                                <Alert
                                  color="danger"
                                  style={{ marginTop: "13px" }}
                                >
                                  {this.state.error}
                                </Alert>
                              </div>
                            ) : (
                              <div>
                                <Alert
                                  color="success"
                                  style={{ marginTop: "13px" }}
                                >
                                  {this.state.success}
                                </Alert>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      This is a sensitive operation which requires you to
                      Re-Login. Please login again and try again!
                    </Alert>

                    <Button
                      onClick={(event) => {
                        event.preventDefault();

                        window.location.href =
                          window.location.protocol +
                          "//" +
                          window.location.host +
                          "/logout";
                      }}
                      color="primary"
                    >
                      Logout and Re-Login
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  onSubmit_email_update(event) {
    event.preventDefault();

    var user = firebase.auth().currentUser;
    const { email } = this.state;

    console.log(email);

    user
      .updateEmail(email)
      .then(() => {
        this.setState({
          success: "Your email has been updated!",
          show_message: true,
          email: "",
        });
      })
      .catch((error) => {
        var temp_error = JSON.stringify(error);

        if (error.code === "auth/requires-recent-login") {
          this.setState({
            reLogin: true,
            password: "",
            re_type_password: "",
          });
        } else {
          alert(error.message);
        }
      });
    // .catch((error) => {
    //   var temp_error = JSON.stringify(error);

    //   if (error.code === "auth/requires-recent-login") {
    //     this.setState({
    //       reLogin: true,
    //       password: "",
    //       re_type_password: "",
    //     });
    //   } else {
    //     alert(error.message);
    //   }
    // }

    // user
    //   .updatePassword(password)
    //   .then(() => {
    //     this.setState({
    //       success: "Your password has been updated!",
    //       show_message: true,
    //       password: "",
    //       re_type_password: "",
    //     });
    //   })
    //   .catch((error) => {
    //     var temp_error = JSON.stringify(error);

    //     if (error.code === "auth/requires-recent-login") {
    //       this.setState({
    //         reLogin: true,
    //         password: "",
    //         re_type_password: "",
    //       });
    //     } else {
    //       alert(error.message);
    //     }
    //   });
  }

  modal_email() {
    const { delete_user_data } = this.state;
    var user = firebase.auth().currentUser;

    var user_uid = "";
    if (user) {
      user_uid = user.uid;
    }

    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal_email}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              this.setState((prevState) => ({
                modal: !prevState.modal,
                menu: false,
                name: "",
                password: "",
                re_type_password: "",
                email: "",
                modal_email: false,
                modal: false,
                reLogin: false,
                success: "",
                error: "",
                show_message: false,
              }));
            }}
          >
            Change Email Address
          </ModalHeader>

          <ModalBody>
            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              <div className="form-group">
                {this.state.reLogin !== true ? (
                  <div>
                    <Form onSubmit={this.onSubmit_email_update.bind(this)}>
                      <div>
                        <Label htmlFor="email">New Email Address : </Label>
                        <Input
                          minlength="6"
                          id="email"
                          placeholder="Enter New Email Address"
                          className="form-control"
                          required
                          type="email"
                          value={this.state.email}
                          onChange={(event) => {
                            event.preventDefault();
                            this.setState({
                              email: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div>
                        {user_uid === "tfVyqHtcJXVfQWfuGEzbMWugbWx1" ? (
                          <div>
                            <Button type="submit" color="primary" disabled>
                              Change Email
                            </Button>
                            <p style={{ color: "#B00020" }}>
                              Since this is a demo account, updating email is
                              disabled!
                            </p>
                          </div>
                        ) : (
                          <Button type="submit" color="primary">
                            Change Email
                          </Button>
                        )}
                      </div>
                    </Form>

                    {this.state.show_message === true ? (
                      <div>
                        {this.state.success !== "" ||
                        this.state.error !== "" ? (
                          <div>
                            {this.state.success === "" ? (
                              <div>
                                <Alert
                                  color="danger"
                                  style={{ marginTop: "13px" }}
                                >
                                  {this.state.error}
                                </Alert>
                              </div>
                            ) : (
                              <div>
                                <Alert
                                  color="success"
                                  style={{ marginTop: "13px" }}
                                >
                                  {this.state.success}
                                </Alert>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      This is a sensitive operation which requires you to
                      Re-Login. Please login again and try again!
                    </Alert>

                    <Button
                      onClick={(event) => {
                        event.preventDefault();

                        window.location.href =
                          window.location.protocol +
                          "//" +
                          window.location.host +
                          "/logout";
                      }}
                      color="primary"
                    >
                      Logout and Re-Login
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        this.setState({ name: obj.displayName });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        this.setState({ name: obj.username });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.modal()}
        {this.modal_email()}
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <i className="bx bx-user font-size-20 align-middle mr-1 "></i>

            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <i className="bx bx-edit-alt font-size-16 align-middle mr-1"></i>
              <a
                onClick={(event) => {
                  event.preventDefault();
                  this.setState((prevState) => ({
                    modal: !prevState.modal,
                  }));
                }}
              >
                Change Password
              </a>
            </DropdownItem>

            <DropdownItem>
              <i className="bx bx-edit-alt font-size-16 align-middle mr-1"></i>
              <a
                onClick={(event) => {
                  event.preventDefault();
                  this.setState((prevState) => ({
                    modal_email: !prevState.modal_email,
                  }));
                }}
              >
                Change Email
              </a>
            </DropdownItem>

            <div className="dropdown-divider"></div>
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(ProfileMenu));
