import React, { Component } from "react";
import {
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Table,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment_time_zone from "moment-timezone";

//Simple bar
import SimpleBar from "simplebar-react";

class TotalSellingProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      updated_array: [],
      show_message: false,
      modal_standard: false,
      view_details_index: 0,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render_details_modal() {
    var results = [];
    //const { data } = this.props;
    var data;
    if (this.state.updated_array.length !== 0) {
      data = this.state.updated_array;
    } else {
      data = this.props.data;
    }

    data[this.state.view_details_index].questionnaire_result.forEach(
      (element, i) => {
        var background = "#fff";

        if ((i + 1) % 2 === 1) {
          background = "#fff";
        } else {
          background = "#f2f5fe";
        }

        results.push(
          <tr>
            <td style={{ background }}>
              <Row>
                <Col lg="1">#{i + 1}</Col>
                <Col lg="11">
                  <h5
                    className="text-truncate font-size-14 mb-1"
                    style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                  >
                    {element.question}
                  </h5>
                  <p
                    className="text-muted mb-0 "
                    style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                  >
                    {element.answer}
                  </p>
                </Col>
              </Row>
            </td>
          </tr>
        );
      }
    );

    var sentiment = "";
    if (data.length !== 0) {
      if (
        data[this.state.view_details_index].questionnaire_result[0].answer ===
          "Satisfied" ||
        data[this.state.view_details_index].questionnaire_result[0].answer ===
          "Very Satisfied"
      ) {
        sentiment = (
          <span
            className="badge badge-success ml-1 align-bottom"
            style={{ fontSize: 12 }}
          >
            Pleasant
          </span>
        );
      } else if (
        data[this.state.view_details_index].questionnaire_result[0].answer ===
          "Dissatisfied" ||
        data[this.state.view_details_index].questionnaire_result[0].answer ===
          "Very Dissatisfied"
      ) {
        sentiment = (
          <span
            className="badge badge-danger ml-1 align-bottom"
            style={{ fontSize: 12 }}
          >
            Unpleasant
          </span>
        );
      } else {
        sentiment = (
          <span
            className="badge badge-warning ml-1 align-bottom"
            style={{ fontSize: 12 }}
          >
            Neutral
          </span>
        );
      }
    }
    return (
      <Modal
        isOpen={this.state.modal_standard}
        toggle={() => {
          this.setState((prevState) => ({
            modal_standard: !prevState.modal_standard,
            view_details_index: 0,
          }));
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Details
          </h5>
          <button
            type="button"
            onClick={() => {
              this.setState((prevState) => ({
                modal_standard: !prevState.modal_standard,
                view_details_index: 0,
              }));
              //this.setState({ modal_standard: false })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <CardBody>
            <Row>
              <Col sm="6">
                <div>
                  <p className="text-muted mb-2">Date Submitted</p>
                  <h5>
                    {moment_time_zone(
                      data[this.state.view_details_index].survey_submitted_on
                    )
                      .tz("America/Toronto")
                      .format("MMMM D YYYY")}
                  </h5>
                </div>
              </Col>
              <Col sm="6">
                <div className="text-sm-right mt-4 mt-sm-0">
                  <p className="text-muted mb-2">Sentiment</p>
                  {sentiment}
                </div>
              </Col>
            </Row>
          </CardBody>

          <CardBody className="border-top">
            <p className="text-muted mb-4">Customer Full Response</p>

            <div className="mt-4">
              <SimpleBar style={{ maxHeight: "350px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap table-centered table-hover mb-0">
                    <tbody>{results}</tbody>
                  </Table>
                </div>
              </SimpleBar>
            </div>
          </CardBody>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.setState((prevState) => ({
                modal_standard: !prevState.modal_standard,
                view_details_index: 0,
              }));
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
    /*
    return (
      <Modal
        isOpen={this.state.modal_standard}
        toggle={() => {
          this.setState((prevState) => ({
            modal_standard: !prevState.modal_standard,
            view_details_index: 0,
          }));
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Details
          </h5>
          <button
            type="button"
            onClick={() => {
              this.setState((prevState) => ({
                modal_standard: !prevState.modal_standard,
                view_details_index: 0,
              }));
              //this.setState({ modal_standard: false })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <CardBody>
            <Row>
              <Col sm="6">
                <div>
                  <p className="text-muted mb-2">Date Submitted</p>
                  <h5>
                    {moment_time_zone(
                      this.state.original_csat_data[
                        this.state.view_details_index
                      ].survey_submitted_on
                    )
                      .tz("America/Toronto")
                      .format("MMMM Do YYYY")}
                  </h5>
                </div>
              </Col>
              <Col sm="6">
                <div className="text-sm-right mt-4 mt-sm-0">
                  <p className="text-muted mb-2">Sentiment</p>
                  {sentiment}
                </div>
              </Col>
            </Row>
          </CardBody>

          <CardBody className="border-top">
            <p className="text-muted mb-4">Customer Full Response</p>

            <div className="mt-4">
              <SimpleBar style={{ maxHeight: "350px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap table-centered table-hover mb-0">
                    <tbody>{results}</tbody>
                  </Table>
                </div>
              </SimpleBar>
            </div>
          </CardBody>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.setState((prevState) => ({
                modal_standard: !prevState.modal_standard,
                view_details_index: 0,
              }));
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
    */
  }

  render_nav() {
    const { data } = this.props;
    return (
      <Nav pills className="rounded">
        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "1",
            })}
            onClick={() => {
              this.setState({
                updated_array: data,
                show_message: false,
              });

              this.toggleTab("1");
            }}
          >
            All Responses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "2",
            })}
            onClick={() => {
              var temp_filter_csat_nps = data.filter((element) => {
                return (
                  element.questionnaire_result[0].answer === "Very Satisfied"
                );
              });
              if (temp_filter_csat_nps.length !== 0) {
                this.setState({
                  updated_array: temp_filter_csat_nps,
                  show_message: false,
                });
              } else {
                this.setState({
                  show_message: true,
                });
              }
              this.toggleTab("2");
            }}
          >
            Very Satisfied
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "3",
            })}
            onClick={() => {
              var temp_filter_csat_nps = data.filter((element) => {
                return element.questionnaire_result[0].answer === "Satisfied";
              });
              if (temp_filter_csat_nps.length !== 0) {
                this.setState({
                  updated_array: temp_filter_csat_nps,
                  show_message: false,
                });
              } else {
                this.setState({
                  show_message: true,
                });
              }

              this.toggleTab("3");
            }}
          >
            Satisfied
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "4",
            })}
            onClick={() => {
              var temp_filter_csat_nps = data.filter((element) => {
                return (
                  element.questionnaire_result[0].answer ===
                  "Neither Satisfied nor Dissatisfied"
                );
              });
              if (temp_filter_csat_nps.length !== 0) {
                this.setState({
                  updated_array: temp_filter_csat_nps,
                  show_message: false,
                });
              } else {
                this.setState({
                  show_message: true,
                });
              }
              this.toggleTab("4");
            }}
          >
            Neutral
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "5",
            })}
            onClick={() => {
              var temp_filter_csat_nps = data.filter((element) => {
                return (
                  element.questionnaire_result[0].answer === "Dissatisfied"
                );
              });
              if (temp_filter_csat_nps.length !== 0) {
                this.setState({
                  updated_array: temp_filter_csat_nps,
                  show_message: false,
                });
              } else {
                this.setState({
                  show_message: true,
                });
              }
              this.toggleTab("5");
            }}
          >
            Dissatisfied
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "6",
            })}
            onClick={() => {
              var temp_filter_csat_nps = data.filter((element) => {
                return (
                  element.questionnaire_result[0].answer === "Very Dissatisfied"
                );
              });
              if (temp_filter_csat_nps.length !== 0) {
                this.setState({
                  updated_array: temp_filter_csat_nps,
                  show_message: false,
                });
              } else {
                this.setState({
                  show_message: true,
                });
              }
              this.toggleTab("6");
            }}
          >
            Very Dissatisfied
          </NavLink>
        </NavItem>
      </Nav>
    );
  }
  render() {
    // console.log(this.props.data);

    var data = []; // = this.props.data;

    if (this.state.updated_array.length !== 0) {
      data = this.state.updated_array;
    } else {
      data = this.props.data;
    }

    return (
      <React.Fragment>
        {this.render_details_modal()}

        {/*<Col xl="12">*/}
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Responses</h4>

            {this.render_nav()}

            <div className="mt-4">
              <SimpleBar style={{ maxHeight: "350px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap table-centered table-hover mb-0">
                    <tbody>
                      {data.map((original_csat_data, key) => {
                        var survey_submitted_on = moment_time_zone(
                          original_csat_data.survey_submitted_on
                        )
                          .tz("America/Toronto")
                          .format("MMMM D YYYY");

                        //   console.log(survey_submitted_on);

                        var csat_answer =
                          original_csat_data.questionnaire_result[0].answer;
                        var csat_open_field =
                          original_csat_data.questionnaire_result[1].answer;
                        var showEmoj = (
                          <i className="bx bx-user-plus h1 text-primary"></i>
                        );
                        //["#64d39c", "#80CBC4", "#f9b683", "#E57373", "#c94552"],
                        if (csat_answer === "Very Satisfied") {
                          showEmoj = (
                            <i
                              className="bx bx-happy-alt"
                              style={{ fontSize: 45, color: "#64d39c" }}
                            ></i>
                          );
                        } else if (csat_answer === "Satisfied") {
                          showEmoj = (
                            <i
                              className="bx bx-smile"
                              style={{ fontSize: 45, color: "#80CBC4" }}
                            ></i>
                          );
                        } else if (
                          csat_answer === "Neither Satisfied nor Dissatisfied"
                        ) {
                          showEmoj = (
                            <i
                              className="bx bx-meh"
                              style={{ fontSize: 45, color: "#f9b683" }}
                            ></i>
                          );
                        } else if (csat_answer === "Dissatisfied") {
                          showEmoj = (
                            <i
                              className="bx bx-sad"
                              style={{ fontSize: 45, color: "#E57373" }}
                            ></i>
                          );
                        } else {
                          showEmoj = (
                            <i
                              className="bx bx-angry"
                              style={{ fontSize: 45, color: "#c94552" }}
                            ></i>
                          );
                        }

                        /*<p className="text-muted mb-0" >
                                  {csat_open_field}
                                </p>*/
                        /*
                          return (
                            <tr>
                              <div style={{ padding: 10 }}>
                                <Row>
                                  <Col sm="10">
                                    <Row>
                                      <Col sm="1">
                                        <div className="custom-control custom-checkbox">
                                          {showEmoj}
                                        </div>
                                      </Col>
                                      <Col sm="11">
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {csat_answer}
                                        </h5>
                                        <p className="text-muted mb-0">
                                          {csat_open_field}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col sm="2">
                                    <div className="text-right">
                                      <button
                                        onClick={() => {
                                          this.setState((prevState) => ({
                                            modal_standard: !prevState.modal_standard,
                                            view_details_index: key,
                                          }));
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                      >
                                        View Details
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </tr>
                          );

                          */
                        {
                          /*
                              <td style={{ width: "50px" }}>
                                <div className="custom-control custom-checkbox">
                                  {showEmoj}
                                </div>
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14 mb-1">
                                  {csat_answer}
                                </h5>
                              </td>

                              <td style={{ width: "90px" }}>
                                <div>
                                  <ul className="list-inline mb-0 font-size-16">
                                    <li className="list-inline-item">
                                      <button
                                        onClick={() => {
                                          this.setState((prevState) => ({
                                            modal_standard: !prevState.modal_standard,
                                            view_details_index: key,
                                          }));
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                      >
                                        View Details
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                                      </td>*/
                        }

                        /*
                            style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                     */
                        return (
                          <tr>
                            <td style={{ width: "50px" }}>
                              <div className="custom-control custom-checkbox">
                                {showEmoj}
                              </div>
                            </td>
                            <td>
                              <h5 className="text-truncate font-size-14 mb-1">
                                {csat_answer}
                              </h5>
                              <p
                                className="text-muted mb-0"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {csat_open_field}
                              </p>
                            </td>

                            <td>
                              <p
                                className="text-muted mb-0"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {survey_submitted_on}
                              </p>
                            </td>

                            <td style={{ width: "90px" }}>
                              <div>
                                <ul className="list-inline mb-0 font-size-16">
                                  <li className="list-inline-item">
                                    <button
                                      onClick={() => {
                                        this.setState((prevState) => ({
                                          modal_standard: !prevState.modal_standard,
                                          view_details_index: key,
                                        }));
                                      }}
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      View Details
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
        {/*  </Col>*/}
      </React.Fragment>
    );
  }
}

export default TotalSellingProduct;
