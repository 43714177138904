import _ from "lodash";
import React, { Component } from "react";
import moment_time_zone from "moment-timezone";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import Lottie from "react-lottie";
import firebase from "firebase/app";
import "firebase/database";

import moment from "moment";
import { CSVLink } from "react-csv";

import OpenField from "./OpenField";
import Chart from "./Chart";
import Breadcrumbs from "../../components/Common/Breadcrumb";

class MCQ_OpenField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      switch: "1",
      export_title: "Export CSV",
      all_data_by_survey_name: [],
      all_data_snap: [],
      specific_initial: "",
      specific_middle: "",
      specific_final: "",
      all_initial: "",
      all_final: "",
      exportName: "Data",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.processing();
    }, 500);
  }
  processing() {
    var snapshot = this.props.data;
    var all_data_snap = _.map(snapshot, (val, uid) => {
      return { uid, ...val };
    });

    var all_data_snap_by_date = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment_time_zone(val.survey_submitted_on)
          .tz("America/Toronto")
          .format("MMMM Do YYYY"),

        data: { uid, ...val },
      };

      return data;
    });
    var all_data_snap_by_month = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment_time_zone(val.survey_submitted_on)
          .tz("America/Toronto")
          .format("MMMM YYYY"),

        data: { uid, ...val },
      };

      return data;
    });
    var all_data_snap_by_year = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment_time_zone(val.survey_submitted_on)
          .tz("America/Toronto")
          .format("YYYY"),

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_by_date = new Map();
    //
    all_data_snap_by_date = all_data_snap_by_date.filter((entry) => {
      var previous;
      if (seen_all_data_by_date.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_date[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_date[entry.label] = entry;

      return true;
    });

    all_data_snap_by_date = _.fromPairs(
      _.map(all_data_snap_by_date, (i) => {
        return [i.label, i.data];
      })
    );

    var seen_all_data_by_month = new Map();
    //
    all_data_snap_by_month = all_data_snap_by_month.filter((entry) => {
      var previous;
      if (seen_all_data_by_month.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_month[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_month[entry.label] = entry;

      return true;
    });

    all_data_snap_by_month = _.fromPairs(
      _.map(all_data_snap_by_month, (i) => {
        return [i.label, i.data];
      })
    );

    var seen_all_data_by_year = new Map();
    //
    all_data_snap_by_year = all_data_snap_by_year.filter((entry) => {
      var previous;
      if (seen_all_data_by_year.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_year[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_year[entry.label] = entry;

      return true;
    });

    all_data_snap_by_year = _.fromPairs(
      _.map(all_data_snap_by_year, (i) => {
        return [i.label, i.data];
      })
    );

    var all_data_by_survey_name = _.map(snapshot, (val, uid) => {
      var all_data_by_survey_name_as_label = {};

      if (val.transaction_info.branch_survey_name) {
        all_data_by_survey_name_as_label = {
          label:
            val.transaction_info.survey_name +
            " : " +
            val.transaction_info.branch_survey_name,
          data: {
            uid,
            val: { ...val },
          },
        };
      } else {
        all_data_by_survey_name_as_label = {
          label: val.transaction_info.survey_name,
          data: {
            uid,
            val: { ...val },
          },
        };
      }

      return all_data_by_survey_name_as_label;
    });

    var seen_all_data_by_survey_name = new Map();
    //
    all_data_by_survey_name = all_data_by_survey_name.filter((entry) => {
      var previous;
      if (seen_all_data_by_survey_name.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_survey_name[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_survey_name[entry.label] = entry;

      return true;
    });

    all_data_by_survey_name = _.fromPairs(
      _.map(all_data_by_survey_name, (i) => {
        return [i.label, i.data];
      })
    );

    all_data_by_survey_name = _.map(all_data_by_survey_name, (val, uid) => {
      var array_converted = _.fromPairs(
        _.map(val, (i) => {
          return [i.uid, i.val];
        })
      );
      var by_date = [],
        by_month = [],
        by_year = [];

      var by_date = _.map(array_converted, (val, uid) => {
        var date_local = {
          label: moment_time_zone(val.survey_submitted_on)
            .tz("America/Toronto")
            .format("MMMM Do YYYY"),
          data: {
            uid,
            val: { ...val },
          },
        };

        return date_local;
      });

      var by_month = _.map(array_converted, (val, uid) => {
        var month_local = {
          label: moment_time_zone(val.survey_submitted_on)
            .tz("America/Toronto")
            .format("MMMM YYYY"),
          data: {
            uid,
            val: { ...val },
          },
        };

        return month_local;
      });

      var by_year = _.map(array_converted, (val, uid) => {
        var year_local = {
          label: moment_time_zone(val.survey_submitted_on)
            .tz("America/Toronto")
            .format("YYYY"),
          data: {
            uid,
            val: { ...val },
          },
        };

        return year_local;
      });
      ///////////////
      var seend_by_date = new Map();
      by_date = by_date.filter((entry) => {
        var previous;
        if (seend_by_date.hasOwnProperty(entry.label)) {
          previous = seend_by_date[entry.label];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        seend_by_date[entry.label] = entry;

        return true;
      });

      by_date = _.fromPairs(
        _.map(by_date, (i) => {
          return [i.label, i.data];
        })
      );

      by_date = _.map(by_date, (val, uid) => {
        var array_converted_by_date = _.fromPairs(
          _.map(val, (i) => {
            return [i.uid, i.val];
          })
        );
        array_converted_by_date = _.map(array_converted_by_date, (val, uid) => {
          return { uid, ...val };
        });

        return {
          uid,
          data: { ...array_converted_by_date },
        };
      });

      ///////////////

      ///////////////
      var seend_by_month = new Map();
      by_month = by_month.filter((entry) => {
        var previous;
        if (seend_by_month.hasOwnProperty(entry.label)) {
          previous = seend_by_month[entry.label];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        seend_by_month[entry.label] = entry;

        return true;
      });

      by_month = _.fromPairs(
        _.map(by_month, (i) => {
          return [i.label, i.data];
        })
      );

      by_month = _.map(by_month, (val, uid) => {
        var array_converted_by_month = _.fromPairs(
          _.map(val, (i) => {
            return [i.uid, i.val];
          })
        );
        array_converted_by_month = _.map(
          array_converted_by_month,
          (val, uid) => {
            return { uid, ...val };
          }
        );

        return {
          uid,
          data: { ...array_converted_by_month },
        };
      });

      ///////////////

      ///////////////
      var send_by_year = new Map();
      by_year = by_year.filter((entry) => {
        var previous;
        if (send_by_year.hasOwnProperty(entry.label)) {
          previous = send_by_year[entry.label];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        send_by_year[entry.label] = entry;

        return true;
      });

      by_year = _.fromPairs(
        _.map(by_year, (i) => {
          return [i.label, i.data];
        })
      );

      by_year = _.map(by_year, (val, uid) => {
        var array_converted_by_year = _.fromPairs(
          _.map(val, (i) => {
            return [i.uid, i.val];
          })
        );

        array_converted_by_year = _.map(array_converted_by_year, (val, uid) => {
          return { uid, ...val };
        });

        return {
          uid,
          data: { ...array_converted_by_year },
        };
      });

      array_converted = _.map(array_converted, (val, uid) => {
        return { uid, ...val };
      });

      var specific_data_snap = {
        all_surveys: { data: array_converted },
        by_date,
        by_month,
        by_year,
      };

      specific_data_snap = _.map(specific_data_snap, (val, uid) => {
        if (uid === "all_surveys") {
          return { selection: uid, data: val.data };
        } else {
          var new_val = val;
          if (uid === "by_date") {
            new_val = _.map(val, (val2, uid2) => {
              return { date: val2.uid, data: val2.data };
            });
          } else if (uid === "by_month") {
            new_val = _.map(val, (val2, uid2) => {
              return { month: val2.uid, data: val2.data };
            });
          } else if (uid === "by_year") {
            new_val = _.map(val, (val2, uid2) => {
              return { year: val2.uid, data: val2.data };
            });
          } else {
            return null;
          }
          return { selection: uid, data: new_val };
        }
      });

      return {
        survey_name: uid,
        data: specific_data_snap,
      };
    });

    all_data_by_survey_name = _.orderBy(
      all_data_by_survey_name,
      ["survey_name"],
      ["asc"]
    );

    var all_data_snap = {
      all_surveys: { data: all_data_snap },
      by_date: all_data_snap_by_date,
      by_month: all_data_snap_by_month,
      by_year: all_data_snap_by_year,
    };

    all_data_snap = _.map(all_data_snap, (val, uid) => {
      if (uid === "all_surveys") {
        return { selection: uid, data: val.data };
      } else {
        var new_val = val; //[];
        if (uid === "by_date") {
          new_val = _.map(val, (val2, uid2) => {
            return { date: uid2, data: val2 };
          });
        } else if (uid === "by_month") {
          new_val = _.map(val, (val2, uid2) => {
            return { month: uid2, data: val2 };
          });
        } else if (uid === "by_year") {
          new_val = _.map(val, (val2, uid2) => {
            return { year: uid2, data: val2 };
          });
        } else {
          return null;
        }
        return { selection: uid, data: new_val };
      }
    });

    this.setState({
      all_data_by_survey_name,
      all_data_snap,
    });
  }
  showcase_data(data) {
    if (data.length === 0) {
      return null;
    } else {
      var multiple_choice = [];
      var open_text = [];
      var multiple_choice_item_render = [];
      var open_text_item_render = [];

      data.forEach((first_element) => {
        var questionnaire_result = first_element.questionnaire_result;
        var transaction_info = first_element.transaction_info;
        var survey_submitted_on = first_element.survey_submitted_on;

        questionnaire_result.forEach((second_element) => {
          var answer = second_element.answer;
          var question = second_element.question;
          var type_of_question = second_element.type_of_question;
          if (
            type_of_question === "MCQ" ||
            type_of_question === "ConditionalMCQ" ||
            type_of_question === "NPS" ||
            type_of_question === "ConditionalNPS" ||
            type_of_question === "CSAT" ||
            type_of_question === "ConditionalCSAT"
          ) {
            multiple_choice.push({
              question,
              data: {
                question,
                answer,
                transaction_info,
                survey_submitted_on,
                type_of_question,
              },
            });
          } else {
            open_text.push({
              question,
              data: {
                question,
                answer,
                transaction_info,
                survey_submitted_on,
                type_of_question,
              },
            });
          }
        });
      });

      /////////////////////////////////////////////////////
      var seen_multiple_choice = new Map();
      var cluster_multiple_choice = multiple_choice.filter((entry) => {
        var previous;
        if (seen_multiple_choice.hasOwnProperty(entry.question)) {
          previous = seen_multiple_choice[entry.question];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        seen_multiple_choice[entry.question] = entry;

        return true;
      });
      //

      //
      var seen_open_text = new Map();
      var cluster_open_text = open_text.filter((entry) => {
        var previous;
        if (seen_open_text.hasOwnProperty(entry.question)) {
          previous = seen_open_text[entry.question];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        seen_open_text[entry.question] = entry;

        return true;
      });

      //Bottom Only for multiple choice START
      cluster_multiple_choice.forEach((first_element, p) => {
        var array = [];
        first_element.data.forEach((second_element) => {
          array.push(second_element.answer);
        });

        array.sort();
        var array_uniqBy_and_count = [];
        var array_uniqBy_choice_only = [];
        var array_uniqBy_count_only = [];

        var current = null;
        var cnt = 0;

        array.forEach((third_element) => {
          if (third_element != current) {
            if (cnt > 0) {
              array_uniqBy_and_count.push({
                choice: current,
                qty: cnt,
              });

              array_uniqBy_choice_only.push(current);
              //array_uniqBy_count_only.push(cnt);
              array_uniqBy_count_only.push({
                value: cnt,
                name: current,
              });
            }
            current = third_element;
            cnt = 1;
          } else {
            cnt++;
          }
        });

        if (cnt > 0) {
          array_uniqBy_and_count.push({
            choice: current,
            qty: cnt,
          });
          array_uniqBy_choice_only.push(current);
          //  array_uniqBy_count_only.push(cnt);
          array_uniqBy_count_only.push({
            value: cnt,
            name: current,
          });
        }

        multiple_choice_item_render.push({
          question: first_element.question,
          labels: array_uniqBy_choice_only,
          data: array_uniqBy_count_only,
        });
      });
      //TOP Only for multiple choice END

      cluster_open_text.forEach((first_element, q) => {
        var open_text_data = [];

        first_element.data.forEach((second_element, p) => {
          var workshop_name = second_element.transaction_info.survey_name;
          if (second_element.transaction_info.branch_survey_name) {
            workshop_name =
              second_element.transaction_info.survey_name +
              " : " +
              second_element.transaction_info.branch_survey_name;
          }
          open_text_data.push({
            id: p + 1,
            response: second_element.answer,
            workshop: workshop_name, //second_element.transaction_info.survey_name,
            date: moment(second_element.survey_submitted_on).format(
              "dddd, MMMM Do YYYY"
            ),
          });
        });

        var columns = [
          {
            dataField: "id",
            text: "ID",
            sort: true,
          },
          {
            dataField: "response",
            text: "Response",
            sort: true,
          },
          {
            dataField: "workshop",
            text: "Workshop",
            sort: true,
          },
          {
            dataField: "date",
            text: "Date",
            sort: true,
          },
        ];

        open_text_item_render.push({
          question: first_element.question,
          data: open_text_data,
          columns: columns,
        });
      });

      return { multiple_choice_item_render, open_text_item_render };
    }
  }

  export_data(data) {
    if (data.length === 0) {
      return null;
    } else {
      var master_data_all_rows = [];
      var master_data_all_coloums = [];
      var master_data_all_questions = [];
      var master_data_for_export_only = [];

      var master_data_all = null;

      data.forEach((element, i) => {
        var temp_array_for_rows = [];
        var temp_array_data_for_export_only = [];

        element.questionnaire_result.forEach((question_data, p) => {
          master_data_all_questions.push(question_data.question);
          var special_characters_and_spaces_removed_and_make_it_lowercase = question_data.question
            .replace(/[^\w\s]/gi, "")
            .replace(/ /g, "_")
            .toLowerCase();

          temp_array_for_rows.push({
            [special_characters_and_spaces_removed_and_make_it_lowercase]:
              question_data.answer,
          });

          temp_array_data_for_export_only.push({
            Submitted: moment_time_zone(element.survey_submitted_on)
              .tz("America/Toronto")
              .format("MMMM Do YYYY"),
            Name: element.transaction_info.survey_name,
            [question_data.question]: question_data.answer,
          });
        });

        var multiple_objects_into_one = temp_array_for_rows.reduce(
          (r, c) => Object.assign(r, c),
          {}
        );

        master_data_all_rows.push(multiple_objects_into_one);

        var new_multiple_objects_into_one = temp_array_data_for_export_only.reduce(
          (r, c) => Object.assign(r, c),
          {}
        );

        //  console.log(new_multiple_objects_into_one);
        master_data_for_export_only.push(new_multiple_objects_into_one);
      });

      //  console.log(master_data_all_questions);

      master_data_all_questions = _.uniq(master_data_all_questions);

      master_data_all_questions.forEach((element, i) => {
        var special_characters_and_spaces_removed_and_make_it_lowercase = element
          .replace(/[^\w\s]/gi, "")
          .replace(/ /g, "_")
          .toLowerCase();
        master_data_all_coloums.push({
          label: element,
          field: special_characters_and_spaces_removed_and_make_it_lowercase,
          sort: "asc",
        });
      });

      master_data_all = {
        columns: master_data_all_coloums,
        rows: master_data_all_rows,
        export_only: master_data_for_export_only,
      };

      // return {
      //   master_data_all,
      // };
      return master_data_for_export_only;
    }
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  render_label_all() {
    const { all_initial } = this.state;
    if (all_initial === "by_date") {
      return <Label>Select Date:</Label>;
    } else if (all_initial === "by_month") {
      return <Label>Select Month:</Label>;
    }
    if (all_initial === "by_year") {
      return <Label>Select Year:</Label>;
    }

    return <Label>Select :</Label>;
  }
  render_label_specific() {
    const { specific_middle } = this.state;
    if (specific_middle === "by_date") {
      return <Label>Select Date:</Label>;
    } else if (specific_middle === "by_month") {
      return <Label>Select Month:</Label>;
    }
    if (specific_middle === "by_year") {
      return <Label>Select Year:</Label>;
    }

    return <Label>Select :</Label>;
  }

  render_page() {
    const {
      specific_event_names,
      all_data_by_survey_name,
      all_data_snap,
      specific_final,
      specific_middle,
      specific_initial,
      all_initial,
      all_final,
    } = this.state;
    const { user_details } = this.props;

    var survey_name_option = [];
    var survey_name_option_last_filter = [];
    var all_option_last_filter = [];
    if (user_details.role === "Facilitator") {
      var programs = _.map(user_details.all_programs, (val, uid) => {
        return { ...val, uid };
      });
      programs = programs[0];

      var limited_access = _.map(programs.survey_access, (val, uid) => {
        return { ...val, uid };
      });
      limited_access.forEach((element_access) => {
        all_data_by_survey_name.forEach((element, i) => {
          var use_name = "";
          if (element_access.access_choice_funnel) {
            use_name =
              element_access.uid + " : " + element_access.access_choice_funnel;
          } else {
            use_name = element_access.uid;
          }
          if (use_name === element.survey_name) {
            survey_name_option.push(
              <option value={i} key={i} data={element.data}>
                {element.survey_name}
              </option>
            );
          }
        });
      });
    } else {
      all_data_by_survey_name.forEach((element, i) => {
        survey_name_option.push(
          <option value={i} key={i} data={element.data}>
            {element.survey_name}
          </option>
        );
      });
    }

    var data = [];
    if (this.state.switch === "1") {
      if (
        (specific_initial !== null && specific_initial !== "") ||
        (specific_middle !== null && specific_middle !== "")
      ) {
        data =
          all_data_by_survey_name[this.state.specific_initial].data[0].data;
        if (specific_middle !== null || specific_middle !== "") {
          if (specific_middle === "by_date") {
            data =
              all_data_by_survey_name[this.state.specific_initial].data[1].data;
            data.forEach((element, i) => {
              survey_name_option_last_filter.push(
                <option value={i} key={i} data={data}>
                  {element.date}
                </option>
              );
            });
          }
          if (specific_middle === "by_month") {
            data =
              all_data_by_survey_name[this.state.specific_initial].data[2].data;

            data.forEach((element, i) => {
              survey_name_option_last_filter.push(
                <option value={i} key={i} data={data}>
                  {element.month}
                </option>
              );
            });
          }
          if (specific_middle === "by_year") {
            data =
              all_data_by_survey_name[this.state.specific_initial].data[3].data;

            data.forEach((element, i) => {
              survey_name_option_last_filter.push(
                <option value={i} key={i} data={data}>
                  {element.year}
                </option>
              );
            });
          }
        }
      }
    } else {
      data = all_data_snap[0].data;

      if (all_initial !== null || all_initial !== "") {
        if (all_initial === "by_date") {
          data = all_data_snap[1].data;

          data.forEach((element, i) => {
            all_option_last_filter.push(
              <option value={i} key={i} data={data}>
                {element.date}
              </option>
            );
          });
        }
        if (all_initial === "by_month") {
          data = all_data_snap[2].data;
          data.forEach((element, i) => {
            all_option_last_filter.push(
              <option value={i} key={i} data={data}>
                {element.month}
              </option>
            );
          });
        }
        if (all_initial === "by_year") {
          data = all_data_snap[3].data;
          data.forEach((element, i) => {
            all_option_last_filter.push(
              <option value={i} key={i} data={data}>
                {element.year}
              </option>
            );
          });
        }
      }
    }

    //var showcase_data = this.showcase_data(show_data);
    var show_data = [];
    if (this.state.switch === "1") {
      if (
        specific_initial !== "" &&
        specific_middle === "" &&
        specific_final === ""
      ) {
        show_data = data;
      } else if (
        specific_initial !== "" &&
        specific_middle !== "" &&
        specific_final !== ""
      ) {
        show_data = _.map(data[specific_final].data, (val, uid) => {
          return val;
        });
        //data[specific_final].data;
      } else {
        //do nothing
      }
    } else {
      if (all_initial === "" && all_final === "") {
        show_data = data;
      } else if (all_initial !== "" && all_final !== "") {
        show_data = _.map(data[all_final].data, (val, uid) => {
          return val;
        });
      } else {
        //do nothing
      }
    }
    var showcase_data = this.showcase_data(show_data);

    var export_data = this.export_data(show_data);

    return (
      <Col lg="12">
        <Card>
          <CardBody>
            <h4 className="card-title mb-3">Dashboard</h4>

            {this.props.user_details.role !== "Facilitator" ? (
              <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={() => {
                      this.setState({
                        activeTab: "1",
                        switch: "1",
                        specific_initial: "",
                        specific_middle: "",
                        specific_final: "",
                      });

                      // this.toggleTab("1");
                    }}
                  >
                    Specific Surveys
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2",
                    })}
                    onClick={() => {
                      this.setState({
                        activeTab: "2",
                        loading: true,
                        all_initial: "",
                        all_final: "",
                      });
                      setTimeout(() => {
                        this.setState({
                          switch: "2",
                          loading: false,
                        });
                      }, 500);
                    }}
                  >
                    All Surveys
                  </NavLink>
                </NavItem>
              </ul>
            ) : null}
            <TabContent activeTab={this.state.activeTab} className="p-3">
              <TabPane tabId="1" id="processing">
                <div>
                  <Form>
                    <Row>
                      <div className="col-xl col-sm-6">
                        <FormGroup className="mt-3 mb-0">
                          <Label>Name :</Label>
                          <select
                            value={this.state.specific_initial}
                            onChange={(event) => {
                              event.preventDefault();

                              this.setState({
                                specific_initial: event.target.value,
                                specific_middle: "",
                                specific_final: "",
                              });
                            }}
                            className="form-control select2-search-disable"
                          >
                            <option value="" defaultValue>
                              Select...
                            </option>
                            {survey_name_option}
                          </select>
                        </FormGroup>
                      </div>

                      <div className="col-xl col-sm-6">
                        <FormGroup className="mt-3 mb-0">
                          <Label>Filter By :</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={this.state.specific_middle}
                            onChange={(event) => {
                              event.preventDefault();

                              this.setState({
                                specific_middle: event.target.value,
                                specific_final: "",
                              });
                            }}
                          >
                            <option value="" defaultValue>
                              Select...
                            </option>
                            <option value="by_date">Date</option>
                            <option value="by_month">Month</option>
                            <option value="by_year">Year</option>
                          </select>
                        </FormGroup>
                      </div>

                      {this.state.specific_middle === "" ? null : (
                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            {this.render_label_specific()}

                            <select
                              className="form-control select2-search-disable"
                              value={this.state.specific_final}
                              onChange={(event) => {
                                event.preventDefault();
                                this.setState({
                                  specific_final: event.target.value,
                                });
                              }}
                            >
                              <option value="" defaultValue>
                                Select...
                              </option>
                              {survey_name_option_last_filter}
                            </select>
                          </FormGroup>
                        </div>
                      )}

                      {export_data === null ? null : (
                        <div className="col-xl col-sm-6 align-self-end">
                          <div className="mt-3">
                            <div>
                              <CSVLink
                                filename={`Luup_Export_${this.state.exportName}.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  {this.state.export_title}
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Form>
                </div>
              </TabPane>

              <TabPane tabId="2" id="all-order">
                <div>
                  <Form>
                    <Row>
                      <div className="col-xl col-sm-6">
                        <FormGroup className="mt-3 mb-0">
                          <Label>Filter By :</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={this.state.all_initial}
                            onChange={(event) => {
                              event.preventDefault();
                              this.setState({
                                all_initial: event.target.value,
                                all_final: "",
                              });
                            }}
                          >
                            <option value="" defaultValue>
                              Select...
                            </option>
                            <option value="by_date">Date</option>
                            <option value="by_month">Month</option>
                            <option value="by_year">Year</option>
                          </select>
                        </FormGroup>
                      </div>

                      {this.state.all_initial === "" ? null : (
                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            {this.render_label_all()}

                            <select
                              className="form-control select2-search-disable"
                              value={this.state.all_final}
                              onChange={(event) => {
                                event.preventDefault();

                                this.setState({
                                  all_final: event.target.value,
                                });
                              }}
                            >
                              <option value="" defaultValue>
                                Select...
                              </option>

                              {all_option_last_filter}
                            </select>
                          </FormGroup>
                        </div>
                      )}

                      {export_data === null ? null : (
                        <div className="col-xl col-sm-6 align-self-end">
                          <div className="mt-3">
                            <div>
                              <CSVLink
                                filename={`Luup_Export_${this.state.exportName}.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  {this.state.export_title}
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Form>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>

        {this.state.loading === true ? (
          <div>{this.render_loading()}</div>
        ) : null}
        {showcase_data === null ? null : (
          <div>
            <Chart showcase_data={showcase_data} />
            <OpenField showcase_data={showcase_data} />
          </div>
        )}
      </Col>
    );
  }
  render() {
    const { all_data_by_survey_name, all_data_snap } = this.state;
    if (all_data_by_survey_name.length === 0 || all_data_snap.length === 0) {
      return (
        <React.Fragment>
          <div>{this.render_loading()}</div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div>{this.render_page()}</div>
      </React.Fragment>
    );
  }
}

export default MCQ_OpenField;
