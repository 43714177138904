/* USER DETAILS */
export const USER_DATA_READ_ASYNC = "USER_DATA_READ_ASYNC";
export const USER_DATA_WRITE_ASYNC = "USER_DATA_WRITE_ASYNC";

export const USER_DATA_READ = "USER_DATA_READ";
export const USER_DATA_WRITE = "USER_DATA_WRITE";

/* SURVEY DATA AND ATTENDANCE */
export const QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC =
  "QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC";
export const QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC =
  "QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC";

export const QR_SURVEY_DATA_AND_ATTENDANCE_READ =
  "QR_SURVEY_DATA_AND_ATTENDANCE_READ";
export const QR_SURVEY_DATA_AND_ATTENDANCE_WRITE =
  "QR_SURVEY_DATA_AND_ATTENDANCE_WRITE";

/* ARCHIVE SURVEY DATA AND ATTENDANCE */
export const ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC =
  "ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC";
export const ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC =
  "ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC";

export const ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ =
  "ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ";
export const ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE =
  "ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE";

/* QR CODE */
export const QR_CODE_READ_ASYNC = "QR_CODE_READ_ASYNC";
export const QR_CODE_WRITE_ASYNC = "QR_CODE_WRITE_ASYNC";

export const QR_CODE_READ = "QR_CODE_READ";
export const QR_CODE_WRITE = "QR_CODE_WRITE";

/* BUILDER AND MANAGEMENT */
export const QUESTIONNAIRE_READ_ASYNC = "QUESTIONNAIRE_READ_ASYNC";
export const QUESTIONNAIRE_WRITE_ASYNC = "QUESTIONNAIRE_WRITE_ASYNC";
export const QUESTIONNAIRE_TEMPLATE_READ_ASYNC =
  "QUESTIONNAIRE_TEMPLATE_READ_ASYNC";
export const QUESTIONNAIRE_TEMPLATE_WRITE_ASYNC =
  "QUESTIONNAIRE_TEMPLATE_WRITE_ASYNC";

export const QUESTIONNAIRE_READ = "QUESTIONNAIRE_READ";
export const QUESTIONNAIRE_WRITE = "QUESTIONNAIRE_WRITE";
export const QUESTIONNAIRE_TEMPLATE_READ = "QUESTIONNAIRE_TEMPLATE_READ";
export const QUESTIONNAIRE_TEMPLATE_WRITE = "QUESTIONNAIRE_TEMPLATE_WRITE";

/* ACCESS */
export const ACCESS_READ_ASYNC = "ACCESS_READ_ASYNC";
export const ACCESS_WRITE_ASYNC = "ACCESS_WRITE_ASYNC";

export const ACCESS_READ = "ACCESS_READ";
export const ACCESS_WRITE = "ACCESS_WRITE";

/*  */
export const DETAIL_ERROR = "DETAIL_ERROR";

// /* USER DETAILS */
// export const USER_DATA_READER_REQUEST = "USER_DATA_READER_REQUEST";
// export const USER_DATA_READER = "USER_DATA_READER";

// /* RESULTS AND ATTENDANCE */
// export const SURVEY_ATTENDANCE_AND_TRANSACTION_DATA_READER_REQUEST =
//   "SURVEY_ATTENDANCE_AND_TRANSACTION_DATA_READER_REQUEST";
// export const SURVEY_ATTENDANCE_AND_TRANSACTION_DATA_READER =
//   "SURVEY_ATTENDANCE_AND_TRANSACTION_DATA_READER";

// /* QR CODE */
// export const SURVEY_QR_DATA_READER = "SURVEY_QR_DATA_READER";
// export const SURVEY_QR_DATA_WRITER = "SURVEY_QR_DATA_WRITER";
// export const SURVEY_QR_DATA_FROM_MULTIPLE_LIST_READER =
//   "SURVEY_QR_DATA_FROM_MULTIPLE_LIST_READER";
// export const SURVEY_QR_DATA_READER_REQUEST = "SURVEY_QR_DATA_READER_REQUEST";

// /* BUILDER AND MANAGEMENT */
// export const CREATE_SURVEY_WRITER = "CREATE_SURVEY_WRITER";
// export const EDIT_SURVEY_WRITER = "EDIT_SURVEY_WRITER";
// export const DELETE_SURVEY_WRITER = "DELETE_SURVEY_WRITER";
// export const CURRENT_QUESTION_TEMPLATE_REQUEST =
//   "CURRENT_QUESTION_TEMPLATE_REQUEST";
// export const CURRENT_QUESTION_TEMPLATE_READER =
//   "CURRENT_QUESTION_TEMPLATE_READER";
// export const CURRENT_QUESTIONNAIRE_REQUEST = "CURRENT_QUESTIONNAIRE_REQUEST";
// export const CURRENT_QUESTIONNAIRE_READER = "CURRENT_QUESTIONNAIRE_READER";

// /* ACCESS */
// export const ACCESS_READER_REQUEST = "ACCESS_READER_REQUEST";
// export const ACCESS_READER_READER = "ACCESS_READER_READER";

// /*  */
// export const DETAIL_ERROR = "DETAIL_ERROR";
