import React, { Component } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import GaugeChart from "react-gauge-chart";

class SalesAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [56, 38, 26],
      options: {
        labels: ["Series A", "Series B", "Series C"],
        colors: ["#556ee6", "#34c38f", "#f46a6a"],
        legend: { show: !1 },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
            },
          },
        },
      },
    };
  }

  render_legend() {
    var legend_promoters = this.props.data.nps.percent_promoters.toFixed(1); //55;

    /*(
      (this.props.data.promoters / this.props.data.nps_array.length) *
      100
    ).toFixed(2);*/

    var legend_passives = this.props.data.nps.percent_passives.toFixed(1); //55;
    /*(
      (this.props.data.passives / this.props.data.nps_array.length) *
      100
    ).toFixed(2);*/

    var legend_detractors = this.props.data.nps.percent_detractors.toFixed(
      1
    ); /*(
      (this.props.data.detractors / this.props.data.nps_array.length) *
      100
    ).toFixed(2);*/

    return (
      <div className="table-responsive mt-4">
        <Table className="table-centered mb-0">
          <tbody>
            <tr>
              <td>
                <i
                  className="fas fa-thumbs-up"
                  style={{ fontSize: 28, color: "#64d39c" }}
                ></i>
              </td>

              <td>
                <div id="radialchart-1" className="apex-charts">
                  <h5 className="font-size-14 mb-1">Promoters</h5>
                </div>
              </td>
              <td>
                <h5 className="mb-0" style={{ color: "#64d39c" }}>
                  {legend_promoters} %
                </h5>
              </td>
            </tr>
            <tr>
              <td>
                <i
                  className="fas fa-hand-point-right"
                  style={{ fontSize: 28, color: "#f9b683" }}
                ></i>
              </td>

              <td>
                <div id="radialchart-2" className="apex-charts">
                  <h5 className="font-size-14 mb-1">Passives</h5>
                </div>
              </td>
              <td>
                <h5 className="mb-0" style={{ color: "#f9b683" }}>
                  {legend_passives} %
                </h5>
              </td>
            </tr>
            <tr>
              <td>
                <i
                  className="fas fa-thumbs-down"
                  style={{ fontSize: 28, color: "#c94552" }}
                ></i>
              </td>

              <td>
                <div id="radialchart-3" className="apex-charts">
                  <h5 className="font-size-14 mb-1">Detractors</h5>
                </div>
              </td>
              <td>
                <h5 className="mb-0" style={{ color: "#c94552" }}>
                  {legend_detractors} %
                </h5>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
  render() {
    if (this.props.data === null) {
      return null;
    }
    var nps_score = this.props.data.nps.nps_score.toFixed(1);
    var show_percentage = 0;
    if (nps_score < 0) {
      if (nps_score === 1) {
        show_percentage = 0;
      } else {
        show_percentage = 0.25;
      }
    } else {
      if (nps_score > 0.75) {
        if (nps_score === 1) {
          show_percentage = 1;
        } else {
          show_percentage = 0.85;
        }
      } else {
        show_percentage = 0.5;
      }
    }

    return (
      <React.Fragment>
        {/*   <Col xl="4">*/}
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Net Promoter Score</h4>
            <Col lg="12">
              <h3 className="text-center">
                {this.props.data.nps.nps_score.toFixed(1)}
              </h3>
            </Col>
            <div>
              <div id="donut-chart" className="apex-charts">
                <GaugeChart
                  id="gauge-chart1"
                  colors={["#c94552", "#f9b683", "#64d39c"]}
                  textColor="black"
                  arcWidth={0.1}
                  arcsLength={[200 / 3, 200 / 3, 200 / 3]}
                  percent={show_percentage}
                  hideText={true}
                />
              </div>
            </div>

            <div className="text-center text-muted">{this.render_legend()}</div>
          </CardBody>
        </Card>
        {/* </Col>*/}
      </React.Fragment>
    );
  }
}

export default SalesAnalytics;
