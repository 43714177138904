import _ from "lodash";
import React, { Component } from "react";
import moment_time_zone from "moment-timezone";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Form,
  FormGroup,
  /////////////////////////
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap";
import Responses from "./Retail/Responses";
import CSAT_Donut from "./Retail/CSAT_Donut";
import NPS_Guage from "./Retail/NPS_Guage";
import CSAT_NPS_TREND from "./Retail/CSAT_NPS_TREND";

import classnames from "classnames";
import Lottie from "react-lottie";
import firebase from "firebase/app";
import "firebase/database";

import moment from "moment";
import { CSVLink } from "react-csv";

import OpenField from "./OpenField";
import Chart from "./Chart";
import Breadcrumbs from "../../components/Common/Breadcrumb";

/*
Adding stuff for retail
*/

//import Charts
import Select from "react-select";
import StackedColumnChart from "./RetailTest/StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./RetailTest/WelcomeComp";
import MonthlyEarning from "./RetailTest/MonthlyEarning";
import SocialSource from "./RetailTest/SocialSource";
import ActivityComp from "./RetailTest/ActivityComp";
import TopCities from "./RetailTest/TopCities";
import LatestTranaction from "./RetailTest/LatestTranaction";

class MCQ_OpenField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "2",
      switch: "2",
      export_title: "Export CSV",
      all_data_by_survey_name: [],
      all_data_snap: [],
      specific_initial: "",
      specific_middle: "",
      specific_final: "",
      all_initial: "",
      all_final: "",
      exportName: "Data",
      initial_filter: "ALL",
      activeTab_trend_and_satisfaction: "1",
      // middle_filter: "",
      // final_filter: "",
      /////////////////////////////////

      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
    };
  }

  componentDidMount() {
    /* firebase
      .database()
      .ref(
        `QR_Code/Survey_Submitted/Testing/Testing/All_Responses_Combined/all_questionnaire/All`
      )
      .once("value")
      .then((snapshot) => {
        var data = snapshot.val();
        data = _.map(data, (val, uid) => {
          return { uid, ...val };
        });

        data.forEach((element) => {
          var questionnaire_result = element.questionnaire_result;
          var uid = element.uid;

          questionnaire_result[0].type_of_question = "CSAT";
          questionnaire_result[2].type_of_question = "NPS";

          if (questionnaire_result[2].answer === "Highly Likely") {
            questionnaire_result[2].answer = 10;
          }
          if (questionnaire_result[2].answer === "Likely") {
            questionnaire_result[2].answer = 8;
          }
          if (questionnaire_result[2].answer === "Somewhat Likely") {
            questionnaire_result[2].answer = 6;
          }
          if (questionnaire_result[2].answer === "Not very likely") {
            questionnaire_result[2].answer = 3;
          }
          if (questionnaire_result[2].answer === "Not at all Likely") {
            questionnaire_result[2].answer = 0;
          }

          firebase
            .database()
            .ref(
              `QR_Code/Survey_Submitted/Testing/Testing/All_Responses_Combined/all_questionnaire/All/${uid}`
            )
            .update({
              questionnaire_result,
            });
        });
      });
      */

    setTimeout(() => {
      this.processing();
    }, 500);
  }
  processing() {
    var snapshot = this.props.data;
    var all_data_snap = _.map(snapshot, (val, uid) => {
      return { uid, ...val };
    });

    var all_data_snap_by_date = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment_time_zone(val.survey_submitted_on)
          .tz("America/Toronto")
          .format("MMMM Do YYYY"),

        data: { uid, ...val },
      };

      return data;
    });
    var all_data_snap_by_month = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment_time_zone(val.survey_submitted_on)
          .tz("America/Toronto")
          .format("MMMM YYYY"),

        data: { uid, ...val },
      };

      return data;
    });
    var all_data_snap_by_year = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment_time_zone(val.survey_submitted_on)
          .tz("America/Toronto")
          .format("YYYY"),

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_by_date = new Map();
    //
    all_data_snap_by_date = all_data_snap_by_date.filter((entry) => {
      var previous;
      if (seen_all_data_by_date.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_date[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_date[entry.label] = entry;

      return true;
    });

    all_data_snap_by_date = _.fromPairs(
      _.map(all_data_snap_by_date, (i) => {
        return [i.label, i.data];
      })
    );

    var seen_all_data_by_month = new Map();
    //
    all_data_snap_by_month = all_data_snap_by_month.filter((entry) => {
      var previous;
      if (seen_all_data_by_month.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_month[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_month[entry.label] = entry;

      return true;
    });

    all_data_snap_by_month = _.fromPairs(
      _.map(all_data_snap_by_month, (i) => {
        return [i.label, i.data];
      })
    );

    var seen_all_data_by_year = new Map();
    //
    all_data_snap_by_year = all_data_snap_by_year.filter((entry) => {
      var previous;
      if (seen_all_data_by_year.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_year[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_year[entry.label] = entry;

      return true;
    });

    all_data_snap_by_year = _.fromPairs(
      _.map(all_data_snap_by_year, (i) => {
        return [i.label, i.data];
      })
    );

    var all_data_by_survey_name = _.map(snapshot, (val, uid) => {
      var all_data_by_survey_name_as_label = {};

      if (val.transaction_info.branch_survey_name) {
        all_data_by_survey_name_as_label = {
          label:
            val.transaction_info.survey_name +
            " : " +
            val.transaction_info.branch_survey_name,
          data: {
            uid,
            val: { ...val },
          },
        };
      } else {
        all_data_by_survey_name_as_label = {
          label: val.transaction_info.survey_name,
          data: {
            uid,
            val: { ...val },
          },
        };
      }

      return all_data_by_survey_name_as_label;
    });

    var seen_all_data_by_survey_name = new Map();
    //
    all_data_by_survey_name = all_data_by_survey_name.filter((entry) => {
      var previous;
      if (seen_all_data_by_survey_name.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_survey_name[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_survey_name[entry.label] = entry;

      return true;
    });

    all_data_by_survey_name = _.fromPairs(
      _.map(all_data_by_survey_name, (i) => {
        return [i.label, i.data];
      })
    );

    all_data_by_survey_name = _.map(all_data_by_survey_name, (val, uid) => {
      var array_converted = _.fromPairs(
        _.map(val, (i) => {
          return [i.uid, i.val];
        })
      );
      var by_date = [],
        by_month = [],
        by_year = [];

      var by_date = _.map(array_converted, (val, uid) => {
        var date_local = {
          label: moment_time_zone(val.survey_submitted_on)
            .tz("America/Toronto")
            .format("MMMM Do YYYY"),
          data: {
            uid,
            val: { ...val },
          },
        };

        return date_local;
      });

      var by_month = _.map(array_converted, (val, uid) => {
        var month_local = {
          label: moment_time_zone(val.survey_submitted_on)
            .tz("America/Toronto")
            .format("MMMM YYYY"),
          data: {
            uid,
            val: { ...val },
          },
        };

        return month_local;
      });

      var by_year = _.map(array_converted, (val, uid) => {
        var year_local = {
          label: moment_time_zone(val.survey_submitted_on)
            .tz("America/Toronto")
            .format("YYYY"),
          data: {
            uid,
            val: { ...val },
          },
        };

        return year_local;
      });
      ///////////////
      var seend_by_date = new Map();
      by_date = by_date.filter((entry) => {
        var previous;
        if (seend_by_date.hasOwnProperty(entry.label)) {
          previous = seend_by_date[entry.label];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        seend_by_date[entry.label] = entry;

        return true;
      });

      by_date = _.fromPairs(
        _.map(by_date, (i) => {
          return [i.label, i.data];
        })
      );

      by_date = _.map(by_date, (val, uid) => {
        var array_converted_by_date = _.fromPairs(
          _.map(val, (i) => {
            return [i.uid, i.val];
          })
        );
        array_converted_by_date = _.map(array_converted_by_date, (val, uid) => {
          return { uid, ...val };
        });

        return {
          uid,
          data: { ...array_converted_by_date },
        };
      });

      ///////////////

      ///////////////
      var seend_by_month = new Map();
      by_month = by_month.filter((entry) => {
        var previous;
        if (seend_by_month.hasOwnProperty(entry.label)) {
          previous = seend_by_month[entry.label];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        seend_by_month[entry.label] = entry;

        return true;
      });

      by_month = _.fromPairs(
        _.map(by_month, (i) => {
          return [i.label, i.data];
        })
      );

      by_month = _.map(by_month, (val, uid) => {
        var array_converted_by_month = _.fromPairs(
          _.map(val, (i) => {
            return [i.uid, i.val];
          })
        );
        array_converted_by_month = _.map(
          array_converted_by_month,
          (val, uid) => {
            return { uid, ...val };
          }
        );

        return {
          uid,
          data: { ...array_converted_by_month },
        };
      });

      ///////////////

      ///////////////
      var send_by_year = new Map();
      by_year = by_year.filter((entry) => {
        var previous;
        if (send_by_year.hasOwnProperty(entry.label)) {
          previous = send_by_year[entry.label];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        send_by_year[entry.label] = entry;

        return true;
      });

      by_year = _.fromPairs(
        _.map(by_year, (i) => {
          return [i.label, i.data];
        })
      );

      by_year = _.map(by_year, (val, uid) => {
        var array_converted_by_year = _.fromPairs(
          _.map(val, (i) => {
            return [i.uid, i.val];
          })
        );

        array_converted_by_year = _.map(array_converted_by_year, (val, uid) => {
          return { uid, ...val };
        });

        return {
          uid,
          data: { ...array_converted_by_year },
        };
      });

      array_converted = _.map(array_converted, (val, uid) => {
        return { uid, ...val };
      });

      var specific_data_snap = {
        all_surveys: { data: array_converted },
        by_date,
        by_month,
        by_year,
      };

      specific_data_snap = _.map(specific_data_snap, (val, uid) => {
        if (uid === "all_surveys") {
          return { selection: uid, data: val.data };
        } else {
          var new_val = val;
          if (uid === "by_date") {
            new_val = _.map(val, (val2, uid2) => {
              return { date: val2.uid, data: val2.data };
            });
          } else if (uid === "by_month") {
            new_val = _.map(val, (val2, uid2) => {
              return { month: val2.uid, data: val2.data };
            });
          } else if (uid === "by_year") {
            new_val = _.map(val, (val2, uid2) => {
              return { year: val2.uid, data: val2.data };
            });
          } else {
            return null;
          }
          return { selection: uid, data: new_val };
        }
      });

      return {
        survey_name: uid,
        data: specific_data_snap,
      };
    });

    all_data_by_survey_name = _.orderBy(
      all_data_by_survey_name,
      ["survey_name"],
      ["asc"]
    );

    var all_data_snap = {
      all_surveys: { data: all_data_snap },
      by_date: all_data_snap_by_date,
      by_month: all_data_snap_by_month,
      by_year: all_data_snap_by_year,
    };

    all_data_snap = _.map(all_data_snap, (val, uid) => {
      if (uid === "all_surveys") {
        return { selection: uid, data: val.data };
      } else {
        var new_val = val; //[];
        if (uid === "by_date") {
          new_val = _.map(val, (val2, uid2) => {
            return { date: uid2, data: val2 };
          });
        } else if (uid === "by_month") {
          new_val = _.map(val, (val2, uid2) => {
            return { month: uid2, data: val2 };
          });
        } else if (uid === "by_year") {
          new_val = _.map(val, (val2, uid2) => {
            return { year: uid2, data: val2 };
          });
        } else {
          return null;
        }
        return { selection: uid, data: new_val };
      }
    });

    this.setState({
      all_data_by_survey_name,
      all_data_snap,
    });
  }

  csat_nps_surveysCollected_trend(data, plot_data) {
    if (data.length === 0) {
      return null;
    } else {
      const { all_initial, specific_middle } = this.state;

      var nps_score_array = [],
        csat_score_array = [],
        number_of_surveys_array = [];

      plot_data.forEach((inital) => {
        var csat_array = [],
          nps_array = [];

        var questionaire_data = _.map(inital.data, (val, uid) => {
          return val;
        });

        questionaire_data.forEach((element) => {
          if (element.transaction_info.survey_type === "NPS & CSAT") {
            //CSAT
            var number = 0;
            var csat_answer = element.questionnaire_result[0].answer;
            if (csat_answer === "Very Satisfied") {
              number = 5;
            } else if (csat_answer === "Satisfied") {
              number = 4;
            } else if (csat_answer === "Neither Satisfied nor Dissatisfied") {
              number = 3;
            } else if (csat_answer === "Dissatisfied") {
              number = 2;
            } else {
              number = 1;
            }
            csat_array.push(number);
            //NPS
            var nps_answer = parseInt(element.questionnaire_result[2].answer);
            nps_array.push(nps_answer);
          }
        });

        //ALL
        var total_questions = csat_array.length;
        var nps_total_questions = nps_array.length;
        //NPS
        var promoters = _.countBy(nps_array, (rec) => {
          return rec > 8;
        }).true;
        if (promoters === undefined) {
          promoters = 0;
        }
        var passives = _.countBy(nps_array, (rec) => {
          return rec > 6 && rec < 9;
        }).true;
        if (passives === undefined) {
          passives = 0;
        }
        var detractors = _.countBy(nps_array, (rec) => {
          return rec < 7;
        }).true;
        if (detractors === undefined) {
          detractors = 0;
        }

        var percent_promoters = (promoters / nps_total_questions) * 100;
        if (percent_promoters === undefined) {
          percent_promoters = 0;
        }
        var percent_passives = (passives / nps_total_questions) * 100;
        if (percent_passives === undefined) {
          percent_passives = 0;
        }
        var percent_detractors = (detractors / nps_total_questions) * 100;
        if (percent_detractors === undefined) {
          percent_detractors = 0;
        }
        var nps_score = parseFloat(
          (percent_promoters - percent_detractors).toFixed(1)
        );
        //CSAT
        var very_satisfied = _.countBy(csat_array, (rec) => {
          return rec === 5;
        }).true;
        if (very_satisfied === undefined) {
          very_satisfied = 0;
        }
        var satisfied = _.countBy(csat_array, (rec) => {
          return rec === 4;
        }).true;
        if (satisfied === undefined) {
          satisfied = 0;
        }
        var neutral = _.countBy(csat_array, (rec) => {
          return rec === 3;
        }).true;
        if (neutral === undefined) {
          neutral = 0;
        }
        var dissatisfied = _.countBy(csat_array, (rec) => {
          return rec === 2;
        }).true;
        if (dissatisfied === undefined) {
          dissatisfied = 0;
        }
        var very_dissatisfied = _.countBy(csat_array, (rec) => {
          return rec === 1;
        }).true;
        if (very_dissatisfied === undefined) {
          very_dissatisfied = 0;
        }

        var csat_score = parseFloat(
          (
            ((very_satisfied * 5 +
              satisfied * 4 +
              neutral * 3 +
              dissatisfied * 2 +
              very_dissatisfied * 1) /
              (total_questions * 5)) *
            100
          ).toFixed(1)
        );
        var timestamp;
        if (specific_middle === "by_date" || all_initial === "by_date") {
          timestamp = moment(inital.date, "MMMM Do YYYY").valueOf();
        } else if (specific_middle === "by_year" || all_initial === "by_year") {
          timestamp = moment(inital.year, "YYYY").valueOf();
        } else {
          timestamp = moment(inital.month, "MMMM YYYY").valueOf();
        }

        nps_score_array.push([timestamp, nps_score]);

        csat_score_array.push([timestamp, csat_score]);

        number_of_surveys_array.push([timestamp, total_questions]);

        // csat_score_array.push({ csat_score, timestamp });

        // nps_score_array.push({ [timestamp]: nps_score });
        // csat_score_array.push([timestamp, csat_score]);
        // number_of_surveys_array.push([timestamp, total_questions]);
      });

      // var series_NPS = [
      //   {
      //     name: "Customer Loyalty (NPS)",
      //     data: nps_score_array,
      //   },
      // ];
      // var series_CSAT = [
      //   {
      //     name: "Customer Satisfaction (CSAT)",
      //     data: csat_score_array,
      //   },
      // ];

      // var series_number_of_surveys = [
      //   {
      //     name: "Completed Surveys",
      //     data: number_of_surveys_array,
      //   },
      // ];

      var series_NPS = {
        name: "Customer Loyalty (NPS)",
        data: nps_score_array,
      };

      var series_CSAT = {
        name: "Customer Satisfaction (CSAT)",
        data: csat_score_array,
      };

      var series_number_of_surveys = {
        name: "Completed Surveys",
        data: number_of_surveys_array,
      };

      return { series_NPS, series_CSAT, series_number_of_surveys };
    }
  }

  showcase_data(data) {
    if (data.length === 0) {
      return null;
    } else {
      // data.forEach((element) => {
      //   // console.log(element);
      // });
      var csat_array = [],
        nps_array = [];

      data.forEach((element) => {
        if (element.transaction_info.survey_type === "NPS & CSAT") {
          //CSAT
          var number = 0;
          var csat_answer = element.questionnaire_result[0].answer;
          if (csat_answer === "Very Satisfied") {
            number = 5;
          } else if (csat_answer === "Satisfied") {
            number = 4;
          } else if (csat_answer === "Neither Satisfied nor Dissatisfied") {
            number = 3;
          } else if (csat_answer === "Dissatisfied") {
            number = 2;
          } else {
            number = 1;
          }
          csat_array.push(number);
          //NPS
          var nps_answer = parseInt(element.questionnaire_result[2].answer);
          nps_array.push(nps_answer);
        }
      });

      // const { series_NPS, series_CSAT }

      //ALL
      var total_questions = csat_array.length;
      var nps_total_questions = nps_array.length;
      //NPS
      var promoters = _.countBy(nps_array, (rec) => {
        return rec > 8;
      }).true;
      if (promoters === undefined) {
        promoters = 0;
      }
      var passives = _.countBy(nps_array, (rec) => {
        return rec > 6 && rec < 9;
      }).true;
      if (passives === undefined) {
        passives = 0;
      }
      var detractors = _.countBy(nps_array, (rec) => {
        return rec < 7;
      }).true;
      if (detractors === undefined) {
        detractors = 0;
      }

      var percent_promoters = (promoters / nps_total_questions) * 100;
      if (percent_promoters === undefined) {
        percent_promoters = 0;
      }
      var percent_passives = (passives / nps_total_questions) * 100;
      if (percent_passives === undefined) {
        percent_passives = 0;
      }
      var percent_detractors = (detractors / nps_total_questions) * 100;
      if (percent_detractors === undefined) {
        percent_detractors = 0;
      }
      var nps_score = percent_promoters - percent_detractors;
      //CSAT
      var very_satisfied = _.countBy(csat_array, (rec) => {
        return rec === 5;
      }).true;
      if (very_satisfied === undefined) {
        very_satisfied = 0;
      }
      var satisfied = _.countBy(csat_array, (rec) => {
        return rec === 4;
      }).true;
      if (satisfied === undefined) {
        satisfied = 0;
      }
      var neutral = _.countBy(csat_array, (rec) => {
        return rec === 3;
      }).true;
      if (neutral === undefined) {
        neutral = 0;
      }
      var dissatisfied = _.countBy(csat_array, (rec) => {
        return rec === 2;
      }).true;
      if (dissatisfied === undefined) {
        dissatisfied = 0;
      }
      var very_dissatisfied = _.countBy(csat_array, (rec) => {
        return rec === 1;
      }).true;
      if (very_dissatisfied === undefined) {
        very_dissatisfied = 0;
      }
      var positive_score = (
        (very_satisfied / total_questions) * 100 +
        (satisfied / total_questions) * 100
      ).toFixed(1);

      // console.log(positive_score);

      //ALL
      var data_calculated = {
        nps: {
          promoters,
          passives,
          detractors,
          percent_promoters,
          percent_detractors,
          percent_passives,
          nps_score,
        },
        csat: {
          very_satisfied,
          satisfied,
          neutral,
          dissatisfied,
          very_dissatisfied,
          positive_score,
        },
        total_questions,
        csat_array,
        nps_array,
      };

      return data_calculated;
      /*  var multiple_choice = [];
      var open_text = [];
      var multiple_choice_item_render = [];
      var open_text_item_render = [];

      data.forEach((first_element) => {
        var questionnaire_result = first_element.questionnaire_result;
        var transaction_info = first_element.transaction_info;
        var survey_submitted_on = first_element.survey_submitted_on;

        questionnaire_result.forEach((second_element) => {
          var answer = second_element.answer;
          var question = second_element.question;
          var type_of_question = second_element.type_of_question;
          if (
            type_of_question === "MCQ" ||
            type_of_question === "ConditionalMCQ" ||
            type_of_question === "NPS" ||
            type_of_question === "ConditionalNPS" ||
            type_of_question === "CSAT" ||
            type_of_question === "ConditionalCSAT"
          ) {
            multiple_choice.push({
              question,
              data: {
                question,
                answer,
                transaction_info,
                survey_submitted_on,
                type_of_question,
              },
            });
          } else {
            open_text.push({
              question,
              data: {
                question,
                answer,
                transaction_info,
                survey_submitted_on,
                type_of_question,
              },
            });
          }
        });
      });

      /////////////////////////////////////////////////////
      var seen_multiple_choice = new Map();
      var cluster_multiple_choice = multiple_choice.filter((entry) => {
        var previous;
        if (seen_multiple_choice.hasOwnProperty(entry.question)) {
          previous = seen_multiple_choice[entry.question];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        seen_multiple_choice[entry.question] = entry;

        return true;
      });
      //

      //
      var seen_open_text = new Map();
      var cluster_open_text = open_text.filter((entry) => {
        var previous;
        if (seen_open_text.hasOwnProperty(entry.question)) {
          previous = seen_open_text[entry.question];
          previous.data.push(entry.data);

          return false;
        }
        if (!Array.isArray(entry.data)) {
          entry.data = [entry.data];
        }
        seen_open_text[entry.question] = entry;

        return true;
      });

      //Bottom Only for multiple choice START
      cluster_multiple_choice.forEach((first_element, p) => {
        var array = [];
        first_element.data.forEach((second_element) => {
          array.push(second_element.answer);
        });

        array.sort();
        var array_uniqBy_and_count = [];
        var array_uniqBy_choice_only = [];
        var array_uniqBy_count_only = [];

        var current = null;
        var cnt = 0;

        array.forEach((third_element) => {
          if (third_element != current) {
            if (cnt > 0) {
              array_uniqBy_and_count.push({
                choice: current,
                qty: cnt,
              });

              array_uniqBy_choice_only.push(current);
              //array_uniqBy_count_only.push(cnt);
              array_uniqBy_count_only.push({
                value: cnt,
                name: current,
              });
            }
            current = third_element;
            cnt = 1;
          } else {
            cnt++;
          }
        });

        if (cnt > 0) {
          array_uniqBy_and_count.push({
            choice: current,
            qty: cnt,
          });
          array_uniqBy_choice_only.push(current);
          //  array_uniqBy_count_only.push(cnt);
          array_uniqBy_count_only.push({
            value: cnt,
            name: current,
          });
        }

        multiple_choice_item_render.push({
          question: first_element.question,
          labels: array_uniqBy_choice_only,
          data: array_uniqBy_count_only,
        });
      });
      //TOP Only for multiple choice END

      cluster_open_text.forEach((first_element, q) => {
        var open_text_data = [];

        first_element.data.forEach((second_element, p) => {
          var workshop_name = second_element.transaction_info.survey_name;
          if (second_element.transaction_info.branch_survey_name) {
            workshop_name =
              second_element.transaction_info.survey_name +
              " : " +
              second_element.transaction_info.branch_survey_name;
          }
          open_text_data.push({
            id: p + 1,
            response: second_element.answer,
            workshop: workshop_name, //second_element.transaction_info.survey_name,
            date: moment(second_element.survey_submitted_on).format(
              "dddd, MMMM Do YYYY"
            ),
          });
        });

        var columns = [
          {
            dataField: "id",
            text: "ID",
            sort: true,
          },
          {
            dataField: "response",
            text: "Response",
            sort: true,
          },
          {
            dataField: "workshop",
            text: "Workshop",
            sort: true,
          },
          {
            dataField: "date",
            text: "Date",
            sort: true,
          },
        ];

        open_text_item_render.push({
          question: first_element.question,
          data: open_text_data,
          columns: columns,
        });
      });

      return { multiple_choice_item_render, open_text_item_render };
      */
    }
  }

  export_data(data) {
    if (data.length === 0) {
      return null;
    } else {
      var master_data_all_rows = [];
      var master_data_all_coloums = [];
      var master_data_all_questions = [];
      var master_data_for_export_only = [];

      var master_data_all = null;

      data.forEach((element, i) => {
        var temp_array_for_rows = [];
        var temp_array_data_for_export_only = [];

        element.questionnaire_result.forEach((question_data, p) => {
          master_data_all_questions.push(question_data.question);
          var special_characters_and_spaces_removed_and_make_it_lowercase = question_data.question
            .replace(/[^\w\s]/gi, "")
            .replace(/ /g, "_")
            .toLowerCase();

          temp_array_for_rows.push({
            [special_characters_and_spaces_removed_and_make_it_lowercase]:
              question_data.answer,
          });

          temp_array_data_for_export_only.push({
            Submitted: moment_time_zone(element.survey_submitted_on)
              .tz("America/Toronto")
              .format("MMMM Do YYYY"),
            Name: element.transaction_info.survey_name,
            [question_data.question]: question_data.answer,
          });
        });

        var multiple_objects_into_one = temp_array_for_rows.reduce(
          (r, c) => Object.assign(r, c),
          {}
        );

        master_data_all_rows.push(multiple_objects_into_one);

        var new_multiple_objects_into_one = temp_array_data_for_export_only.reduce(
          (r, c) => Object.assign(r, c),
          {}
        );

        //  console.log(new_multiple_objects_into_one);
        master_data_for_export_only.push(new_multiple_objects_into_one);
      });

      //  console.log(master_data_all_questions);

      master_data_all_questions = _.uniq(master_data_all_questions);

      master_data_all_questions.forEach((element, i) => {
        var special_characters_and_spaces_removed_and_make_it_lowercase = element
          .replace(/[^\w\s]/gi, "")
          .replace(/ /g, "_")
          .toLowerCase();
        master_data_all_coloums.push({
          label: element,
          field: special_characters_and_spaces_removed_and_make_it_lowercase,
          sort: "asc",
        });
      });

      master_data_all = {
        columns: master_data_all_coloums,
        rows: master_data_all_rows,
        export_only: master_data_for_export_only,
      };

      // return {
      //   master_data_all,
      // };
      return master_data_for_export_only;
    }
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  render_label_all() {
    const { all_initial } = this.state;
    if (all_initial === "by_date") {
      return <Label>Select Date:</Label>;
    } else if (all_initial === "by_month") {
      return <Label>Select Month:</Label>;
    }
    if (all_initial === "by_year") {
      return <Label>Select Year:</Label>;
    }

    return <Label>Select :</Label>;
  }
  render_label_specific() {
    const { specific_middle } = this.state;
    if (specific_middle === "by_date") {
      return <Label>Select Date:</Label>;
    } else if (specific_middle === "by_month") {
      return <Label>Select Month:</Label>;
    }
    if (specific_middle === "by_year") {
      return <Label>Select Year:</Label>;
    }

    return <Label>Select :</Label>;
  }

  render_page() {
    const {
      specific_event_names,
      all_data_by_survey_name,
      all_data_snap,
      specific_final,
      specific_middle,
      specific_initial,
      all_initial,
      all_final,
    } = this.state;
    const { user_details } = this.props;

    var survey_name_option = [];
    var survey_name_option_last_filter = [];
    var all_option_last_filter = [];
    if (user_details.role === "Facilitator") {
      var programs = _.map(user_details.all_programs, (val, uid) => {
        return { ...val, uid };
      });
      programs = programs[0];

      var limited_access = _.map(programs.survey_access, (val, uid) => {
        return { ...val, uid };
      });
      limited_access.forEach((element_access) => {
        all_data_by_survey_name.forEach((element, i) => {
          var use_name = "";
          if (element_access.access_choice_funnel) {
            use_name =
              element_access.uid + " : " + element_access.access_choice_funnel;
          } else {
            use_name = element_access.uid;
          }
          if (use_name === element.survey_name) {
            survey_name_option.push({ label: element.survey_name, value: i });
          }
        });
      });
    } else {
      all_data_by_survey_name.forEach((element, i) => {
        survey_name_option.push({ label: element.survey_name, value: i });
      });
    }

    var data = [];
    var plot_data = [];
    if (this.state.switch === "1") {
      if (
        (specific_initial !== null && specific_initial !== "") ||
        (specific_middle !== null && specific_middle !== "")
      ) {
        data =
          all_data_by_survey_name[this.state.specific_initial].data[0].data;
        plot_data =
          all_data_by_survey_name[this.state.specific_initial].data[2].data;
        if (specific_middle !== null || specific_middle !== "") {
          if (specific_middle === "by_date") {
            data =
              all_data_by_survey_name[this.state.specific_initial].data[1].data;
            plot_data = data;
            data.forEach((element, i) => {
              survey_name_option_last_filter.push({
                label: element.date,
                value: i,
              });
            });
          }
          if (specific_middle === "by_month") {
            data =
              all_data_by_survey_name[this.state.specific_initial].data[2].data;
            plot_data = data;
            data.forEach((element, i) => {
              survey_name_option_last_filter.push({
                label: element.month,
                value: i,
              });
            });
          }
          if (specific_middle === "by_year") {
            data =
              all_data_by_survey_name[this.state.specific_initial].data[3].data;
            plot_data = data;
            data.forEach((element, i) => {
              survey_name_option_last_filter.push({
                label: element.year,
                value: i,
              });
            });
          }
        }
      }
    } else {
      data = all_data_snap[0].data;
      plot_data = all_data_snap[2].data;

      if (all_initial !== null || all_initial !== "") {
        if (all_initial === "by_date") {
          data = all_data_snap[1].data;
          plot_data = data;
          data.forEach((element, i) => {
            all_option_last_filter.push({ label: element.date, value: i });
          });
        }
        if (all_initial === "by_month") {
          data = all_data_snap[2].data;
          plot_data = data;
          data.forEach((element, i) => {
            all_option_last_filter.push({ label: element.month, value: i });
          });
        }
        if (all_initial === "by_year") {
          data = all_data_snap[3].data;
          plot_data = data;
          data.forEach((element, i) => {
            all_option_last_filter.push({ label: element.year, value: i });
          });
        }
      }
    }

    var show_data = [];
    if (this.state.switch === "1") {
      if (
        specific_initial !== "" &&
        specific_middle === "" &&
        specific_final === ""
      ) {
        show_data = data;
      } else if (
        specific_initial !== "" &&
        specific_middle !== "" &&
        specific_final !== ""
      ) {
        show_data = _.map(data[specific_final].data, (val, uid) => {
          return val;
        });
        //data[specific_final].data;
      } else {
        //do nothing
      }
    } else {
      if (all_initial === "" && all_final === "") {
        show_data = data;
      } else if (all_initial !== "" && all_final !== "") {
        show_data = _.map(data[all_final].data, (val, uid) => {
          return val;
        });
      } else {
        //do nothing
      }
    }

    const list_of_surveys = [
      {
        label: "All",
        options: [{ label: "All Surveys", value: "All Surveys" }],
      },
      {
        label: "Specific",
        options: survey_name_option,
      },
    ];
    var last_filter_label = "Select...";
    if (all_initial === "by_month" || specific_middle === "by_month") {
      var last_filter_label = "Select Month...";
    } else if (all_initial === "by_year" || specific_middle === "by_year") {
      var last_filter_label = "Select Year...";
    } else if (all_initial === "by_date" || specific_middle === "by_date") {
      var last_filter_label = "Select Date...";
    } else {
      var last_filter_label = "Select...";
    }
    var list_of_last_filter = [];

    if (this.state.initial_filter === "ALL") {
      list_of_last_filter = [
        {
          label: last_filter_label,
          options: all_option_last_filter,
        },
      ];
    } else {
      list_of_last_filter = [
        {
          label: last_filter_label,
          options: survey_name_option_last_filter,
        },
      ];
    }
    var reports = [
      {
        title: "Number of Surveys",
        iconClass: "bx bx-select-multiple",
        description: "",
      },
      {
        title: "CSAT",
        iconClass: "bx bx-smile",
        description: "",
      },
      {
        title: "NPS",
        iconClass: "bx bx-line-chart",
        description: "",
      },
    ];

    // var all_data_by_date = all_data_snap[1].data;
    // var all_data_by_month = all_data_snap[2].data;
    // var all_data_by_year = all_data_snap[3].data;
    // all_data_by_date,
    // all_data_by_month,
    // all_data_by_year,
    // all_initial

    var showcase_data = this.showcase_data(show_data);

    const csat_nps_surveysCollected_trend = this.csat_nps_surveysCollected_trend(
      data,
      plot_data
    );

    var nps_score_detail = "",
      csat_score_detail = "";

    if (showcase_data !== null) {
      reports[0].description = showcase_data.total_questions;
      reports[1].description = parseFloat(showcase_data.csat.positive_score);
      reports[2].description = showcase_data.nps.nps_score.toFixed(1);

      if (showcase_data.nps.nps_score >= 70) {
        nps_score_detail = <span style={{ color: "#22aa58" }}>Excellent</span>;
      } else if (
        showcase_data.nps.nps_score >= 30 &&
        showcase_data.nps.nps_score < 70
      ) {
        nps_score_detail = <span style={{ color: "#3cc273" }}>Great</span>; //= "Your customer loyalty is great";
      } else if (
        showcase_data.nps.nps_score >= 0 &&
        showcase_data.nps.nps_score < 30
      ) {
        nps_score_detail = <span style={{ color: "#f9b73e" }}>Fair</span>; //= "Your customer loyalty is good";
      } else {
        nps_score_detail = (
          <span style={{ color: "#f16e64" }}>Needs Improvement</span>
        ); // = "Your customer loyalty requires improvement";
      }

      if (showcase_data.csat.positive_score >= 90) {
        csat_score_detail = <span style={{ color: "#22aa58" }}>Excellent</span>; //= "our Customer Satisfaction is Excellent";
      }
      if (
        showcase_data.csat.positive_score >= 80 &&
        showcase_data.csat.positive_score < 90
      ) {
        csat_score_detail = <span style={{ color: "#3cc273" }}>Great</span>;
      } else if (
        showcase_data.csat.positive_score >= 60 &&
        showcase_data.csat.positive_score < 80
      ) {
        csat_score_detail = <span style={{ color: "#f9b73e" }}>Fair</span>;
      } else {
        csat_score_detail = (
          <span style={{ color: "#f16e64" }}>Needs Improvement</span>
        );
      }
    }

    return (
      <div>
        <Row>
          <Col xl="4">
            <Card

            //className="overflow-hidden"
            >
              <div className="bg-soft-primary">
                <Row>
                  <Col xs="6">
                    <div className="text-primary p-3">
                      <h5 className="text-primary">Filter: </h5>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="text-primary p-3">
                      <div className="text-right">
                        <Button
                          color="light"
                          size="sm"
                          type="button"
                          onClick={() => {
                            //
                          }}
                          id="reset"
                        >
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </Col>

                  <Col xs="12">
                    <div className="text-primary p-2">
                      <Select
                        style={{ color: "red" }}
                        placeholder="Select Survey..."
                        //  value={this.state.name_filter}
                        onChange={(event) => {
                          if (event.value === "All Surveys") {
                            this.setState({
                              activeTab: "2",
                              switch: "2", //might want to remove this
                              loading: true,
                              all_initial: "",
                              all_final: "",
                              specific_middle: "",
                              specific_final: "",
                              initial_filter: "ALL",
                            });
                            setTimeout(() => {
                              this.setState({
                                switch: "2",
                                loading: false,
                              });
                            }, 500);
                          } else {
                            this.setState({
                              activeTab: "1",
                              switch: "1",
                              specific_initial: event.value,
                              specific_middle: "",
                              specific_final: "",
                              all_initial: "",
                              all_final: "",
                              initial_filter: "SPECIFIC",
                            });
                          }
                        }}
                        options={list_of_surveys}
                      />
                    </div>
                  </Col>

                  <Col xs="12">
                    <div className="text-primary p-1">
                      <div
                        id="overview-chart"
                        className="apex-charts"
                        dir="ltr"
                      >
                        <div className="toolbar button-items text-center">
                          <Button
                            color="light"
                            size="sm"
                            type="button"
                            className={this.state.activeM_CSAT ? "active" : ""}
                            onClick={() => {
                              //this.updateChartYearly_CSAT()

                              if (this.state.initial_filter === "ALL") {
                                this.setState({
                                  all_initial: "by_date",
                                  all_final: "",
                                });
                              } else {
                                this.setState({
                                  specific_middle: "by_date",
                                  specific_final: "",
                                });
                              }
                            }}
                            id="one_month"
                          >
                            By Date
                          </Button>
                          <Button
                            color="light"
                            size="sm"
                            type="button"
                            className={this.state.active6M_CSAT ? "active" : ""}
                            onClick={() => {
                              //this.updateChartYearly_CSAT()
                              // this.setState({
                              //   all_initial: "by_year",
                              //   all_final: "",
                              // });

                              if (this.state.initial_filter === "ALL") {
                                this.setState({
                                  all_initial: "by_month",
                                  all_final: "",
                                });
                              } else {
                                this.setState({
                                  specific_middle: "by_month",
                                  specific_final: "",
                                });
                              }
                            }}
                            id="six_months"
                          >
                            By Month
                          </Button>
                          <Button
                            color="light"
                            size="sm"
                            type="button"
                            className={this.state.activeY_CSAT ? "active" : ""}
                            onClick={() => {
                              //this.updateChartYearly_CSAT()
                              // this.setState({
                              //   all_initial: "by_month",
                              //   all_final: "",
                              // });

                              if (this.state.initial_filter === "ALL") {
                                this.setState({
                                  all_initial: "by_year",
                                  all_final: "",
                                });
                              } else {
                                this.setState({
                                  specific_middle: "by_year",
                                  specific_final: "",
                                });
                              }
                            }}
                            id="one_year"
                          >
                            By Year
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="text-primary p-2">
                      <Select
                        style={{ color: "red" }}
                        placeholder={last_filter_label} //"Select Date..."
                        value={this.state.name_filter}
                        onChange={(event) => {
                          //

                          if (this.state.initial_filter === "ALL") {
                            this.setState({
                              all_final: event.value,
                            });
                          } else {
                            this.setState({
                              specific_final: event.value,
                            });
                          }
                        }}
                        options={list_of_last_filter}
                      />
                    </div>
                  </Col>

                  {/*     <Col xs="5" className="align-self-end"></Col>*/}
                </Row>
              </div>
              {/*<CardBody className="pt-0">
                <Row>
                  <Col sm="4">
                    <div className="avatar-md profile-user-wid mb-4"></div>
                    <h5 className="font-size-15 text-truncate">Henry Price</h5>
                    <p className="text-muted mb-0 text-truncate">
                      UI/UX Designer
                    </p>
                  </Col>

                  <Col sm="8">
                    <div className="pt-4">
                      <Row>
                        <Col xs="6">
                          <h5 className="font-size-15">125</h5>
                          <p className="text-muted mb-0">Projects</p>
                        </Col>
                        <Col xs="6">
                          <h5 className="font-size-15">$1245</h5>
                          <p className="text-muted mb-0">Revenue</p>
                        </Col>
                      </Row>
                      <div className="mt-4"></div>
                    </div>
                  </Col>
                </Row>
              </CardBody>*/}
            </Card>
            {/*<MonthlyEarning />*/}
            {/*<TopCities />*/}
            {<NPS_Guage data={showcase_data} />}
          </Col>
          <Col xl="8">
            <Card>
              <CardBody>
                <Row>
                  <Col className="lg-6">
                    <h5 className="font-size-15">
                      Customer Loyalty: {nps_score_detail}
                    </h5>
                  </Col>
                  <Col className="lg-6">
                    <h5 className="font-size-15">
                      Customer Satisfaction: {csat_score_detail}
                    </h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              {/* Reports Render */}
              {reports.map((report, key) => (
                <Col md="4" key={"_col_" + key}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Media>
                        <Media body>
                          <p className="text-muted font-weight-medium">
                            {report.title}
                          </p>
                          <h4 className="mb-0">{report.description}</h4>
                        </Media>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>

            <Card>
              <CardBody>
                {/************************************* */}

                {/*  <CardTitle className="mb-4 float-sm-left">Email Sent</CardTitle>*/}

                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.state.activeTab_trend_and_satisfaction === "1",
                      })}
                      onClick={() => {
                        this.setState({
                          activeTab_trend_and_satisfaction: "1",
                        });
                      }}
                    >
                      Customer Satifaction Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.state.activeTab_trend_and_satisfaction === "2",
                      })}
                      onClick={() => {
                        this.setState({
                          activeTab_trend_and_satisfaction: "2",
                        });
                      }}
                    >
                      Long Term Trend
                    </NavLink>
                  </NavItem>
                </ul>

                {/************************************* */}

                <div className="float-sm-right">
                  {/* <ul className="nav nav-pills">
                    {this.state.email.map((mail, key) => (
                      <li className="nav-item" key={"_li_" + key}>
                        <Button
                          className={
                            mail.isActive ? "nav-link active" : "nav-link"
                          }
                          to={mail.linkto}
                        >
                          {mail.title}
                        </Button>
                      </li>
                    ))}
                  </ul>*/}
                </div>
                <div className="clearfix"></div>

                {this.state.activeTab_trend_and_satisfaction === "1" ? (
                  <CSAT_Donut data={showcase_data} />
                ) : (
                  <CSAT_NPS_TREND
                    data={csat_nps_surveysCollected_trend}
                    filter_all={this.state.all_initial}
                    filter_specific={this.state.specific_middle}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/*
                            <StackedColumnChart />


                            <Row>
          <Col xl="4">
            <SocialSource />
          </Col>
          <Col xl="4">
            <ActivityComp />
          </Col>

          <Col xl="4">
            <TopCities />
          </Col>
        </Row>*/}
        {/*<CSAT_Donut data={this.state.csat_nps_functionality} />*/}
        <Row>
          <Col lg="12">
            {/*<LatestTranaction />*/}

            {show_data.length > 0 ? <Responses data={show_data} /> : null}
          </Col>
        </Row>
      </div>
    );
    /*    var export_data = this.export_data(show_data);

    return (
      <Col lg="12">
        <Card>
          <CardBody>
            <h4 className="card-title mb-3">Dashboard</h4>

            {this.props.user_details.role !== "Facilitator" ? (
              <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={() => {
                      this.setState({
                        activeTab: "1",
                        switch: "1",
                        specific_initial: "",
                        specific_middle: "",
                        specific_final: "",
                      });

                      // this.toggleTab("1");
                    }}
                  >
                    Specific Surveys
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2",
                    })}
                    onClick={() => {
                      this.setState({
                        activeTab: "2",
                        loading: true,
                        all_initial: "",
                        all_final: "",
                      });
                      setTimeout(() => {
                        this.setState({
                          switch: "2",
                          loading: false,
                        });
                      }, 500);
                    }}
                  >
                    All Surveys
                  </NavLink>
                </NavItem>
              </ul>
            ) : null}
            <TabContent activeTab={this.state.activeTab} className="p-3">
              <TabPane tabId="1" id="processing">
                <div>
                  <Form>
                    <Row>
                      <div className="col-xl col-sm-6">
                        <FormGroup className="mt-3 mb-0">
                          <Label>Name :</Label>
                          <select
                            value={this.state.specific_initial}
                            onChange={(event) => {
                              event.preventDefault();

                              this.setState({
                                specific_initial: event.target.value,
                                specific_middle: "",
                                specific_final: "",
                              });
                            }}
                            className="form-control select2-search-disable"
                          >
                            <option value="" defaultValue>
                              Select...
                            </option>
                            {survey_name_option}
                          </select>
                        </FormGroup>
                      </div>

                      <div className="col-xl col-sm-6">
                        <FormGroup className="mt-3 mb-0">
                          <Label>Filter By :</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={this.state.specific_middle}
                            onChange={(event) => {
                              event.preventDefault();

                              this.setState({
                                specific_middle: event.target.value,
                                specific_final: "",
                              });
                            }}
                          >
                            <option value="" defaultValue>
                              Select...
                            </option>
                            <option value="by_date">Date</option>
                            <option value="by_month">Month</option>
                            <option value="by_year">Year</option>
                          </select>
                        </FormGroup>
                      </div>

                      {this.state.specific_middle === "" ? null : (
                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            {this.render_label_specific()}

                            <select
                              className="form-control select2-search-disable"
                              value={this.state.specific_final}
                              onChange={(event) => {
                                event.preventDefault();
                                this.setState({
                                  specific_final: event.target.value,
                                });
                              }}
                            >
                              <option value="" defaultValue>
                                Select...
                              </option>
                              {survey_name_option_last_filter}
                            </select>
                          </FormGroup>
                        </div>
                      )}

                      {export_data === null ? null : (
                        <div className="col-xl col-sm-6 align-self-end">
                          <div className="mt-3">
                            <div>
                              <CSVLink
                                filename={`Luup_Export_${this.state.exportName}.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  {this.state.export_title}
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Form>
                </div>
              </TabPane>

              <TabPane tabId="2" id="all-order">
                <div>
                  <Form>
                    <Row>
                      <div className="col-xl col-sm-6">
                        <FormGroup className="mt-3 mb-0">
                          <Label>Filter By :</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={this.state.all_initial}
                            onChange={(event) => {
                              event.preventDefault();
                              this.setState({
                                all_initial: event.target.value,
                                all_final: "",
                              });
                            }}
                          >
                            <option value="" defaultValue>
                              Select...
                            </option>
                            <option value="by_date">Date</option>
                            <option value="by_month">Month</option>
                            <option value="by_year">Year</option>
                          </select>
                        </FormGroup>
                      </div>

                      {this.state.all_initial === "" ? null : (
                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            {this.render_label_all()}

                            <select
                              className="form-control select2-search-disable"
                              value={this.state.all_final}
                              onChange={(event) => {
                                event.preventDefault();

                                this.setState({
                                  all_final: event.target.value,
                                });
                              }}
                            >
                              <option value="" defaultValue>
                                Select...
                              </option>

                              {all_option_last_filter}
                            </select>
                          </FormGroup>
                        </div>
                      )}

                      {export_data === null ? null : (
                        <div className="col-xl col-sm-6 align-self-end">
                          <div className="mt-3">
                            <div>
                              <CSVLink
                                filename={`Luup_Export_${this.state.exportName}.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  {this.state.export_title}
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Form>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>

        {this.state.loading === true ? (
          <div>{this.render_loading()}</div>
        ) : null}
        {showcase_data === null ? null : (
          <div>
            <Chart showcase_data={showcase_data} />
            <OpenField showcase_data={showcase_data} />
          </div>
        )}
      </Col>
    );

    */
  }
  render() {
    const { all_data_by_survey_name, all_data_snap } = this.state;
    if (all_data_by_survey_name.length === 0 || all_data_snap.length === 0) {
      return (
        <React.Fragment>
          <div>{this.render_loading()}</div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div>{this.render_page()}</div>
      </React.Fragment>
    );
  }
}

export default MCQ_OpenField;
