import React, { Component } from "react";
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import moment from "moment";

import ReactApexChart from "react-apexcharts";

class Earning extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: "1" };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  csat_processing() {
    const { data } = this.props;

    var labels = [],
      responses = [];

    data.series_CSAT.data.sort().forEach((element) => {
      labels.push(moment(element[0]).format("L"));
      responses.push(element[1]);
    });

    var CSAT_series = [
      {
        name: "Satisfaction Average",
        type: "line",
        data: responses,
      },

      {
        name: "Satisfaction Average",
        type: "line",
        data: responses,
      },
    ];

    var CSAT_options = {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 2, 4],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: ["#f46a6a", "#f46a6a", "#f46a6a"],

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: labels,

      markers: {
        size: 7,
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Rating",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y + " Rating";
              //  return y.toFixed(0) + " points";
            }
            return y + " Rating";
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    };

    return { CSAT_series, CSAT_options };
    /* const { csat_data, nps_data } = this.props;

    var data_satisfaction = [],
      labels = [],
      data_number_of_responses = [];

    if (csat_data.lenght !== 0) {
      csat_data[0].data.forEach((element) => {
        labels.push(moment(element[0]).format("L"));

        data_satisfaction.push(parseFloat(element[1].toFixed(2)));
      });
      data_number_of_responses = csat_data[0].number_of_responses;
    }

    var CSAT_series = [
      {
        name: "Satisfaction Average",
        type: "line",
        data: data_satisfaction,
      },

      {
        name: "Satisfaction Average",
        type: "line",
        data: data_satisfaction,
      },
    ];

    var CSAT_options = {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 2, 4],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: ["#f46a6a", "#f46a6a", "#f46a6a"],

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: labels,

      markers: {
        size: 7,
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Rating",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y + " Rating";
              //  return y.toFixed(0) + " points";
            }
            return y + " Rating";
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    };

    return { CSAT_series, CSAT_options };
    */
  }

  nps_processing() {
    const { data } = this.props;

    var labels = [],
      responses = [];

    data.series_NPS.data.sort().forEach((element) => {
      labels.push(moment(element[0]).format("L"));
      responses.push(element[1]);
    });

    var NPS_series = [
      {
        name: "NPS Score",
        type: "line",
        data: responses,
      },
      {
        name: "NPS Score",
        type: "line",
        data: responses,
      },
    ];

    var NPS_options = {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 2, 4],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: ["#556ee6"],

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: labels,

      markers: {
        size: 7,
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "NPS Score",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y + " NPS Score";
            }
            return y + " NPS Score";
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    };

    return { NPS_series, NPS_options };
  }

  number_of_survey_processing() {
    const { data } = this.props;

    var labels = [],
      responses = [];

    data.series_number_of_surveys.data.sort().forEach((element) => {
      labels.push(moment(element[0]).format("L"));
      responses.push(element[1]);
    });

    var number_of_surveys_series = [
      {
        name: "# of Surveys",
        type: "column",
        data: responses,
      },
    ];

    var number_of_surveys_options = {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 2, 4],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: ["#34c38f"],

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: labels,
      markers: {
        size: 7,
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "# of Surveys",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y + " Surveys";
            }
            return y + " Surveys";
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    };
    return { number_of_surveys_series, number_of_surveys_options };
  }
  render_nav() {
    const { data, filter_all, filter_specific } = this.props;

    var data_show = "";
    if (filter_all === "by_date" || filter_specific === "by_date") {
      data_show = "(Daily Trend)";
    } else if (filter_all === "by_year" || filter_specific === "by_year") {
      data_show = "(Yearly Trend)";
    } else {
      data_show = "(Monthly Trend)";
    }
    return (
      <Nav pills className="rounded">
        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "1",
            })}
            onClick={() => {
              if (this.state.activeTab !== "1") {
                this.toggleTab("1");
              }
            }}
          >
            Customer Satisfaction {data_show}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "2",
            })}
            onClick={() => {
              if (this.state.activeTab !== "2") {
                this.toggleTab("2");
              }
            }}
          >
            Customer Loyalty {data_show}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "3",
            })}
            onClick={() => {
              if (this.state.activeTab !== "3") {
                this.toggleTab("3");
              }
            }}
          >
            Completed Surveys {data_show}
          </NavLink>
        </NavItem>
      </Nav>
    );
  }

  render_charts() {
    const { CSAT_series, CSAT_options } = this.csat_processing();
    const { NPS_series, NPS_options } = this.nps_processing();

    const {
      number_of_surveys_series,
      number_of_surveys_options,
    } = this.number_of_survey_processing();

    if (this.state.activeTab === "1") {
      return (
        <Row>
          <Col lg="12">
            <div id="line-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                options={CSAT_options}
                series={CSAT_series}
                type="line"
                height="380"
              />
            </div>
          </Col>
        </Row>
      );
    } else if (this.state.activeTab === "2") {
      return (
        <Row>
          <Col lg="12">
            <div id="line-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                options={NPS_options}
                series={NPS_series}
                type="line"
                height="380"
              />
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col lg="12">
            <div id="line-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                options={number_of_surveys_options}
                series={number_of_surveys_series}
                type="line"
                height="380"
              />
            </div>
          </Col>
        </Row>
      );
    }
  }

  render() {
    if (this.props.data === null) {
      return null;
    }
    return (
      <React.Fragment>
        {/*  <Col xl="12">
          <Card>*/}
        <CardBody>
          {this.render_nav()}
          {this.render_charts()}
        </CardBody>
        {/*      </Card>
        </Col>*/}
      </React.Fragment>
    );
  }
}

export default Earning;
