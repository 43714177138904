import _ from "lodash";
import React, { Component } from "react";
import Lottie from "react-lottie";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  Input,
  Alert,
  Card,
  CardBody,
  FormGroup,
  CardTitle,
  Badge,
} from "reactstrap";
import firebase from "firebase/app";
import "firebase/database";
import "./CSS/access.css";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { access_read } from "../../store/actions";

class Access extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //CHECK AND DELECT THE ONES WHICH ARE NOT NEEDED
      startDate: new Date(),
      activeTab: "1",
      specific_event_name: "",
      filter_by_specific_data: "",
      filter_by_all_data: "",
      last_filter_for_specific: "",
      last_filter_for_all: "",
      modal: false,
      modal_sure_delete: false,
      delete_user_data: {},
      full_name: "",
      email: "",
      loading_modal: false,
      error: "",
      success: "",
    };
  }

  componentDidMount() {
    this.props.access_read();
  }
  modal_sure_delete() {
    const { delete_user_data } = this.state;

    return (
      <Modal
        isOpen={this.state.modal_sure_delete}
        className="outline_class"
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={() => {
          this.setState((prevState) => ({
            modal_sure_delete: !prevState.modal_sure_delete,
          }));
        }}
      >
        <div className="modal-content">
          <ModalBody>
            {this.state.loading_modal === false ? (
              <div>
                {this.state.success !== "" || this.state.error !== "" ? (
                  <div>
                    {this.state.success === "" ? (
                      <div>
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.state.error}
                        </Alert>

                        <div
                          className="text-center"
                          onClick={(event) => {
                            event.preventDefault();
                            this.setState((prevState) => ({
                              modal_sure_delete: !prevState.modal_sure_delete,

                              specific_event_name: "",
                              filter_by_specific_data: "",
                              filter_by_all_data: "",
                              last_filter_for_specific: "",
                              last_filter_for_all: "",
                              delete_user_data: {},
                              full_name: "",
                              email: "",
                              loading_modal: false,
                              error: "",
                              success: "",
                            }));
                          }}
                        >
                          <Button color="primary">Okay</Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.state.success}
                        </Alert>
                        <div className="text-center">
                          <Button
                            color="primary"
                            onClick={(event) => {
                              event.preventDefault();
                              // this.props.access_reader_request();
                              this.props.access_read();

                              this.setState((prevState) => ({
                                modal_sure_delete: !prevState.modal_sure_delete,
                                specific_event_name: "",
                                filter_by_specific_data: "",
                                filter_by_all_data: "",
                                last_filter_for_specific: "",
                                last_filter_for_all: "",
                                delete_user_data: {},
                                full_name: "",
                                email: "",
                                loading_modal: false,
                                error: "",
                                success: "",
                              }));
                            }}
                          >
                            Okay
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-center">
                    <Row className="justify-content-center">
                      <Col>
                        <div>
                          <h1 className="mt-4 font-weight-semibold">
                            Are you sure?
                          </h1>
                          <div className="mt-4">
                            <Row className="justify-content-center">
                              <div style={{ padding: 5 }}>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-lg"
                                  onClick={() => {
                                    var user_uid =
                                      firebase.auth().currentUser.uid;
                                    var admin_email =
                                      firebase.auth().currentUser.email;

                                    var data = delete_user_data;
                                    data.admin_email = admin_email;

                                    firebase
                                      .database()
                                      .ref(`/users/${user_uid}/`)
                                      .once("value", (app_user_details) => {
                                        if (app_user_details.val() === null) {
                                          data.app_user = false;
                                        } else {
                                          data.app_user = true;
                                        }

                                        firebase
                                          .database()
                                          .ref(
                                            `/partner/users/${user_uid}/request_delete_limited_account/`
                                          )
                                          .push(data)
                                          .then(() => {
                                            this.setState({
                                              loading_modal: true,
                                            });
                                            setTimeout(() => {
                                              this.setState((prevState) => ({
                                                loading_modal: false,
                                                success:
                                                  "Your request is being processed. It may take a  few minutes, a confirmation email will be sent to you and the user of the account shortly.",
                                              }));
                                            }, 2000);
                                          })
                                          .catch(() => {
                                            this.setState((prevState) => ({
                                              error:
                                                "There was an error, please try again!",
                                              loading_modal: false,
                                            }));
                                          });
                                      });
                                  }}
                                >
                                  <span
                                    style={{
                                      paddingRight: 10,
                                      paddingLeft: 10,
                                    }}
                                  >
                                    Yes
                                  </span>
                                </Button>
                              </div>
                              <div style={{ padding: 5 }}>
                                <Button
                                  type="button"
                                  color="light"
                                  className="btn-lg"
                                  onClick={() => {
                                    this.setState((prevState) => ({
                                      modal_sure_delete:
                                        !prevState.modal_sure_delete,
                                    }));
                                  }}
                                >
                                  <span
                                    style={{
                                      paddingRight: 10,
                                      paddingLeft: 10,
                                    }}
                                  >
                                    Cancel
                                  </span>
                                </Button>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            ) : (
              <div> {this.render_loading()}</div>
            )}
          </ModalBody>
        </div>
      </Modal>
    );
  }

  process_creating_account() {
    const { email, full_name } = this.state;
    var user_uid = firebase.auth().currentUser.uid;
    var admin_email = firebase.auth().currentUser.email;

    var key = firebase
      .database()
      .ref(`/partner/users/${user_uid}/request_create_limited_account/`)
      .push()
      .getKey();

    firebase
      .database()
      .ref(`/partner/users/${user_uid}/request_create_limited_account/${key}`)
      .update({
        email,
        name: full_name,
        admin_email,
        admin_details: {
          program_name: this.props.access[0].program_name,
          role: this.props.user_details.role,
          user_type: this.props.user_details.user_type,
          company_or_institution_name:
            this.props.user_details.company_or_institution_name,
          status: "loading",
        },
      })
      .then(() => {
        setTimeout(() => {
          firebase
            .database()
            .ref(
              `/partner/users/${user_uid}/request_create_limited_account/${key}`
            )
            .once("value", (snapshot) => {
              var status = snapshot.val().status;

              console.log(status);
              if (status === "user_already_created") {
                this.setState({
                  loading_modal: false,
                  error: "There is already a user with the same email address.",
                });
              } else {
                this.setState({
                  loading_modal: false,
                  success:
                    "Your request is being processed. It may take a  few minutes, a confirmation email will be sent to you and the user of the account shortly.",
                });
              }
            });
        }, 8000);
      })
      .catch((error) => {
        console.log(error);
        this.setState((prevState) => ({
          loading_modal: false,
          error: "There was an error, please try again!",
        }));
      });
  }

  async updateAPI() {
    const { email, full_name } = this.state;

    this.setState({
      loading_modal: true,
    });

    this.process_creating_account();

    /*
    
    
      //   return admin
//     //     .auth()
//     //     .getUserByEmail(object.email)
//     //     .then((userRecord) => {
//     //       console.log("Successfully fetched user data:", userRecord.toJSON());
//     //       response.send({ response: "user_found" });
//     //     })
//     //     .catch(async (error) => {
//     //       response.send({ response: "no_user_found" });
//     //     });
//     // } else {
//     //   response.send({ response: "error" });
//     // }


*/
    // var url =
    //   "https://us-central1-luup-4a75f.cloudfunctions.net/Check_Existing_Account";

    /* try {
      await fetch("/Check_Existing_Account", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      })
        .then((response) => {
          if (response.ok) return response.json();
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          if (data.response === "user_found") {
            this.setState({
              loading_modal: false,
              error: "There is already a user with the same email address.",
            });
          } else if (data.response === "no_user_found") {
            this.process_creating_account();
          } else {
            console.log(data);
            this.setState({
              loading_modal: false,
              error: "There was an error, please try  again!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading_modal: false,
            error: "There was an error, please try  again!",
          });
        });
    } catch (error) {
      console.log(error);
      this.setState({
        loading_modal: false,
        error: "There was an error, please try  again!",
      });
    }


    */
  }

  onSubmit(event) {
    event.preventDefault();

    this.updateAPI();
  }
  renderModal() {
    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              this.setState((prevState) => ({
                modal: !prevState.modal,
              }));
            }}
          >
            User Creation
          </ModalHeader>

          {this.state.loading_modal === false ? (
            <ModalBody>
              <div id="kyc-verify-wizard" className="twitter-bs-wizard">
                <div className="form-group">
                  {this.state.success !== "" || this.state.error !== "" ? (
                    <div>
                      {this.state.success === "" ? (
                        <div>
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {this.state.error}
                          </Alert>

                          <div
                            className="text-center"
                            onClick={(event) => {
                              event.preventDefault();
                              this.setState((prevState) => ({
                                modal: !prevState.modal,

                                specific_event_name: "",
                                filter_by_specific_data: "",
                                filter_by_all_data: "",
                                last_filter_for_specific: "",
                                last_filter_for_all: "",
                                modal_sure_delete: false,
                                delete_user_data: {},
                                full_name: "",
                                email: "",
                                loading_modal: false,
                                error: "",
                                success: "",
                              }));
                            }}
                          >
                            <Button color="primary">Okay</Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {this.state.success}
                          </Alert>
                          <div className="text-center">
                            <Button
                              color="primary"
                              onClick={(event) => {
                                event.preventDefault();
                                // this.props.access_reader_request();
                                this.props.access_read();

                                this.setState((prevState) => ({
                                  modal: !prevState.modal,
                                  specific_event_name: "",
                                  filter_by_specific_data: "",
                                  filter_by_all_data: "",
                                  last_filter_for_specific: "",
                                  last_filter_for_all: "",
                                  modal_sure_delete: false,
                                  delete_user_data: {},
                                  full_name: "",
                                  email: "",
                                  loading_modal: false,
                                  error: "",
                                  success: "",
                                }));
                              }}
                            >
                              Okay
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Form onSubmit={this.onSubmit.bind(this)}>
                      <div>
                        <Label htmlFor="formname">Full Name : </Label>
                        <Input
                          type="text"
                          id="formname"
                          placeholder="Enter Full Name"
                          className="form-control"
                          required
                          onChange={(event) => {
                            event.preventDefault();
                            this.setState({
                              full_name: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Label htmlFor="formname">Email Address : </Label>
                        <Input
                          id="formname"
                          placeholder="Enter Email Address"
                          className="form-control"
                          type="email"
                          required
                          onChange={(event) => {
                            event.preventDefault();

                            this.setState({
                              email: event.target.value,
                            });
                          }}
                        />
                      </div>
                      <p style={{ color: "#B00020" }}>
                        NOTE: An email with user credentials will be sent to
                        both, you and the user.
                      </p>

                      <div>
                        <Button type="submit" color="primary">
                          Create User
                        </Button>
                      </div>
                    </Form>
                  )}
                </div>
              </div>
            </ModalBody>
          ) : (
            <div> {this.render_loading()}</div>
          )}
        </div>
      </Modal>
    );
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  render_page() {
    const {
      // user_details,
      // surveys_and_attendance,
      // qr_details,
      access,
      // questionnaire,
      // questionnaire_template,
      // error,
      // loading,
    } = this.props;

    if (this.props.access[0].data_array.length === 0) {
      return (
        <div className="page-content">
          {this.renderModal()}
          {this.modal_sure_delete()}
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <Col xl="5" sm="8">
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <Row className="justify-content-center">
                        <Col lg="12">
                          <h4 className="mt-4 font-weight-semibold">Access</h4>
                          <br />
                          <p style={{ color: "#B00020" }}>
                            No Access given out yet!
                          </p>
                          <Button
                            type="button"
                            color="primary"
                            className="btn-md"
                            onClick={() => {
                              //  this.togglemodal_pressed_yes();
                              this.setState((prevState) => ({
                                modal: !prevState.modal,
                              }));
                            }}
                          >
                            Send Invite (Create Limited Access Accounts)
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    var transactions = [],
      list_of_programs = [],
      temp_array = [];

    access[0].list_of_access_items.forEach((element) => {
      var data = _.map(element, (val, uid) => {
        return val;
      });

      if (data[0].type_of_questionnaire) {
        if (data[0].type_of_questionnaire === "branch") {
          var question_name = data[0].questions[0][2];

          question_name.forEach((element) => {
            temp_array.push({
              access: data[0].survey_name,
              access_choice_funnel: element.choice,
            });
          });
        } else {
          temp_array.push({ access: data[0].survey_name });
        }
      } else {
        temp_array.push({ access: data[0].survey_name });
      }
    });

    temp_array.forEach((element, i) => {
      if (element.access_choice_funnel) {
        list_of_programs.push(
          <option value={i} key={i}>
            {element.access + " : " + element.access_choice_funnel}
          </option>
        );
      } else {
        list_of_programs.push(
          <option value={i} key={i}>
            {element.access}
          </option>
        );
      }
    });

    var data_array = this.props.access[0].data_array;
    data_array.forEach((element, i) => {
      var survey_builder_access_value = ""; //"false";
      if (element.survey_builder_access === undefined) {
        survey_builder_access_value = "false";
      } else {
        survey_builder_access_value = element.survey_builder_access;
      }

      var added_programs = _.map(element.added_programs, (val, uid) => {
        return { ...val, uid };
      });

      var added_programs_only_val = _.map(
        element.added_programs,
        (val, uid) => {
          return val.access;
        }
      );

      var list_user_program_access = [];
      // console.log(element);
      if (added_programs.length === 0) {
        list_user_program_access.push(
          <Col key={i}>
            <Badge
              className={"font-size-12 badge-soft-" + "danger"}
              color={"danger"}
              pill
            >
              NO ACCESS GIVEN YET
            </Badge>
          </Col>
        );
      } else {
        added_programs.forEach((element_2, w) => {
          var show = element_2.access;
          if (element_2.access_choice_funnel) {
            show = show + " : " + element_2.access_choice_funnel;
          }

          list_user_program_access.push(
            <Col>
              <Row>
                <div
                  className="badge badge-soft-primary font-size-13 m-1"
                  key={"_skill_" + w}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "15",
                    }}
                  >
                    <a
                      onClick={(event) => {
                        event.preventDefault();
                        //   console.log(element_2);
                        //   console.log(element_2.access);

                        firebase
                          .database()
                          .ref(
                            `${this.props.access[0].partial_url}/${data_array[i].uid}/added_programs/${element_2.uid}`
                          )
                          .remove();

                        firebase
                          .database()
                          .ref(
                            `partner/users/${element.user_id}/all_programs/${this.props.access[0].program_name}/survey_access/${element_2.access}`
                          )
                          .remove();

                        //  this.props.access_reader_request();
                        this.props.access_read();
                      }}
                    >
                      X
                    </a>
                  </span>{" "}
                  {show}
                </div>
              </Row>
            </Col>
          );
        });
      }

      transactions.push({
        number: i + 1,
        name: element.name,
        qr_access: (
          <FormGroup className="mt-3 mb-0" key={"qr_access" + i}>
            <select
              className="form-control select2-search-disable"
              value={element.access_granted}
              onChange={(event) => {
                event.preventDefault();

                var access_granted = event.target.value;

                firebase
                  .database()
                  .ref(
                    `${this.props.access[0].partial_url}/${data_array[i].uid}`
                  )
                  .update({ access_granted });

                firebase
                  .database()
                  .ref(
                    `partner/users/${data_array[i].user_id}/qr_access_granted`
                  )
                  .update({ access: access_granted });

                // this.props.access_reader_request();
                this.props.access_read();
              }}
            >
              <option value="true">Give QR Code Access</option>
              <option value="false">Revoke QR Code Access</option>
            </select>

            <div className="text-center" style={{ paddingTop: 5 }}>
              {element.access_granted === "true" ? (
                <Badge
                  className={"font-size-12 badge-soft-" + "success"}
                  color={"success"}
                  pill
                >
                  GRANTED
                </Badge>
              ) : (
                <Badge
                  className={"font-size-12 badge-soft-" + "danger"}
                  color={"danger"}
                  pill
                >
                  REVOKED
                </Badge>
              )}
            </div>
          </FormGroup>
        ),
        dashboard_access: (
          <FormGroup className="mt-3 mb-0" key={"dashboard_access" + i}>
            <select
              className="form-control select2-search-disable"
              value={element.dashboard_access}
              onChange={(event) => {
                event.preventDefault();

                var dashboard_access = event.target.value;

                firebase
                  .database()
                  .ref(
                    `${this.props.access[0].partial_url}/${data_array[i].uid}`
                  )
                  .update({ dashboard_access });

                firebase
                  .database()
                  .ref(
                    `partner/users/${data_array[i].user_id}/dashboard_access`
                  )
                  .update({ access: dashboard_access });

                // this.props.access_reader_request();
                this.props.access_read();
              }}
            >
              <option value="true">Give Dashboard Access</option>
              <option value="false">Revoke Dashboard Access</option>
            </select>

            <div className="text-center" style={{ paddingTop: 5 }}>
              {element.dashboard_access === "true" ? (
                <Badge
                  className={"font-size-12 badge-soft-" + "success"}
                  color={"success"}
                  pill
                >
                  GRANTED
                </Badge>
              ) : (
                <Badge
                  className={"font-size-12 badge-soft-" + "danger"}
                  color={"danger"}
                  pill
                >
                  REVOKED
                </Badge>
              )}
            </div>
          </FormGroup>
        ),
        survey_builder_access: (
          <FormGroup className="mt-3 mb-0" key={"survey_builder_access" + i}>
            <select
              className="form-control select2-search-disable"
              value={survey_builder_access_value}
              onChange={(event) => {
                event.preventDefault();

                var survey_builder_access = event.target.value;
                //   console.log(survey_builder_access);

                firebase
                  .database()
                  .ref(
                    `${this.props.access[0].partial_url}/${data_array[i].uid}`
                  )
                  .update({ survey_builder_access });

                firebase
                  .database()
                  .ref(
                    `partner/users/${data_array[i].user_id}/survey_builder_access`
                  )
                  .update({ access: survey_builder_access });

                // this.props.access_reader_request();
                this.props.access_read();
              }}
            >
              <option value="true">Give Survey Builder Access</option>
              <option value="false">Revoke Survey Builder Access</option>
            </select>

            <div className="text-center" style={{ paddingTop: 5 }}>
              {survey_builder_access_value === "true" ? (
                <Badge
                  className={"font-size-12 badge-soft-" + "success"}
                  color={"success"}
                  pill
                >
                  GRANTED
                </Badge>
              ) : (
                <Badge
                  className={"font-size-12 badge-soft-" + "danger"}
                  color={"danger"}
                  pill
                >
                  REVOKED
                </Badge>
              )}
            </div>
          </FormGroup>
        ),
        survey_name: (
          <div key={"survey_name" + i}>
            <FormGroup className="mt-3 mb-0">
              <select
                className="form-control select2-search-disable"
                //</FormGroup>  value={this.state.filter_by_all_data}
                onChange={(event) => {
                  event.preventDefault();
                  if (event.target.value !== "") {
                    var specific_element = temp_array[event.target.value];

                    var access_to = specific_element.access;
                    var access_choice_funnel = "";

                    if (specific_element.access_choice_funnel) {
                      access_choice_funnel =
                        specific_element.access_choice_funnel;
                    }

                    var matched = added_programs_only_val.includes(
                      specific_element.access
                    );
                    if (matched === true) {
                      alert("Survey is already been added!");
                    } else {
                      var new_program = access_to;
                      var add_data_to_user_2 = {
                        // access: this.props.access[0].program_name,
                        //    survey_access: {
                        // [new_program]: { access: true },
                        // },
                        access: true,
                      };
                      var data_1st = { access: specific_element.access };

                      if (specific_element.access_choice_funnel) {
                        add_data_to_user_2 = {
                          // access: this.props.access[0].program_name,
                          //  survey_access: {
                          //    [new_program]: {
                          access: true,
                          access_choice_funnel,
                          //       },
                          //   },
                        };

                        data_1st = {
                          access: specific_element.access,
                          access_choice_funnel,
                        };
                      }

                      firebase
                        .database()
                        .ref(
                          `${this.props.access[0].partial_url}/${data_array[i].uid}/added_programs`
                        )
                        .push(data_1st);

                      firebase
                        .database()
                        .ref(
                          `partner/users/${element.user_id}/all_programs/${this.props.access[0].program_name}/`
                        )
                        .update({
                          access: this.props.access[0].program_name,
                        });

                      firebase
                        .database()
                        .ref(
                          `partner/users/${element.user_id}/all_programs/${this.props.access[0].program_name}/survey_access/${new_program}`
                        )
                        .update(add_data_to_user_2);

                      // this.props.access_reader_request();
                      this.props.access_read();
                    }
                  }
                }}
              >
                <option value="">Select name to give access...</option>
                {list_of_programs}
              </select>
            </FormGroup>
          </div>
        ),

        surveys_added: (
          <div style={{ paddingTop: 5 }} key={"surveys_added" + i}>
            {list_user_program_access}
          </div>
        ),

        delete_user: (
          <Button
            key={"delete_user" + i}
            onClick={(event) => {
              event.preventDefault();
              console.log(this.props.user_details);
              this.setState((prevState) => ({
                delete_user_data: {
                  name: element.name,
                  pushID: element.uid,
                  user_id: element.user_id,
                  admin_details: {
                    program_name: this.props.access[0].program_name,
                    // role: this.props.access[0].details.getUserDeatils.role,
                    role: this.props.user_details.role,
                    // user_type: this.props.access[0].details.getUserDeatils
                    //   .user_type,
                    user_type: this.props.user_details.user_type,
                    // company_or_institution_name: this.props.access[0].details
                    //   .getUserDeatils.company_or_institution_name,
                    company_or_institution_name:
                      this.props.user_details.company_or_institution_name,
                  },
                },
                modal_sure_delete: !prevState.modal_sure_delete,
              }));
            }}
            color="primary"
          >
            Delete User
          </Button>
        ),
      });
    });

    return (
      <React.Fragment>
        {this.renderModal()}
        {this.modal_sure_delete()}
        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              <div id="textbox">
                <p className="alignleft">Access</p>
                <div className="alignright" style={{ paddingBottom: 10 }}>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-md"
                    onClick={() => {
                      //  this.togglemodal_pressed_yes();
                      this.setState((prevState) => ({
                        modal: !prevState.modal,
                      }));
                    }}
                  >
                    Send Invite (Create Limited Access Accounts)
                  </Button>
                </div>
              </div>
            </CardTitle>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>QR Code Access</th>
                    <th>Dashboard Access</th>
                    <th>Survey Builder Access</th>
                    <th>Survey Names</th>
                    <th>Surveys Added</th>
                    <th>Delete User</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, key) => (
                    <tr key={"_tr_" + key}>
                      <td>{transaction.number}</td>

                      <td>{transaction.name}</td>
                      <td>{transaction.qr_access}</td>
                      <td>{transaction.dashboard_access}</td>
                      <td>{transaction.survey_builder_access}</td>
                      <td>{transaction.survey_name}</td>
                      <td>{transaction.surveys_added}</td>
                      <td>{transaction.delete_user}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
  render() {
    const {
      user_details,
      surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;

    if (loading === true) {
      return (
        <React.Fragment>
          <div className="page-content">{this.render_loading()}</div>
        </React.Fragment>
      );
    } else {
      if (access === null) {
        return (
          <React.Fragment>
            <div className="page-content">{this.render_loading()}</div>
          </React.Fragment>
        );
      } else {
        if (user_details.role === "Facilitator") {
          return <Redirect to={{ pathname: "/" }} />;
        } else if (user_details.plan_selected === "Starter") {
          return <Redirect to={{ pathname: "/" }} />;
        }else if (user_details.role === "Individual Users") {
          return <Redirect to={{ pathname: "/" }} />;
        }

        return (
          <React.Fragment>
            <div>{this.render_page()}</div>
          </React.Fragment>
        );
      }
    }
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, { access_read })(withNamespaces()(Access))
);
