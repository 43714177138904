import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  // CardSubtitle
} from "reactstrap";
import _ from "lodash";
import { Form, FormGroup, Button } from "reactstrap";

import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { showcase_data } = this.props;

    var open_text_item_render = showcase_data.open_text_item_render;

    if (open_text_item_render) {
      var show_open_text = [];

      open_text_item_render.forEach((element, i) => {
        show_open_text.push(
          <Row key={i}>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>{element.question}</CardTitle>

                  <ToolkitProvider
                    keyField="id"
                    data={element.data}
                    columns={element.columns}
                    exportCSV
                    search
                  >
                    {(props) => (
                      <div>
                        <Form>
                          <Row>
                            <div className="col-xl-2 col-sm-6">
                              <FormGroup className="mt-3 mb-0">
                                <SearchBar {...props.searchProps} />
                              </FormGroup>
                            </div>

                            <div className="col-xl-4 col-sm-6">
                              <FormGroup className="mt-3 mb-0">
                                <Button
                                  color="primary"
                                  style={{ padding: 0 }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                  }}
                                >
                                  <ExportCSVButton {...props.csvProps}>
                                    <span
                                      style={{
                                        color: "white",
                                        padding: 0,
                                      }}
                                    >
                                      Export CSV
                                    </span>
                                  </ExportCSVButton>
                                </Button>
                              </FormGroup>
                            </div>
                          </Row>
                        </Form>

                        <hr />
                        <BootstrapTable
                          {...props.baseProps}
                          pagination={paginationFactory({
                            paginationSize: 4,
                            pageStartIndex: 0,
                            firstPageText: "First",
                            prePageText: "Back",
                            nextPageText: "Next",
                            lastPageText: "Last",
                            nextPageTitle: "First page",
                            prePageTitle: "Pre page",
                            firstPageTitle: "Next page",
                            lastPageTitle: "Last page",
                            showTotal: true,
                            sizePerPageList: [
                              {
                                text: "10",
                                value: 10,
                              },
                              {
                                text: "25",
                                value: 25,
                              },
                              {
                                text: "All",
                                value: element.data.length,
                              },
                            ],
                          })}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        );
      });
      return (
        <React.Fragment>
          {/* ADD TABLE HERE */}
          {show_open_text}
        </React.Fragment>
      );
    }
    return null;
  }
}

export default DatatableTables;
