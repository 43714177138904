import {
  /* USER DETAILS */
  USER_DATA_READ_ASYNC,
  USER_DATA_WRITE_ASYNC,
  USER_DATA_READ,
  USER_DATA_WRITE,

  /* SURVEY DATA AND ATTENDANCE */
  QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC,
  QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC,
  QR_SURVEY_DATA_AND_ATTENDANCE_READ,
  QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,

  /* ARCHIVE SURVEY DATA AND ATTENDANCE */
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC,
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC,
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ,
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,

  /* QR CODE */
  QR_CODE_READ_ASYNC,
  QR_CODE_WRITE_ASYNC,
  QR_CODE_READ,
  QR_CODE_WRITE,

  /* BUILDER AND MANAGEMENT */
  QUESTIONNAIRE_READ_ASYNC,
  QUESTIONNAIRE_WRITE_ASYNC,
  QUESTIONNAIRE_TEMPLATE_READ_ASYNC,
  QUESTIONNAIRE_TEMPLATE_WRITE_ASYNC,
  QUESTIONNAIRE_READ,
  QUESTIONNAIRE_WRITE,
  QUESTIONNAIRE_TEMPLATE_READ,
  QUESTIONNAIRE_TEMPLATE_WRITE,

  /* ACCESS */
  ACCESS_READ_ASYNC,
  ACCESS_WRITE_ASYNC,
  ACCESS_READ,
  ACCESS_WRITE,

  /*  */
  DETAIL_ERROR,
} from "./actionTypes";

const initialState = {
  user_details: null,
  surveys_and_attendance: {},
  archive_surveys_and_attendance: {},

  qr_details: null,
  access: null,
  questionnaire: null,
  questionnaire_template: null,
  error: "",
  loading: true,
};

const database = (state = initialState, action) => {
  switch (action.type) {
    /* USER DETAILS */
    case USER_DATA_READ_ASYNC:
      state = { ...state, user_details: action.payload, loading: false };
      break;

    case USER_DATA_WRITE_ASYNC:
      state = { ...state, loading: false };
      break;

    case USER_DATA_READ:
      state = { ...state, loading: true };
      break;

    case USER_DATA_WRITE:
      state = { ...state, loading: true };
      break;

    /* SURVEY DATA AND ATTENDANCE */
    case QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC:
      state = {
        ...state,
        surveys_and_attendance: action.payload,
        loading: false,
      };
      break;

    case QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC:
      state = { ...state, loading: false };
      break;

    case QR_SURVEY_DATA_AND_ATTENDANCE_READ:
      state = { ...state, loading: true };
      break;

    case QR_SURVEY_DATA_AND_ATTENDANCE_WRITE:
      state = { ...state, loading: true };
      break;

    /* ARCHIVE SURVEY DATA AND ATTENDANCE */
    case ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC:
      state = {
        ...state,
        archive_surveys_and_attendance: action.payload,
        loading: false,
      };
      break;

    case ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC:
      state = { ...state, loading: false };
      break;

    case ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ:
      state = { ...state, loading: true };
      break;

    case ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE:
      state = { ...state, loading: true };
      break;

    /* QR CODE */
    case QR_CODE_READ_ASYNC:
      state = { ...state, qr_details: action.payload, loading: false };
      break;

    case QR_CODE_WRITE_ASYNC:
      state = { ...state, loading: false };
      break;

    case QR_CODE_READ:
      state = { ...state, loading: true };
      break;

    case QR_CODE_WRITE:
      state = { ...state, loading: true };
      break;

    /* BUILDER AND MANAGEMENT */
    case QUESTIONNAIRE_READ_ASYNC:
      state = { ...state, questionnaire: action.payload, loading: false };
      break;

    case QUESTIONNAIRE_WRITE_ASYNC:
      state = { ...state, loading: false };
      break;

    case QUESTIONNAIRE_TEMPLATE_READ_ASYNC:
      state = {
        ...state,
        questionnaire_template: action.payload,
        loading: false,
      };
      break;

    case QUESTIONNAIRE_TEMPLATE_WRITE_ASYNC:
      state = { ...state, loading: false };
      break;

    case QUESTIONNAIRE_READ:
      state = { ...state, loading: true };
      break;

    case QUESTIONNAIRE_WRITE:
      state = { ...state, loading: true };
      break;

    case QUESTIONNAIRE_TEMPLATE_READ:
      state = { ...state, loading: true };
      break;

    case QUESTIONNAIRE_TEMPLATE_WRITE:
      state = { ...state, loading: true };
      break;

    /* ACCESS */

    case ACCESS_READ_ASYNC:
      state = { ...state, access: action.payload, loading: false };
      break;

    case ACCESS_WRITE_ASYNC:
      state = { ...state, loading: false };
      break;

    case ACCESS_READ:
      state = { ...state, loading: true };
      break;

    case ACCESS_WRITE:
      state = { ...state, loading: true };
      break;

    /*  */
    case DETAIL_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    /* DEFAULT */
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default database;
