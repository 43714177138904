import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Dashboard from "./Dashboard";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import {
  archive_qr_survey_data_and_attendance_read,
  archive_qr_survey_data_and_attendance_write,
} from "../../store/actions"; //"../../store/actions";
import Lottie from "react-lottie";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /* SURVEY DATA AND ATTENDANCE */
    this.props.archive_qr_survey_data_and_attendance_read();
    this.props.archive_qr_survey_data_and_attendance_write();
  }
  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
  render() {
    const {
      user_details,
      surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;

    if (loading === true) {
      return (
        <React.Fragment>
          <div className="page-content">{this.render_loading()}</div>
        </React.Fragment>
      );
    } else {
      if (access === null) {
        return (
          <React.Fragment>
            <div className="page-content">{this.render_loading()}</div>
          </React.Fragment>
        );
      } else {
        if (user_details.role === "Facilitator") {
          return <Redirect to={{ pathname: "/" }} />;
        } else if (user_details.plan_selected === "Starter") {
          return <Redirect to={{ pathname: "/" }} />;
        }

        return (
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title="Archived Dashboard"
                  breadcrumbItem="Archived Dashboard"
                />
                <Row>
                  <Col lg="12">
                    <Dashboard />
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        );
      }
    }

    /* return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Archived Dashboard"
              breadcrumbItem="Archived Dashboard"
            />
            <Row>
              <Col lg="12">
                <Dashboard />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
    */
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    archive_surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    archive_surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(
    mapStatetoProps,

    {
      archive_qr_survey_data_and_attendance_read,
      archive_qr_survey_data_and_attendance_write,
    }
  )(withNamespaces()(Index))
);
