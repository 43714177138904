import _ from "lodash";

import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import Lottie from "react-lottie";
import moment_time_zone from "moment-timezone";
import moment from "moment";
import { QRCode } from "react-qr-svg";

import firebase from "firebase/app";
import "firebase/database";
import "./CSS/surveyQR.css";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { qr_code_read } from "../../store/actions";

class SurveyQR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: 1,
      selectedFiles: [],

      data: {},
      qr_screen: false,
      draw_number: "",
      draw: false,
      countdown: false,
      user_data: {},
      selected_program: "",
      event_selected: "",
      drawButtonPressed: false,
      modal_request: "",
      questionaire_details: {},
      qr_creating_modal: false,
    };
    this.togglemodal.bind(this);
  }

  componentDidMount() {
    this.props.qr_code_read();
  }

  coundown_and_draw_render() {
    const countdownjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/countdown.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    if (this.state.countdown === true) {
      return (
        <div className="text-center">
          <Lottie
            options={countdownjson}
            //height={300}
            //  width={300}
            style={{
              backgroundColor: "#301b92",
            }}
          />
        </div>
      );
    }

    return (
      <div className="text-center">
        <h1 style={{ color: "black", fontSize: 150, fontWeight: "bold" }}>
          {this.state.draw_number}
        </h1>
      </div>
    );
  }

  /* BOTTOM FOR THE THEME */
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  processing_new_session(data) {
    var date_local = moment_time_zone()
      .tz("America/Toronto")
      .format("MM/DD/YYYY");

    var survey_expiry = moment(
      moment_time_zone().tz("America/Toronto").format()
    )
      .add(1, "days")
      .format("LLLL");

    firebase
      .database()
      .ref(data.links.partial_unique_link)

      .once("value", (snap) => {
        const id = _.map(snap.val(), (val, uid) => {
          return uid;
        });
        const value = _.map(snap.val(), (val, uid) => {
          return val;
        });

        if (value[0] !== undefined) {
          if (value[0].current !== undefined) {
            firebase
              .database()
              //  .ref(`${data.links.partial_unique_link}/${id[0]}/old`)
              .ref(`${data.links.unique_link}/old`)
              .update(value[0].current)
              .then(() => {
                firebase
                  .database()
                  //.ref(`${data.links.partial_unique_link}/${id[0]}/current`)
                  .ref(`${data.links.unique_link}/current`)
                  .remove();
              })
              .catch(() => {
                alert("Something Went Wrong! Please try again.");
              });

            firebase
              .database()
              .ref(data.links.partial_questionaire_link)
              .update({ survey_expiry });
          } else {
            firebase
              .database()
              .ref(data.links.partial_questionaire_link)
              .update({ survey_expiry });
          }
        } else {
          firebase
            .database()
            .ref(data.links.partial_questionaire_link)
            .update({ survey_expiry });
        }
      });
  }
  processing_draw_now(data) {
    firebase
      .database()
      .ref(`Admin/random_number_generating_range`)
      .once("value", (random_number_generating_range) => {
        var in_person_draw =
          random_number_generating_range.val().in_person_draw;
        firebase
          .database()
          .ref(`${data.links.unique_link}/current`)
          .once("value", (snapshot) => {
            var unique = _.map(snapshot.val(), (val, uid) => {
              return { ...val };
            });

            if (unique.length === 0) {
              alert("No participants yet!");
            } else {
              this.setState({ countdown: true });

              var randomenumber;

              var checking;
              var found;

              do {
                //  randomenumber = Math.floor(Math.random() * Math.floor(200));
                randomenumber = Math.floor(
                  Math.random() * Math.floor(in_person_draw)
                );
                checking = randomenumber;
                found = unique.find((element) => {
                  return checking === element.randomNumber;
                });
              } while (found === undefined);

              this.setState({
                draw_number: randomenumber,
                draw: true,
              });

              setTimeout(() => {
                this.setState({
                  countdown: false,
                });
              }, 3000); //10000);

              if (found === undefined) {
                // console.log("good to go");
              } else {
                // console.log("generate a new number");
              }
            }
          });
      });
  }
  togglemodal_pressed_yes() {
    const { modal_request } = this.state;

    //  console.log(modal_request);
    if (modal_request.for_ === "New_Session") {
      this.processing_new_session(modal_request);
    } else if (modal_request.for_ === "Draw_Now") {
      this.processing_draw_now(modal_request);
    } else {
      // console.log("");
    }

    this.setState({
      qr_screen: true,
    });

    //this.props.qr_details[0].QR_Codes[this.state.event_selected];
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  togglemodal_pressed_no() {
    //this.props.qr_details[0].QR_Codes[this.state.event_selected];

    this.setState({
      qr_screen: true,
    });
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render_qr_creating_modal() {
    return (
      <Modal
        isOpen={this.state.qr_creating_modal}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={() => {
          this.setState((prevState) => ({
            qr_creating_modal: !prevState.qr_creating_modal,
          }));
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            QR Code Processing
          </h5>
          <button
            type="button"
            onClick={() => this.setState({ qr_creating_modal: false })}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Your QR is on its way!</h5>
          <p>
            Refresh this page in a minute, and your shiny new QR should be ready
            to go!
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.setState((prevState) => ({
                qr_creating_modal: !prevState.qr_creating_modal,
              }));
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => {
              this.setState((prevState) => ({
                qr_creating_modal: !prevState.qr_creating_modal,
              }));
            }}
          >
            Okay
          </button>
        </div>
      </Modal>
    );
  }

  renderModal() {
    return (
      <Modal
        isOpen={this.state.modal}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalBody>
            <div className="text-center">
              <Row className="justify-content-center">
                <Col
                //xl="5" sm="8"
                >
                  {this.state.drawButtonPressed === false ? (
                    <div>
                      <h1 className="mt-4 font-weight-semibold">
                        Is this a new session?
                      </h1>

                      <div className="mt-4">
                        <Row className="justify-content-center">
                          <div style={{ padding: 5 }}>
                            <Button
                              type="button"
                              color="primary"
                              className="btn-lg"
                              onClick={() => {
                                this.togglemodal_pressed_yes();
                              }}
                            >
                              <span
                                style={{ paddingRight: 10, paddingLeft: 10 }}
                              >
                                YES
                              </span>
                            </Button>
                          </div>
                          <div style={{ padding: 5 }}>
                            <Button
                              type="button"
                              color="light"
                              className="btn-lg"
                              onClick={() => {
                                this.togglemodal_pressed_no();
                              }}
                            >
                              <span
                                style={{ paddingRight: 10, paddingLeft: 10 }}
                              >
                                NO
                              </span>
                            </Button>
                          </div>
                        </Row>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h1 className="mt-4 font-weight-semibold">
                        Are you sure?
                      </h1>
                      <div className="mt-4">
                        <Row className="justify-content-center">
                          <div style={{ padding: 5 }}>
                            <Button
                              type="button"
                              color="primary"
                              className="btn-lg"
                              onClick={() => {
                                this.setState({
                                  modal_request: {
                                    ...this.state.modal_request,
                                    for_: "Draw_Now",
                                  },
                                });

                                setTimeout(() => {
                                  this.togglemodal_pressed_yes();
                                }, 250);
                              }}
                            >
                              <span
                                style={{ paddingRight: 10, paddingLeft: 10 }}
                              >
                                DRAW NOW!
                              </span>
                            </Button>
                          </div>
                          <div style={{ padding: 5 }}>
                            <Button
                              type="button"
                              color="light"
                              className="btn-lg"
                              onClick={() => {
                                this.togglemodal_pressed_no();
                              }}
                            >
                              <span
                                style={{ paddingRight: 10, paddingLeft: 10 }}
                              >
                                Cancel
                              </span>
                            </Button>
                          </div>
                        </Row>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  /* TOP FROM THE THEME */

  renderProgram({ list_of_programs }) {
    return (
      <div className="form-group">
        <label style={{ paddingBottom: 2 }}>Select the Program:</label>
        <select
          className="form-control"
          value={this.state.selected_program}
          onChange={(event) => {
            event.preventDefault();

            /*
             this.setState({
              selected_program: event.target.value,
            });
            var user = firebase.auth().currentUser;

            firebase
              .database()
              .ref(`partner/users/${user.uid}/`)
              .on("value", (snapshot) => {
                var database_val = snapshot.val();
                if (database_val !== null) {
                  //  console.log(database_val);

                  firebase
                    .database()
                    .ref(
                      `QR_Code/questionnaire/${database_val.company_or_institution_name}/${event.target.value}`
                    )
                    .on("value", (snap) => {
                      var data_initial_pull = snap.val();
                      if (
                        data_initial_pull !== undefined &&
                        data_initial_pull !== null
                      ) {
                        this.setState({
                          data_initial_pull,
                        });
                      } else {
                        this.setState({
                          data_initial_pull,
                        });
                      }
                    });
                } else {
                  alert(
                    "Something went wrong! Please contact us at support@getluup.com"
                  );
                }
              });
              */
          }}
        >
          <option selected value="">
            Select....
          </option>

          {list_of_programs}
        </select>
      </div>
    );
  }

  renderEventName(survey_list) {
    return (
      <div className="form-group">
        <label style={{ paddingBottom: 2 }}>Select Survey:</label>

        {/*this.props.user_details.user_type !== "retail" ? (
          <label style={{ paddingBottom: 2 }}>Select Event/Workshop:</label>
        ) : (
          <label style={{ paddingBottom: 2 }}>Select Survey:</label>
        )*/}
        <select
          className="form-control"
          value={this.state.view_selection}
          onChange={(event) => {
            event.preventDefault();
            if (event.target.value !== "") {
              if (
                this.props.qr_details[0].QR_Codes[event.target.value].data[0]
                  .Dynamic_Link_Details === undefined
              ) {
                // console.log("WE HERE");
                this.setState((prevState) => ({
                  qr_creating_modal: !prevState.qr_creating_modal,
                  view_selection: "",
                }));
              } else {
                var surveyName =
                  this.props.qr_details[0].QR_Codes[event.target.value].uid;
                var surveyUID =
                  this.props.qr_details[0].QR_Codes[event.target.value].data[0]
                    .uid_;
                var partial_questionaire_link = `${this.props.qr_details[0].partial_questionaire_link}/${surveyName}/${surveyUID}`;
                var partial_unique_link = `${this.props.qr_details[0].partial_unique_link}/${surveyName}`;
                var unique_link = `${this.props.qr_details[0].partial_unique_link}/${surveyName}/${surveyUID}`;
                var questionaire_details =
                  this.props.qr_details[0].QR_Codes[event.target.value].data[0];

                this.setState({
                  event_selected: event.target.value,
                  drawButtonPressed: false,
                  questionaire_details,
                  modal_request: {
                    for_: "New_Session",
                    req: this.props.qr_details[0].QR_Codes[event.target.value],
                    links: {
                      partial_questionaire_link,
                      partial_unique_link,
                      unique_link,
                    },
                  },
                });

                if (questionaire_details.custom_expiry) {
                  //dont show modal
                  if (questionaire_details.custom_expiry === "Never Expiry") {
                    this.setState({
                      qr_screen: true,
                    });
                  }
                } else {
                  this.togglemodal();
                }
              }
            }
          }}
        >
          <option selected value="">
            Select....
          </option>
          {survey_list}
        </select>
      </div>
    );
  }

  render_main_screen() {
    var qr_details = [];

    if (this.props.qr_details) {
      qr_details = this.props.qr_details.length;
    }

    if (this.state.qr_screen === true && qr_details.length !== 0) {
      /* console.log(
        this.props.qr_details[0].QR_Codes[this.state.event_selected] //[event.target.value]
      );*/

      var survey_name =
        this.props.qr_details[0].QR_Codes[this.state.event_selected].uid;
      var QR_Code_Link =
        this.props.qr_details[0].QR_Codes[this.state.event_selected].data[0]
          .Dynamic_Link_Details.QR_Code_Link;
      var newlink = QR_Code_Link.split("https://");

      return (
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  {/*  <h4 className="mb-0 font-size-18">QR Code</h4>*/}

                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        modal: false,
                        activeTab: 1,
                        selectedFiles: [],
                        data: {},
                        qr_screen: false,
                        draw_number: "",
                        draw: false,
                        countdown: false,
                        user_data: {},
                        selected_program: "",
                        event_selected: "",
                        drawButtonPressed: false,
                        modal_request: "",
                        questionaire_details: {},
                      });
                    }}
                  >
                    GO BACK
                  </Button>

                  <div className="page-title-right">
                    <Breadcrumb listClassName="m-0">
                      <BreadcrumbItem>QR Code</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </div>
              </Col>
            </Row>

            {this.renderModal()}
            <Row className="justify-content-center mt-lg-5">
              <Col xl="5" sm="8">
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <Row className="justify-content-center">
                        <Col lg="12">
                          <h1 className="mt-4 font-weight-semibold">
                            {survey_name}
                          </h1>

                          <div
                            style={{
                              background: "white",
                              paddingTop: 20,
                              paddingBottom: 20,
                              paddingRight: 20,
                              paddingLeft: 20,
                            }}
                          >
                            <div
                              style={{
                                background: "#301b92",
                                paddingTop: 20,
                                paddingBottom: 20,
                                paddingRight: 20,
                                paddingLeft: 20,
                              }}
                            >
                              <div
                                style={{
                                  background: "white",
                                  paddingTop: 20,
                                  paddingBottom: 20,
                                  paddingRight: 20,
                                  paddingLeft: 20,
                                }}
                              >
                                {this.state.draw !== true ? (
                                  <QRCode
                                    bgColor="#FFFFFF"
                                    fgColor="#000000"
                                    level="Q"
                                    value={QR_Code_Link}
                                    //    style={{ width: 256 }}
                                    //  className="size_qr"
                                    //  cellClassPrefix="my-qr-code"
                                  />
                                ) : (
                                  <div>{this.coundown_and_draw_render()}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <h1 className="mt-4 font-weight-semibold">
                            {newlink[1]}
                          </h1>
                        </Col>
                      </Row>

                      {this.state.questionaire_details.draw !== false ||
                      this.state.questionaire_details.in_person_draw !==
                        false ? (
                        <div style={{ paddingTop: 15 }}>
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => {
                              this.setState({ drawButtonPressed: true });

                              this.togglemodal();
                            }}
                          >
                            Draw Now!
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      if (qr_details.length > 1) {
        var list_of_programs = [];
        this.props.qr_details.forEach((element, i) => {
          list_of_programs.push(
            <option value={i}>{element.all_programs}</option>
          );
        });

        return (
          <div className="page-content">
            <Container fluid>
              <Row className="justify-content-center mt-lg-5">
                <Col xl="5" sm="8">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col lg="10">
                            <h4 className="mt-4 font-weight-semibold">
                              Survey QR CODE
                            </h4>
                            <br />

                            {this.state.selected_program === "" ? (
                              <div>
                                {this.renderProgram({ list_of_programs })}
                              </div>
                            ) : (
                              <div>
                                {this.renderProgram({ list_of_programs })}
                                {this.renderEventName()}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                      {this.renderModal()}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      } else if (qr_details.length === 0) {
        return null;
      }

      var list_of_programs = [];
      const { user_details } = this.props;
      if (user_details.role === "Facilitator") {
        var limited_access = _.map(user_details.all_programs, (val, uid) => {
          return val.survey_access;
        });
        limited_access = _.map(limited_access[0], (val, uid) => {
          return uid;
        });

        limited_access.forEach((element_access) => {
          this.props.qr_details[0].QR_Codes.forEach((element, i) => {
            var use_name = element_access;
            var element_name = element.uid;

            if (use_name === element_name) {
              list_of_programs.push(
                <option value={i} key={i}>
                  {element_name}
                </option>
              );
            }
          });
        });
      } else {
        this.props.qr_details[0].QR_Codes.forEach((element, i) => {
          list_of_programs.push(<option value={i}>{element.uid}</option>);
        });
      }

      var noSurveys = false;
      if (this.props.qr_details) {
        if (this.props.qr_details[0].QR_Codes.length === 0) {
          noSurveys = true;
        }
      }
      if (noSurveys === true) {
        return (
          <div className="page-content">
            <Container fluid>
              <Row className="justify-content-center mt-lg-5">
                <Col xl="5" sm="8">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col lg="12">
                            <h4 className="mt-4 font-weight-semibold">
                              Survey QR CODE
                            </h4>
                            <br />
                            <p style={{ color: "#B00020" }}>
                              No Survey Created Yet!
                            </p>
                            <Link
                              className="btn btn-primary btn-block"
                              to="/surveyBuilder"
                            >
                              Create Survey Now!
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      } else {
        return (
          <div className="page-content">
            <Container fluid>
              <Row className="justify-content-center mt-lg-5">
                <Col xl="5" sm="8">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col lg="10">
                            <h4 className="mt-4 font-weight-semibold">
                              Survey QR CODE
                            </h4>
                            <br />

                            {this.renderEventName(list_of_programs)}
                          </Col>
                        </Row>
                      </div>
                      {this.renderModal()}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
    }
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  render_page() {
    return (
      <React.Fragment>
        {this.render_qr_creating_modal()}
        {this.render_main_screen()}
      </React.Fragment>
    );
  }
  render() {
    const {
      user_details,
      surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;
    // console.log({
    //   user_details,
    //   surveys_and_attendance,
    //   qr_details,
    //   access,
    //   questionnaire,
    //   questionnaire_template,
    //   error,
    //   loading,
    // });
    if (loading === true) {
      return (
        <React.Fragment>
          <div className="page-content">{this.render_loading()}</div>
        </React.Fragment>
      );
    } else {
      if (questionnaire === null || questionnaire_template === null) {
        return (
          <React.Fragment>
            <div className="page-content">{this.render_loading()}</div>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <div>{this.render_page()}</div>
        </React.Fragment>
      );
    }
  }
}
const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, { qr_code_read })(withNamespaces()(SurveyQR))
);

/*


  async Survey_Submitted() {
    var Survey_Submitted = {};

    this.state.all_data_extraction.forEach(async (element, i) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.Survey_Submitted) {
        info.data = all_data.Survey_Submitted;
        try {
          await firebase
            .database()
            .ref(`app_users/Survey_Submitted/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(Survey_Submitted).length > 0) {
    //   console.log(Survey_Submitted);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/Survey_Submitted/`)
    //       .update(Survey_Submitted);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE Survey_Submitted");
  }

  async coupon_code() {
    var coupon_code = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.coupon_code) {
        // coupon_code[user_id] = info;
        info.data = all_data.coupon_code;
        try {
          await firebase
            .database()
            .ref(`app_users/coupon_code/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(coupon_code).length > 0) {
    //   console.log(coupon_code);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/coupon_code/`)
    //       .update(coupon_code);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    console.log("DONE coupon_code");
  }

  async email() {
    var email = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.email) {
        // email[user_id] = info;
        info.data = all_data.email;
        try {
          await firebase
            .database()
            .ref(`app_users/email/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(email).length > 0) {
    //   console.log(email);
    //   try {
    //     await firebase.database().ref(`app_users/email/`).update(email);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    console.log("DONE email");
  }

  async expired_survey() {
    var expired_survey = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.expired_survey) {
        // expired_survey[user_id] = info;
        info.data = all_data.expired_survey;

        try {
          await firebase
            .database()
            .ref(`app_users/expired_survey/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(expired_survey).length > 0) {
    //   console.log(expired_survey);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/expired_survey/`)
    //       .update(expired_survey);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE expired_survey");
  }

  async plaid_link_card() {
    var plaid_link_card = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.plaid_link_card) {
        // plaid_link_card[user_id] = info;
        info.data = all_data.plaid_link_card;

        try {
          await firebase
            .database()
            .ref(`app_users/plaid_link_card/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(plaid_link_card).length > 0) {
    //   console.log(plaid_link_card);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/plaid_link_card/`)
    //       .update(plaid_link_card);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    console.log("DONE plaid_link_card");
  }

  async plaid_link_card_request_status() {
    var plaid_link_card_request_status = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.plaid_link_card_request_status) {
        // plaid_link_card_request_status[user_id] = info;
        info.data = all_data.plaid_link_card_request_status;

        try {
          await firebase
            .database()
            .ref(`app_users/plaid_link_card_request_status/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(plaid_link_card_request_status).length > 0) {
    //   console.log(plaid_link_card_request_status);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/plaid_link_card_request_status/`)
    //       .update(plaid_link_card_request_status);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE plaid_link_card_request_status");
  }

  async plaid_transactions() {
    var plaid_transactions = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.plaid_transactions) {
        // plaid_transactions[user_id] = info;
        info.data = all_data.plaid_transactions;

        try {
          await firebase
            .database()
            .ref(`app_users/plaid_transactions/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(plaid_transactions).length > 0) {
    //   console.log(plaid_transactions);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/plaid_transactions/`)
    //       .update(plaid_transactions);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE plaid_transactions");
  }

  async plaid_unlink_card_request() {
    var plaid_unlink_card_request = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.plaid_unlink_card_request) {
        // plaid_unlink_card_request[user_id] = info;
        // plaid_unlink_card_request[user_id]
        info.data = all_data.plaid_unlink_card_request;
        try {
          await firebase
            .database()
            .ref(`app_users/plaid_unlink_card_request/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(plaid_unlink_card_request).length > 0) {
    //   console.log(plaid_unlink_card_request);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/plaid_unlink_card_request/`)
    //       .update(plaid_unlink_card_request);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    console.log("DONE plaid_unlink_card_request");
  }

  async pushNotificationToken() {
    var pushNotificationToken = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.pushNotificationToken) {
        // pushNotificationToken[user_id] = info;
        // pushNotificationToken[user_id]
        info.data = all_data.pushNotificationToken;

        try {
          await firebase
            .database()
            .ref(`app_users/pushNotificationToken/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(pushNotificationToken).length > 0) {
    //   console.log(pushNotificationToken);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/pushNotificationToken/`)
    //       .update(pushNotificationToken);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE pushNotificationToken");
  }

  async ratings() {
    var ratings = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.ratings) {
        // ratings[user_id] = info;
        info.data = all_data.ratings;

        try {
          await firebase
            .database()
            .ref(`app_users/ratings/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(ratings).length > 0) {
    //   console.log(ratings);
    //   try {
    //     await firebase.database().ref(`app_users/ratings/`).update(ratings);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    console.log("DONE ratings");
  }

  async redeeming() {
    var redeeming = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.redeeming) {
        // redeeming[user_id] = info;
        // redeeming[user_id]
        info.data = all_data.redeeming;
        try {
          await firebase
            .database()
            .ref(`app_users/redeeming/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(redeeming).length > 0) {
    //   console.log(redeeming);
    //   try {
    //     await firebase.database().ref(`app_users/redeeming/`).update(redeeming);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE redeeming");
  }

  async referral_data() {
    var referral_data = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.referral_data) {
        // referral_data[user_id] = info;
        // referral_data[user_id].data
        info.data = all_data.referral_data;

        try {
          await firebase
            .database()
            .ref(`app_users/referral_data/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(referral_data).length > 0) {
    //   console.log(referral_data);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/referral_data/`)
    //       .update(referral_data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    console.log("DONE referral_data");
  }

  async surveys() {
    var surveys = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.surveys) {
        // surveys[user_id] = info;
        info.data = all_data.surveys;

        try {
          await firebase
            .database()
            .ref(`app_users/surveys/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(surveys).length > 0) {
    //   console.log(surveys);
    //   try {
    //     await firebase.database().ref(`app_users/surveys/`).update(surveys);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE surveys");
  }

  async userDetails() {
    var userDetails = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;

      if (all_data.userDetails) {
        var transfer = all_data.userDetails;

        var creationTime = "",
          displayName = "",
          email = "",
          phoneNumber = "",
          pushNotificationToken = "";

        if (all_data.pushNotificationToken) {
          pushNotificationToken = all_data.pushNotificationToken.token;
        }

        if (transfer.creationTime) {
          creationTime = transfer.creationTime;
        }

        if (transfer.displayName) {
          displayName = transfer.displayName;
        }

        if (transfer.email) {
          email = transfer.email;
        }

        if (transfer.phoneNumber) {
          phoneNumber = transfer.phoneNumber;
        }

        // userDetails[user_id] = {
        //   pushNotificationToken,
        //   creationTime,
        //   displayName,
        //   email,
        //   phoneNumber,
        //   data: {
        //     creationTime,
        //     displayName,
        //     email,
        //     phoneNumber,
        //   },
        // };

        try {
          await firebase
            .database()
            .ref(`app_users/userDetails/${user_id}`)
            .update({
              pushNotificationToken,
              creationTime,
              displayName,
              email,
              phoneNumber,
              data: {
                creationTime,
                displayName,
                email,
                phoneNumber,
              },
            });
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(userDetails).length > 0) {
    //   console.log(userDetails);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/userDetails/`)
    //       .update(userDetails);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE userDetails");
  }

  async userStatus() {
    var userStatus = {};

    this.state.all_data_extraction.forEach(async (element) => {
      var user_id = element.uid;

      var all_data = element.val;
      var _userDetails = all_data.userDetails;
      var _pushNotificationToken = all_data.pushNotificationToken;

      var info = {};
      if (_userDetails) {
        info = _userDetails;
      }
      if (_pushNotificationToken) {
        info.pushNotificationToken = _pushNotificationToken.token;
      }

      if (all_data.userStatus) {
        // userStatus[user_id] = info;
        info.data = all_data.userStatus;
        try {
          await firebase
            .database()
            .ref(`app_users/userStatus/${user_id}`)
            .update(info);
        } catch (error) {
          console.log(error);
        }
      }
    });
    // if (Object.keys(userStatus).length > 0) {
    //   console.log(userStatus);
    //   try {
    //     await firebase
    //       .database()
    //       .ref(`app_users/userStatus/`)
    //       .update(userStatus);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    console.log("DONE userStatus");
  }

  async test() {
    console.log("WE'RE HERE");
    firebase
      .database()
      .ref(`users/`)
      .once("value")
      .then((snapshot) => {
        const data = _.map(snapshot.val(), (val, uid) => {
          return { uid, val };
        });
        console.log(data.length);
        this.setState({
          all_data_extraction: data,
        });
        return data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  
  
  */
