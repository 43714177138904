import _ from "lodash";

import React, { Component } from "react";
import Lottie from "react-lottie";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import MCQ_OpenField from "./MCQ_OpenField";
//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  render_page() {
    const {
      user_details,
      archive_surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;

    return (
      <MCQ_OpenField
        role={user_details.role}
        data={archive_surveys_and_attendance}
        user_details={user_details}
      />
    );
  }
  render() {
    const {
      user_details,
      archive_surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;
    // console.log({
    //   user_details,
    //   surveys_and_attendance,
    //   qr_details,
    //   access,
    //   questionnaire,
    //   questionnaire_template,
    //   error,
    //   loading,
    // });
    if (loading === true) {
      return (
        <React.Fragment>
          <div>{this.render_loading()}</div>
        </React.Fragment>
      );
    } else {
      if (user_details === null) {
        return (
          <React.Fragment>
            <div>{this.render_loading()}</div>
          </React.Fragment>
        );
      } else {
        if (user_details.role === "Facilitator") {
          return <Redirect to={{ pathname: "/" }} />;
        } else {
          if (archive_surveys_and_attendance === null) {
            return (
              <div className="page-content">
                <Container fluid>
                  <Row className="justify-content-center mt-lg-5">
                    <Col xl="5" sm="8">
                      <Card>
                        <CardBody>
                          <div className="text-center">
                            <Row className="justify-content-center">
                              <Col lg="12">
                                <h4 className="mt-4 font-weight-semibold">
                                  Attendance
                                </h4>
                                <br />
                                <p style={{ color: "#B00020" }}>
                                  No Data Archived Yet!
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          } else {
            if (Object.keys(archive_surveys_and_attendance).length === 0) {
              return (
                <React.Fragment>
                  <div>{this.render_loading()}</div>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment>
                <div>{this.render_page()}</div>
              </React.Fragment>
            );
          }

          // return (
          //   <React.Fragment>
          //     <div>{this.render_page()}</div>
          //   </React.Fragment>
          // );
        }
      }
    }
  }
}
const mapStatetoProps = (state) => {
  const {
    user_details,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
    archive_surveys_and_attendance,
  } = state.Database;

  return {
    user_details,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
    archive_surveys_and_attendance,
  };
};

export default withRouter(
  connect(mapStatetoProps, null)(withNamespaces()(Attendance))
);
//
