import {
  /* USER DETAILS */
  USER_DATA_READ_ASYNC,
  USER_DATA_WRITE_ASYNC,
  USER_DATA_READ,
  USER_DATA_WRITE,

  /* SURVEY DATA AND ATTENDANCE */
  QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC,
  QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC,
  QR_SURVEY_DATA_AND_ATTENDANCE_READ,
  QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,

  /* ARCHIVE SURVEY DATA AND ATTENDANCE */
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC,
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC,
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ,
  ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,

  /* QR CODE */
  QR_CODE_READ_ASYNC,
  QR_CODE_WRITE_ASYNC,
  QR_CODE_READ,
  QR_CODE_WRITE,

  /* BUILDER AND MANAGEMENT */
  QUESTIONNAIRE_READ_ASYNC,
  QUESTIONNAIRE_WRITE_ASYNC,
  QUESTIONNAIRE_TEMPLATE_READ_ASYNC,
  QUESTIONNAIRE_TEMPLATE_WRITE_ASYNC,
  QUESTIONNAIRE_READ,
  QUESTIONNAIRE_WRITE,
  QUESTIONNAIRE_TEMPLATE_READ,
  QUESTIONNAIRE_TEMPLATE_WRITE,

  /* ACCESS */
  ACCESS_READ_ASYNC,
  ACCESS_WRITE_ASYNC,
  ACCESS_READ,
  ACCESS_WRITE,

  /*  */
  DETAIL_ERROR,

  //   /* USER DETAILS */
  //   USER_DATA_READER_REQUEST,
  //   USER_DATA_READER,

  //   /* RESULTS AND ATTENDANCE */
  //   SURVEY_ATTENDANCE_AND_TRANSACTION_DATA_READER_REQUEST,
  //   SURVEY_ATTENDANCE_AND_TRANSACTION_DATA_READER,

  //   /* QR CODE */
  //   SURVEY_QR_DATA_READER_REQUEST,
  //   SURVEY_QR_DATA_FROM_MULTIPLE_LIST_READER,
  //   SURVEY_QR_DATA_READER,
  //   SURVEY_QR_DATA_WRITER,

  //   /* BUILDER AND MANAGEMENT */
  //   CREATE_SURVEY_WRITER,
  //   EDIT_SURVEY_WRITER,
  //   DELETE_SURVEY_WRITER,
  //   CURRENT_QUESTION_TEMPLATE_REQUEST,
  //   CURRENT_QUESTION_TEMPLATE_READER,
  //   CURRENT_QUESTIONNAIRE_REQUEST,
  //   CURRENT_QUESTIONNAIRE_READER,

  //   /* ACCESS */
  //   ACCESS_READER_REQUEST,
  //   ACCESS_READER_READER,

  //   /*  */
  //   DETAIL_ERROR,
} from "./actionTypes";

/* USER DETAILS */
export const user_data_read_async = (payload) => {
  return {
    type: USER_DATA_READ_ASYNC,
    payload: payload,
  };
};

export const user_data_write_async = (payload) => {
  return {
    type: USER_DATA_WRITE_ASYNC,
    payload: payload,
  };
};

////////////------------------------////////////
export const user_data_read = () => {
  return {
    type: USER_DATA_READ,
  };
};

export const user_data_write = (payload) => {
  return {
    type: USER_DATA_WRITE,
    payload: payload,
  };
};

/* RESULTS AND ATTENDANCE */

export const qr_survey_data_and_attendance_read_async = (payload) => {
  return {
    type: QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC,
    payload: payload,
  };
};

export const qr_survey_data_and_attendance_write_async = (payload) => {
  return {
    type: QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC,
    payload: payload,
  };
};

////////////------------------------////////////

export const qr_survey_data_and_attendance_read = () => {
  return {
    type: QR_SURVEY_DATA_AND_ATTENDANCE_READ,
  };
};

export const qr_survey_data_and_attendance_write = (payload) => {
  return {
    type: QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,
    payload: payload,
  };
};

/*ARCHIVE RESULTS AND ATTENDANCE */

export const archive_qr_survey_data_and_attendance_read_async = (payload) => {
  return {
    type: ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ_ASYNC,
    payload: payload,
  };
};

export const archive_qr_survey_data_and_attendance_write_async = (payload) => {
  return {
    type: ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE_ASYNC,
    payload: payload,
  };
};

////////////------------------------////////////

export const archive_qr_survey_data_and_attendance_read = () => {
  return {
    type: ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_READ,
  };
};

export const archive_qr_survey_data_and_attendance_write = (payload) => {
  return {
    type: ARCHIVE_QR_SURVEY_DATA_AND_ATTENDANCE_WRITE,
    payload: payload,
  };
};

/* QR CODE */

export const qr_code_read_async = (payload) => {
  return {
    type: QR_CODE_READ_ASYNC,
    payload: payload,
  };
};

export const qr_code_write_async = (payload) => {
  return {
    type: QR_CODE_WRITE_ASYNC,
    payload: payload,
  };
};

////////////------------------------////////////

export const qr_code_read = () => {
  return {
    type: QR_CODE_READ,
  };
};

export const qr_code_write = (payload) => {
  return {
    type: QR_CODE_WRITE,
    payload: payload,
  };
};

/* BUILDER AND MANAGEMENT */

export const questionnaire_read_async = (payload) => {
  return {
    type: QUESTIONNAIRE_READ_ASYNC,
    payload: payload,
  };
};

export const questionnaire_write_async = (payload) => {
  return {
    type: QUESTIONNAIRE_WRITE_ASYNC,
    payload: payload,
  };
};

export const questionnaire_template_read_async = (payload) => {
  return {
    type: QUESTIONNAIRE_TEMPLATE_READ_ASYNC,
    payload: payload,
  };
};

export const questionnaire_template_write_async = (payload) => {
  return {
    type: QUESTIONNAIRE_TEMPLATE_WRITE_ASYNC,
    payload: payload,
  };
};

////////////------------------------////////////

export const questionnaire_read = () => {
  return {
    type: QUESTIONNAIRE_READ,
  };
};

export const questionnaire_write = (payload) => {
  return {
    type: QUESTIONNAIRE_WRITE,
    payload: payload,
  };
};

export const questionnaire_template_read = () => {
  return {
    type: QUESTIONNAIRE_TEMPLATE_READ,
  };
};

export const questionnaire_template_write = (payload) => {
  return {
    type: QUESTIONNAIRE_TEMPLATE_WRITE,
    payload: payload,
  };
};

/* ACCESS */
export const access_read_async = (payload) => {
  return {
    type: ACCESS_READ_ASYNC,
    payload: payload,
  };
};

export const access_write_async = (payload) => {
  return {
    type: ACCESS_WRITE_ASYNC,
    payload: payload,
  };
};
////////////------------------------////////////

export const access_read = () => {
  return {
    type: ACCESS_READ,
  };
};

export const access_write = (payload) => {
  return {
    type: ACCESS_WRITE,
    payload: payload,
  };
};

/*  */

export const detail_error = (error) => {
  return {
    type: DETAIL_ERROR,
    payload: error,
  };
};
