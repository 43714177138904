import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import RetailDashboardPieCharts from "../pages/RetailDashboardPieCharts/index";

// Dashboard
//import Dashboard_Retail_All from "../pages/Dashboard_Retail_All/index";

import SurveyQR from "../pages/SurveyQR/index";
import Attendance from "../pages/Attendance/index";
import SurveyBuilder from "../pages/SurveyBuilder/index";
import SurveyManagement from "../pages/SurveyManagement/index";
import Access from "../pages/Access/index";
import Archived_Dashboard from "../pages/Archived_Dashboard/index";
import Archived_Attendance from "../pages/Archived_Attendance/index";

//import Loading from "../pages/Loading/index";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/charts", component: RetailDashboardPieCharts },

  // { path: "/dashboard-all", component: Dashboard_Retail_All },
  { path: "/QR", component: SurveyQR },
  { path: "/attendance", component: Attendance },
  { path: "/surveyBuilder", component: SurveyBuilder },
  { path: "/surveyManagement", component: SurveyManagement },
  { path: "/access", component: Access },

  { path: "/archived-dashboard", component: Archived_Dashboard },
  { path: "/archived-attendance", component: Archived_Attendance },

  // { path: "/", component: Loading },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
