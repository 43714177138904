import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SurveyBuilder from "./SurveyBuilder";
import Lottie from "react-lottie";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import RetailBuilder from "./RetailBuilder";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    // const {
    //   user_details,
    //   surveys_and_attendance,
    //   qr_details,
    //   access,
    //   questionnaire,
    //   questionnaire_template,
    //   error,
    //   loading,
    // } = this.props;
    // console.log({
    //   user_details,
    //   surveys_and_attendance,
    //   qr_details,
    //   access,
    //   questionnaire,
    //   questionnaire_template,
    //   error,
    //   loading,
    // });
    const { user_details } = this.props;

    if (user_details === null) {
      return (
        <React.Fragment>
          <div>{this.render_loading()}</div>
        </React.Fragment>
      );
    } else {
      const {
        dashboard_access,
        qr_access_granted,
        survey_builder_access,
      } = user_details;

      var dash_access = false,
        qr_access = false,
        builder_access = false;

      if (survey_builder_access) {
        builder_access = survey_builder_access.access;
      }

      if (
        user_details.role === "Facilitator" &&
        (builder_access === false || builder_access === "false")
      ) {
        return <Redirect to={{ pathname: "/" }} />;
      } else {
        if (user_details.user_type === "retail") {
          return <RetailBuilder />;
        }
        //Lottie
        return (
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title="Survey Builder"
                  breadcrumbItem="Survey Builder"
                />
                <Row>
                  <Col lg="12">
                    <SurveyBuilder />
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        );
      }
    }
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, null)(withNamespaces()(Index))
);

/*
import React, { Component } from "react";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// // actions
// import {
//   // USER DETAILS
//   user_data_read,
//   user_data_write,
//   //SURVEY DATA AND ATTENDANCE 
//   qr_survey_data_and_attendance_read,
//   qr_survey_data_and_attendance_write,
//   //QR CODE 
//   qr_code_read,
//   qr_code_write,
//   // BUILDER AND MANAGEMENT
//   questionnaire_read,
//   questionnaire_write,
//   questionnaire_template_read,
//   questionnaire_template_write,
//   // ACCESS 
//   access_read,
//   access_write,
//   // ERROR
//   detail_error,
// } from "../../store/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   //USER DETAILS 
  //   this.props.user_data_read();
  //   this.props.user_data_write();
  //   //SURVEY DATA AND ATTENDANCE 
  //   this.props.qr_survey_data_and_attendance_read();
  //   this.props.qr_survey_data_and_attendance_write();
  //   // QR CODE 
  //   this.props.qr_code_read();
  //   this.props.qr_code_write();
  //   // BUILDER AND MANAGEMENT 
  //   this.props.questionnaire_read();
  //   this.props.questionnaire_write();
  //   this.props.questionnaire_template_read();
  //   this.props.questionnaire_template_write();
  //   // ACCESS 
  //   this.props.access_read();
  //   this.props.access_write();
  //   //ERROR
  //   this.props.detail_error();
  // }

  render() {
    const {
      user_details,
      surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;
    console.log({
      user_details,
      surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    });
    return (
      <React.Fragment>
        <div className="page-content"></div>
      </React.Fragment>
    );
  }
}

//export default withNamespaces()(Dashboard);

const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(
    mapStatetoProps,
    null
    //   {
    //   // USER DETAILS
    //   user_data_read,
    //   user_data_write,
    //   // SURVEY DATA AND ATTENDANCE
    //   qr_survey_data_and_attendance_read,
    //   qr_survey_data_and_attendance_write,
    //   // QR CODE
    //   qr_code_read,
    //   qr_code_write,
    //   // BUILDER AND MANAGEMENT
    //   questionnaire_read,
    //   questionnaire_write,
    //   questionnaire_template_read,
    //   questionnaire_template_write,
    //   // ACCESS
    //   access_read,
    //   access_write,
    //   // ERROR
    //   detail_error,
    // }
  )(withNamespaces()(Index))
);

*/
