import _ from "lodash";
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Form,
  FormGroup,
  Button,
  Input,
  Modal,
  ModalBody,
  InputGroup,
  ModalHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import classnames from "classnames";
import Lottie from "react-lottie";
//import update from "react-addons-update";
import update from "immutability-helper";

import firebase from "firebase/app";
import "firebase/database";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import {
  questionnaire_read,
  questionnaire_template_read,
} from "../../store/actions";

// //Actions
// import {
//   current_question_template_request,
//   current_questionnaire_request,
//   user_data_reader_request,
// } from "../../store/actions";

import { __Addons } from "react";

import "./CSS/surveyBuilder.css";

class Retail_Builder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      activeTab: "1",
      tab_1_template_select: "",
      tab_1_survey_name: "",
      tab_1_email_required: "",
      tab_1_specify_email_requirment: "",
      tab_1_type_of_email: "",
      tab_1_draw_required: "",
      tab_1_survey_expiry: "",

      tab_4_survey_name: "",
      tab_5_template_name: "",

      title: "Build Survey Using Template",
      modal: false,
      modal_request: "",

      modal_survey_save: false,
      special_characters_used: false,

      modal_template_save: false,

      modal_survey_update: false,
      modal_template_update: false,

      questionaire_details: {},

      template_url: "",

      rows: [],
      show_editing: false,
      custom: false,
      modal_delete_template: false,

      modal_pre_built: false,
      show_main_screen: true,
      pre_built_survey: {},
      questionnaire: [
        {
          0: { question: "" },
          1: { questionnaire_type: "" },
        },
      ],
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.questionnaire_read();
    this.props.questionnaire_template_read();
  }
  ///////////////// BOTTOM EXTRA JUST ADDED FOR BUILDING SURVEY
  handleAddRow = () => {
    const item = {
      name: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };

  handleRemoveRow = (e, idx) => {
    // console.log(idx);
    if (typeof idx != "undefined")
      document.getElementById("addr" + idx).style.display = "none";
  };

  ///////////////// TOP EXTRA JUST ADDED FOR BUILDING SURVEY

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  /* BOTTOM FOR THE THEME */
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  togglemodal_pressed_yes() {
    const { for_, data } = this.state.modal_request;
    if (for_ === "Delete_Survey") {
      firebase.database().ref(data.QR_Code_Link).remove();
      firebase.database().ref(data.survey_id_for_QR_creation_push_id).remove();
      firebase.database().ref(data.questionnaire_link).remove();
      // this.props.current_questionnaire_request();
      this.props.questionnaire_read();
      // this.props.questionnaire_template_read()

      this.setState({ tab_4_survey_name: "" });
      alert("Success!");
    }
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  togglemodal_pressed_no() {
    const { for_, data } = this.state.modal_request;

    if (for_ === "Delete_Survey") {
      this.setState({ tab_4_survey_name: "" });
    }

    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  renderModal_to_build_survey_from_pre_built() {
    //renderModal_to_build_survey_from_pre_built

    var title = "";
    if (Object.keys(this.state.pre_built_survey).length > 0) {
      if (this.state.pre_built_survey.template_name === "contact_tracing") {
        title = "Please enter store name to create Covid Contact Tracing Form:";
      } else {
        title = "Please enter store name to create Store Performance Survey:";
      }
    }

    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal_pre_built}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={() => {
          this.setState((prevState) => ({
            modal_pre_built: !prevState.modal_pre_built,
          }));
        }}
      >
        <div className="modal-content">
          <ModalBody>
            <div className="text-center">
              <Row className="justify-content-center">
                <Col>
                  <div>
                    <h4 className="mt-4 font-weight-semibold">{title}</h4>
                    <div className="mt-4">
                      <Row className="justify-content-center">
                        <Label htmlFor="formname">Survey Name : </Label>

                        <Input
                          type="text"
                          id="formname"
                          placeholder="Enter Survey Name"
                          className="form-control"
                          onChange={(event) => {
                            event.preventDefault();

                            /*  if (event.target.value === "") {
                              this.setState({
                                tab_1_email_required: "",
                                tab_1_specify_email_requirment: "",
                                tab_1_type_of_email: "",
                                tab_1_draw_required: "",
                              });
                            }
  */
                            this.setState({
                              tab_1_survey_name: event.target.value,
                            });
                          }}
                        />

                        <div style={{ padding: 5 }}>
                          <Button
                            type="button"
                            color="light"
                            className="btn-lg"
                            onClick={() => {
                              this.setState((prevState) => ({
                                modal_pre_built: !prevState.modal_pre_built,
                              }));
                            }}
                          >
                            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                              Cancel
                            </span>
                          </Button>
                        </div>
                        <div style={{ padding: 5 }}>
                          <Button
                            type="button"
                            color="primary"
                            className="btn-lg"
                            onClick={() => {
                              if (this.state.tab_1_survey_name === "") {
                                alert(
                                  "Please enter survey name before continuing!"
                                );
                              } else {
                                var pre_built_survey = this.state
                                  .pre_built_survey;
                                var survey_name = this.state.tab_1_survey_name;
                                pre_built_survey.survey_name = this.state.tab_1_survey_name;
                                pre_built_survey.company_or_institution_name = this.props.questionnaire_template[0].company_or_institution_name;
                                pre_built_survey.department = this.props.questionnaire_template[0].program_name;

                                this.process_save_survey_from_pre_built_template(
                                  pre_built_survey,
                                  survey_name
                                );

                                this.setState((prevState) => ({
                                  modal_pre_built: !prevState.modal_pre_built,
                                }));
                              }
                            }}
                          >
                            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                              Create
                            </span>
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  renderModal() {
    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalBody>
            <div className="text-center">
              <Row className="justify-content-center">
                <Col
                //xl="5" sm="8"
                >
                  <div>
                    <h1 className="mt-4 font-weight-semibold">Are you sure?</h1>
                    <div className="mt-4">
                      <Row className="justify-content-center">
                        <div style={{ padding: 5 }}>
                          <Button
                            type="button"
                            color="primary"
                            className="btn-lg"
                            onClick={() => {
                              this.togglemodal_pressed_yes();
                            }}
                          >
                            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                              Yes
                            </span>
                          </Button>
                        </div>
                        <div style={{ padding: 5 }}>
                          <Button
                            type="button"
                            color="light"
                            className="btn-lg"
                            onClick={() => {
                              this.togglemodal_pressed_no();
                            }}
                          >
                            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                              Cancel
                            </span>
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  renderModal_delete_template() {
    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal_delete_template}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={() => {
          this.setState((prevState) => ({
            modal_delete_template: !prevState.modal_delete_template,
          }));
        }}
      >
        <div className="modal-content">
          <ModalBody>
            <div className="text-center">
              <Row className="justify-content-center">
                <Col
                //xl="5" sm="8"
                >
                  <div>
                    <h1 className="mt-4 font-weight-semibold">Are you sure?</h1>
                    <div className="mt-4">
                      <Row className="justify-content-center">
                        <div style={{ padding: 5 }}>
                          <Button
                            type="button"
                            color="primary"
                            className="btn-lg"
                            onClick={() => {
                              //  this.togglemodal_pressed_yes();

                              firebase
                                .database()
                                .ref(this.state.template_url)
                                .remove()
                                .then(() => {
                                  alert("Success!");
                                  // this.props.current_questionnaire_request();
                                  // this.props.current_question_template_request();
                                  this.props.questionnaire_read();
                                  this.props.questionnaire_template_read();
                                })
                                .catch((err) => {
                                  //   console.log(err);
                                  alert("Error! ");
                                });

                              this.setState((prevState) => ({
                                modal_delete_template: !prevState.modal_delete_template,
                              }));
                            }}
                          >
                            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                              Yes
                            </span>
                          </Button>
                        </div>
                        <div style={{ padding: 5 }}>
                          <Button
                            type="button"
                            color="light"
                            className="btn-lg"
                            onClick={() => {
                              this.setState((prevState) => ({
                                modal_delete_template: !prevState.modal_delete_template,
                              }));
                            }}
                          >
                            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                              Cancel
                            </span>
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  process_save_survey_content(template, survey_name) {
    //console.log(template);
    //console.log(survey_name);

    var questionnaire_template = this.props.questionnaire_template;

    //console.log(questionnaire_template[0].questionnaire_url);

    firebase
      .database()
      .ref(`${questionnaire_template[0].questionnaire_url}/${survey_name}`)
      .once("value", (snapshot) => {
        if (snapshot.val() === null) {
          var surveyID = firebase
            .database()
            .ref(
              `${questionnaire_template[0].questionnaire_url}/${survey_name}`
            )
            .push().key;

          firebase
            .database()
            .ref(
              `${questionnaire_template[0].questionnaire_url}/${survey_name}/${surveyID}`
            )
            .update(template)
            .then(() => {
              var deep_link_url =
                "https://survey.getluup.com/?survey=" + surveyID;
              var dynamic_link_name = "surveyID: " + surveyID;
              var data = template;

              firebase
                .database()
                .ref(`QR_Code/survey_id_for_QR_creation/${surveyID}`)
                .update({
                  surveyID,
                  data,
                  deep_link_url,
                  dynamic_link_name, //, link_part
                })
                .then(() => {
                  if (this.props.user_details.role === "Facilitator") {
                    firebase
                      .database()
                      .ref(
                        `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}`
                      )
                      .once("value", (snap_data) => {
                        var snap_data_array = _.map(
                          snap_data.val(),
                          (val, uid) => {
                            return {
                              ...val,
                              uid,
                            };
                          }
                        );

                        var user_uid = firebase.auth().currentUser.uid;
                        var found = snap_data_array.find((ele) => {
                          if (ele.user_id === user_uid) {
                            return ele;
                          }
                        });

                        firebase
                          .database()
                          .ref(
                            `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}/${found.uid}/added_programs`
                          )
                          .push({
                            access: survey_name,
                          })
                          .then(() => {
                            firebase
                              .database()
                              .ref(
                                `partner/users/${user_uid}/all_programs/${questionnaire_template[0].program_name}/survey_access/${survey_name}`
                              )
                              .update({
                                access: true,
                              })
                              .then(() => {
                                alert("Success!");
                                this.setState((prevState) => ({
                                  modal_survey_save: !prevState.modal_survey_save,
                                  tab_1_template_select: "",
                                  tab_1_survey_name: "",
                                  tab_1_email_required: "",
                                  tab_1_specify_email_requirment: "",
                                  tab_1_type_of_email: "",
                                  tab_1_draw_required: "",
                                  special_characters_used: false,
                                }));
                                // this.props.current_questionnaire_request();
                                // this.props.current_question_template_request();
                                this.props.questionnaire_read();
                                this.props.questionnaire_template_read();
                              });
                          });
                      });
                  } else {
                    alert("Success!");
                    this.setState((prevState) => ({
                      modal_survey_save: !prevState.modal_survey_save,
                      tab_1_template_select: "",
                      tab_1_survey_name: "",
                      tab_1_email_required: "",
                      tab_1_specify_email_requirment: "",
                      tab_1_type_of_email: "",
                      tab_1_draw_required: "",
                      special_characters_used: false,
                    }));
                    // this.props.current_questionnaire_request();
                    // this.props.current_question_template_request();
                    this.props.questionnaire_read();
                    this.props.questionnaire_template_read();
                  }
                })
                .catch((error) => {
                  alert("Error");
                });
            });
        } else {
          alert("You already have a QR and Survey built!");
        }
      });
  }

  process_save_survey_from_pre_built_template(template, survey_name) {
    var questionnaire_template = this.props.questionnaire_template;

    firebase
      .database()
      .ref(`${questionnaire_template[0].questionnaire_url}/${survey_name}`)
      .once("value", (snapshot) => {
        //  console.log(this.props.current_questionnaire_request());
        if (snapshot.val() === null) {
          var surveyID = firebase
            .database()
            .ref(
              `${questionnaire_template[0].questionnaire_url}/${survey_name}`
            )
            .push().key;

          firebase
            .database()
            .ref(
              `${questionnaire_template[0].questionnaire_url}/${survey_name}/${surveyID}`
            )
            .update(template)
            .then(() => {
              var deep_link_url =
                "https://survey.getluup.com/?survey=" + surveyID;
              var dynamic_link_name = "surveyID: " + surveyID;
              var data = template;

              firebase
                .database()
                .ref(`QR_Code/survey_id_for_QR_creation/${surveyID}`)
                .update({
                  surveyID,
                  data,
                  deep_link_url,
                  dynamic_link_name, //, link_part
                })
                .then(() => {
                  if (this.props.user_details.role === "Facilitator") {
                    firebase
                      .database()
                      .ref(
                        `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}`
                      )
                      .once("value", (snap_data) => {
                        var snap_data_array = _.map(
                          snap_data.val(),
                          (val, uid) => {
                            return {
                              ...val,
                              uid,
                            };
                          }
                        );

                        var user_uid = firebase.auth().currentUser.uid;
                        var found = snap_data_array.find((ele) => {
                          if (ele.user_id === user_uid) {
                            return ele;
                          }
                        });

                        firebase
                          .database()
                          .ref(
                            `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}/${found.uid}/added_programs`
                          )
                          .push({
                            access: survey_name,
                          })
                          .then(() => {
                            firebase
                              .database()
                              .ref(
                                `partner/users/${user_uid}/all_programs/${questionnaire_template[0].program_name}/survey_access/${survey_name}`
                              )
                              .update({
                                access: true,
                              })
                              .then(() => {
                                alert("Success!");
                                this.setState((prevState) => ({
                                  modal_survey_save: !prevState.modal_survey_save,
                                  tab_1_template_select: "",
                                  tab_1_survey_name: "",
                                  tab_1_email_required: "",
                                  tab_1_specify_email_requirment: "",
                                  tab_1_type_of_email: "",
                                  tab_1_draw_required: "",
                                  special_characters_used: false,
                                }));
                                // this.props.current_questionnaire_request();
                                // this.props.current_question_template_request();
                                this.props.questionnaire_read();
                                this.props.questionnaire_template_read();
                              });
                          });
                      });
                  } else {
                    alert("Success!");
                    this.setState((prevState) => ({
                      modal_survey_save: !prevState.modal_survey_save,
                      tab_1_template_select: "",
                      tab_1_survey_name: "",
                      tab_1_email_required: "",
                      tab_1_specify_email_requirment: "",
                      tab_1_type_of_email: "",
                      tab_1_draw_required: "",
                      special_characters_used: false,
                    }));
                    // this.props.current_questionnaire_request();
                    // this.props.current_question_template_request();
                    this.props.questionnaire_read();
                    this.props.questionnaire_template_read();
                  }
                })

                .catch((error) => {
                  console.log(error);
                  alert("Error");
                });
            });
        } else {
          alert("You already have a QR and Survey built!");
        }
      });
  }

  render_save_survey_content_retail() {
    const {
      tab_1_template_select,
      tab_1_survey_name,
      tab_1_email_required,
      tab_1_specify_email_requirment,
      tab_1_type_of_email,
      tab_1_draw_required,
    } = this.state;
    var regex = /[^\w\s]/g;
    return (
      <div>
        <div>
          <div className="form-group">
            <Label htmlFor="formname">Survey Name : </Label>
            <Input
              type="text"
              id="formname"
              placeholder="Enter Survey Name"
              className="form-control"
              onChange={(event) => {
                event.preventDefault();

                if (event.target.value === "") {
                  this.setState({
                    tab_1_email_required: "",
                    tab_1_specify_email_requirment: "",
                    tab_1_type_of_email: "",
                    tab_1_draw_required: "",
                  });
                }

                this.setState({
                  tab_1_survey_name: event.target.value,
                });
              }}
            />
            {tab_1_survey_name.search(regex) !== -1 ? (
              <p style={{ color: "#B00020" }}>
                To continue please avoid using special characters.
              </p>
            ) : null}
          </div>
        </div>

        <Button
          type="submit"
          color="primary"
          onClick={(event) => {
            event.preventDefault();

            var questionnaire_template = this.props.questionnaire_template;
            var template = {
              questions: this.state.questionnaire,
            };

            var survey_name = tab_1_survey_name;
            var regex = /[^\w\s]/g;

            if (survey_name.search(regex) !== -1) {
              alert("Please avoid using Special Characters.");
            } else {
              template.surveyFor = "Customer";
              template.unique_entry_required = true;
              template.type_of_email = "any email";
              template.survey_type = "retail";
              // template.survey_expiry = "never";
              // if(this.props.user_details.user_type==="retail"){
              template.survey_expiry = "never";
              template.custom_expiry = "Never Expiry";
              template.subText = "Add Something Here for Retail";
              // }
              template.in_person_draw = false;
              template.email_required = false;
              template.instant_draw = true;
              template.giftcard_with_each_feedback = false;
              template.survey_name = survey_name;

              template.draw = false;
              template.company_or_institution_name =
                questionnaire_template[0].company_or_institution_name;
              template.department = questionnaire_template[0].program_name;

              this.process_save_survey_content(template, survey_name);
            }
          }}
        >
          Create Survey
        </Button>
      </div>
    );
  }
  render_save_survey_content() {
    //const { questionnaire, questionnaire_template, loading } = this.props;

    const {
      tab_1_template_select,
      tab_1_survey_name,
      tab_1_email_required,
      tab_1_specify_email_requirment,
      tab_1_type_of_email,
      tab_1_draw_required,
    } = this.state;
    var regex = /[^\w\s]/g;

    return (
      <div>
        <div>
          <div className="form-group">
            <Label htmlFor="formname">Survey Name : </Label>
            <Input
              type="text"
              id="formname"
              placeholder="Enter Survey Name"
              className="form-control"
              onChange={(event) => {
                event.preventDefault();

                if (event.target.value === "") {
                  this.setState({
                    tab_1_email_required: "",
                    tab_1_specify_email_requirment: "",
                    tab_1_type_of_email: "",
                    tab_1_draw_required: "",
                  });
                }

                this.setState({
                  tab_1_survey_name: event.target.value,
                });
              }}
            />
            {tab_1_survey_name.search(regex) !== -1 ? (
              <p style={{ color: "#B00020" }}>
                To continue please avoid using special characters.
              </p>
            ) : null}
          </div>

          <FormGroup
            onChange={(event) => {
              // event.preventDefault();
              if (event.target.value === "No") {
                this.setState({
                  tab_1_draw_required: "No",
                  tab_1_email_required: "",
                  tab_1_type_of_email: "",
                });
              } else {
                this.setState({
                  tab_1_draw_required: "",
                  tab_1_email_required: "",
                  tab_1_type_of_email: "",
                });
              }
              this.setState({
                tab_1_survey_expiry: event.target.value,
              });
            }}
          >
            <Label className="d-block mb-3">
              Would you like the survey to expire? (By default all surveys
              expire automatically after 24 hours of creating a new session)
            </Label>
            <div className="custom-control custom-radio custom-control-inline">
              <Input
                type="radio"
                id="yesExpiryButton"
                name="customRadioExpiry3"
                className="custom-control-input"
                value="Yes"
              />
              <Label className="custom-control-label" htmlFor="yesExpiryButton">
                Yes
              </Label>
            </div>
            &nbsp;
            <div className="custom-control custom-radio custom-control-inline">
              <Input
                type="radio"
                id="noExpiryButton"
                name="customRadioExpiry3"
                className="custom-control-input"
                value="No"
              />
              <Label className="custom-control-label" htmlFor="noExpiryButton">
                No
              </Label>
            </div>
          </FormGroup>
          {this.state.tab_1_survey_expiry === "" ? (
            <p style={{ color: "#B00020" }}>
              Note: If you select "No", you will not be able to do a prize draw.
            </p>
          ) : null}

          {this.state.tab_1_survey_expiry !== "" ? (
            <div>
              {this.state.tab_1_survey_expiry === "Yes" ? (
                <FormGroup
                  onChange={(event) => {
                    // event.preventDefault();

                    this.setState({
                      tab_1_draw_required: event.target.value,
                    });
                  }}
                >
                  <Label className="d-block mb-3">
                    Would you like a draw/prize at the end of the survey?
                  </Label>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Input
                      type="radio"
                      id="yesexpirySurvey"
                      name="expirySurvey"
                      className="custom-control-input"
                      value="Yes"
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="yesexpirySurvey"
                    >
                      Yes
                    </Label>
                  </div>
                  &nbsp;
                  <div className="custom-control custom-radio custom-control-inline">
                    <Input
                      type="radio"
                      id="noexpirySurvey"
                      name="expirySurvey"
                      className="custom-control-input"
                      value="No"
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="noexpirySurvey"
                    >
                      No
                    </Label>
                  </div>
                </FormGroup>
              ) : null}
            </div>
          ) : null}
        </div>
        {tab_1_survey_name !== "" &&
        tab_1_draw_required !== "" &&
        tab_1_survey_name.search(regex) === -1 ? (
          <div>
            <FormGroup
              onChange={(event) => {
                // event.preventDefault();

                this.setState({
                  tab_1_email_required: event.target.value,
                });
              }}
            >
              <Label className="d-block mb-3">Do you require an email? :</Label>
              <div className="custom-control custom-radio custom-control-inline">
                <Input
                  type="radio"
                  id="yesButton"
                  name="customRadioInline1"
                  className="custom-control-input"
                  value="Yes"
                />
                <Label className="custom-control-label" htmlFor="yesButton">
                  Yes
                </Label>
              </div>
              &nbsp;
              <div className="custom-control custom-radio custom-control-inline">
                <Input
                  type="radio"
                  id="noButton"
                  name="customRadioInline1"
                  className="custom-control-input"
                  value="No"
                />
                <Label className="custom-control-label" htmlFor="noButton">
                  No
                </Label>
              </div>
            </FormGroup>

            {tab_1_email_required === "Yes" && tab_1_email_required !== "" ? (
              <div>
                <FormGroup
                  onChange={(event) => {
                    //   event.preventDefault();

                    this.setState({
                      tab_1_specify_email_requirment: event.target.value,
                    });
                  }}
                >
                  <Label className="d-block mb-3">
                    Do you require specific type of email (e.g ryerson.ca,
                    gmail.com, etc.)? :
                  </Label>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Input
                      type="radio"
                      id="yesButton1"
                      name="customRadioInline2"
                      className="custom-control-input"
                      value="Yes"
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="yesButton1"
                    >
                      Yes
                    </Label>
                  </div>
                  &nbsp;
                  <div className="custom-control custom-radio custom-control-inline">
                    <Input
                      type="radio"
                      id="noButton2"
                      name="customRadioInline2"
                      className="custom-control-input"
                      value="No"
                    />
                    <Label className="custom-control-label" htmlFor="noButton2">
                      No
                    </Label>
                  </div>
                </FormGroup>

                {tab_1_specify_email_requirment === "Yes" &&
                tab_1_specify_email_requirment !== "" ? (
                  <div>
                    <div className="form-group">
                      <Label htmlFor="formname">Provide email type : </Label>
                      <Input
                        onChange={(event) => {
                          event.preventDefault();
                          this.setState({
                            tab_1_type_of_email: event.target.value,
                          });
                        }}
                        id="formname"
                        placeholder="gmail.com, hotmail.com, ryerson.ca, etc..."
                        className="form-control"
                      />
                    </div>

                    {tab_1_type_of_email === "" ? null : (
                      <Button
                        type="submit"
                        color="primary"
                        onClick={(event) => {
                          event.preventDefault();

                          var questionnaire_template = this.props
                            .questionnaire_template;
                          var template = {
                            questions: this.state.questionnaire,
                          };

                          var survey_name = tab_1_survey_name;
                          var regex = /[^\w\s]/g;

                          if (survey_name.search(regex) !== -1) {
                            alert("Please avoid using Special Characters.");
                          } else {
                            //var template = tab_1_template_select;
                            var draw = "";
                            var email_required = "";
                            var subText = "";
                            var unique_entry_required = "";
                            var type_of_email = tab_1_type_of_email;

                            if (tab_1_draw_required === "Yes") {
                              draw = true;

                              subText =
                                "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                            } else {
                              draw = false;
                              subText =
                                "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                            }

                            if (tab_1_specify_email_requirment === "Yes") {
                              email_required = true;
                            } else {
                              email_required = false;
                            }

                            ///  if (tab_1_email_required === "Yes") {

                            if (
                              tab_1_email_required === "Yes" ||
                              tab_1_draw_required === "Yes"
                              //draw === true
                            ) {
                              unique_entry_required = true;
                            } else {
                              unique_entry_required = false;
                            }

                            if (
                              // questionnaire_template[0].user_details
                              //   .getUserDeatils.user_type === "institute"
                              this.props.user_details.user_type === "institute"
                            ) {
                              template.in_person_draw = draw;
                            } else {
                              template.in_person_draw = false;
                            }

                            if (this.state.tab_1_survey_expiry === "No") {
                              unique_entry_required = false;
                              template.survey_expiry = "never";
                              template.custom_expiry = "Never Expiry";
                            }

                            template.draw = draw;
                            template.email_required = email_required;
                            template.type_of_email = type_of_email;
                            template.subText = subText;
                            template.unique_entry_required = unique_entry_required;
                            template.survey_name = survey_name;

                            template.company_or_institution_name =
                              questionnaire_template[0].company_or_institution_name;
                            template.department =
                              questionnaire_template[0].program_name;

                            var user_prize_draw = "5";
                            var general_prize_draw = "5";
                            template.user_prize_draw = user_prize_draw;
                            template.general_prize_draw = general_prize_draw;

                            if (
                              this.props.user_details.user_type === "retail"
                            ) {
                              template.survey_expiry = "never";
                              template.custom_expiry = "Never Expiry";
                            }

                            this.process_save_survey_content(
                              template,
                              survey_name
                            );
                          }
                        }}
                      >
                        Create
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    {tab_1_specify_email_requirment === "No" &&
                    tab_1_specify_email_requirment !== "" ? (
                      <Button
                        type="submit"
                        color="primary"
                        onClick={(event) => {
                          event.preventDefault();

                          var questionnaire_template = this.props
                            .questionnaire_template;
                          var template = {
                            questions: this.state.questionnaire,
                          };

                          var survey_name = tab_1_survey_name;
                          var regex = /[^\w\s]/g;

                          if (survey_name.search(regex) !== -1) {
                            alert("Please avoid using Special Characters.");
                          } else {
                            // var template = tab_1_template_select;
                            var draw = "";
                            var email_required = "";
                            var subText = "";
                            var unique_entry_required = "";
                            var type_of_email = tab_1_type_of_email;

                            if (tab_1_draw_required === "Yes") {
                              draw = true;
                              subText =
                                "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                            } else {
                              draw = false;
                              subText =
                                "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                            }

                            if (tab_1_specify_email_requirment === "Yes") {
                              email_required = true;
                            } else {
                              email_required = false;
                            }

                            // if (tab_1_email_required === "Yes") {

                            if (
                              tab_1_email_required === "Yes" ||
                              tab_1_draw_required === "Yes"
                              //draw === true
                            ) {
                              unique_entry_required = true;
                            } else {
                              unique_entry_required = false;
                            }

                            if (
                              // questionnaire_template[0].user_details
                              //   .getUserDeatils.user_type === "institute"
                              this.props.user_details.user_type === "institute"
                            ) {
                              template.in_person_draw = draw;
                            } else {
                              template.in_person_draw = false;
                            }

                            if (this.state.tab_1_survey_expiry === "No") {
                              unique_entry_required = false;
                              template.survey_expiry = "never";
                              template.custom_expiry = "Never Expiry";
                            }

                            template.draw = draw;
                            template.email_required = email_required;
                            template.type_of_email = type_of_email;
                            template.subText = subText;
                            template.unique_entry_required = unique_entry_required;
                            template.survey_name = survey_name;

                            template.company_or_institution_name =
                              questionnaire_template[0].company_or_institution_name;
                            template.department =
                              questionnaire_template[0].program_name;
                            var user_prize_draw = "5";
                            var general_prize_draw = "5";
                            template.user_prize_draw = user_prize_draw;
                            template.general_prize_draw = general_prize_draw;

                            if (
                              this.props.user_details.user_type === "retail"
                            ) {
                              template.survey_expiry = "never";
                              template.custom_expiry = "Never Expiry";
                            }

                            this.process_save_survey_content(
                              template,
                              survey_name
                            );
                          }
                        }}
                      >
                        Create
                      </Button>
                    ) : null}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {tab_1_email_required === "No" ? (
                  <Button
                    type="submit"
                    color="primary"
                    onClick={(event) => {
                      event.preventDefault();
                      // console.log("THREE");
                      var questionnaire_template = this.props
                        .questionnaire_template;
                      var template = {
                        questions: this.state.questionnaire,
                      };

                      const {
                        tab_1_survey_name,
                        tab_1_email_required,
                        tab_1_specify_email_requirment,
                        tab_1_type_of_email,
                        tab_1_draw_required,
                      } = this.state;

                      var survey_name = tab_1_survey_name;
                      var regex = /[^\w\s]/g;

                      if (survey_name.search(regex) !== -1) {
                        alert("Please avoid using Special Characters.");
                      } else {
                        var template = {
                          questions: this.state.questionnaire,
                        };
                        // tab_1_template_select;
                        var draw = "";
                        var email_required = "";
                        var subText = "";
                        var unique_entry_required = "";
                        var type_of_email = tab_1_type_of_email;
                        if (tab_1_draw_required === "Yes") {
                          draw = true;
                          subText =
                            "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                        } else {
                          draw = false;
                          subText =
                            "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                        }
                        email_required = false;

                        if (tab_1_specify_email_requirment === "Yes") {
                          email_required = true;
                        } else {
                          email_required = false;
                        }

                        if (
                          tab_1_email_required === "Yes" ||
                          tab_1_draw_required === "Yes"
                          //draw === true
                        ) {
                          unique_entry_required = true;
                        } else {
                          unique_entry_required = false;
                        }

                        if (
                          // questionnaire_template[0].user_details.getUserDeatils
                          //   .user_type === "institute"
                          this.props.user_details.user_type === "institute"
                        ) {
                          template.in_person_draw = draw;
                        } else {
                          template.in_person_draw = false;
                        }

                        if (this.state.tab_1_survey_expiry === "No") {
                          unique_entry_required = false;
                          template.survey_expiry = "never";
                          template.custom_expiry = "Never Expiry";
                        }

                        template.draw = draw;
                        template.email_required = email_required;
                        template.type_of_email = type_of_email;
                        template.subText = subText;
                        template.unique_entry_required = unique_entry_required;
                        template.survey_name = survey_name;

                        template.company_or_institution_name =
                          questionnaire_template[0].company_or_institution_name;
                        template.department =
                          questionnaire_template[0].program_name;
                        var user_prize_draw = "5";
                        var general_prize_draw = "5";
                        template.user_prize_draw = user_prize_draw;
                        template.general_prize_draw = general_prize_draw;

                        if (this.props.user_details.user_type === "retail") {
                          template.survey_expiry = "never";
                          template.custom_expiry = "Never Expiry";
                        }

                        this.process_save_survey_content(template, survey_name);
                      }
                    }}
                  >
                    Create
                  </Button>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
  renderModal_save_survey() {
    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal_survey_save}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={() => {
          this.setState((prevState) => ({
            modal_survey_save: !prevState.modal_survey_save,
            tab_1_template_select: "",
            tab_1_survey_name: "",
            tab_1_email_required: "",
            tab_1_specify_email_requirment: "",
            tab_1_type_of_email: "",
            tab_1_draw_required: "",
            tab_1_survey_expiry: "",
          }));
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              this.setState((prevState) => ({
                modal_survey_save: !prevState.modal_survey_save,
                tab_1_template_select: "",
                tab_1_survey_name: "",
                tab_1_email_required: "",
                tab_1_specify_email_requirment: "",
                tab_1_type_of_email: "",
                tab_1_draw_required: "",
                tab_1_survey_expiry: "",
              }));
            }}
          >
            Building Survey
          </ModalHeader>
          <ModalBody>
            {this.props.user_details.user_type === "retail" ? (
              <div id="kyc-verify-wizard" className="twitter-bs-wizard">
                {this.render_save_survey_content_retail()}
              </div>
            ) : (
              <div id="kyc-verify-wizard" className="twitter-bs-wizard">
                {this.render_save_survey_content()}
              </div>
            )}
          </ModalBody>
        </div>
      </Modal>
    );
  }

  renderModal_save_template() {
    var regex = /[^\w\s]/g;

    return (
      <Modal
        isOpen={this.state.modal_template_save}
        className="outline_class"
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={() => {
          this.setState((prevState) => ({
            modal_template_save: !prevState.modal_template_save,
            tab_1_template_select: "",
            tab_1_survey_name: "",
            tab_1_email_required: "",
            tab_1_specify_email_requirment: "",
            tab_1_type_of_email: "",
            tab_1_draw_required: "",
            tab_1_survey_expiry: "",
          }));
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              this.setState((prevState) => ({
                modal_template_save: !prevState.modal_template_save,
                tab_1_template_select: "",
                tab_1_survey_name: "",
                tab_1_email_required: "",
                tab_1_specify_email_requirment: "",
                tab_1_type_of_email: "",
                tab_1_draw_required: "",
                tab_1_survey_expiry: "",
              }));
            }}
          >
            Building Survey Template
          </ModalHeader>
          <ModalBody>
            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              <div className="form-group">
                <div>
                  <Label htmlFor="formname">Template Name : </Label>
                  <Input
                    type="text"
                    id="formname"
                    placeholder="Enter Template Name"
                    className="form-control"
                    onChange={(event) => {
                      event.preventDefault();

                      if (event.target.value === "") {
                        this.setState({
                          tab_1_template_select: "",
                          tab_1_survey_name: "",
                          tab_1_email_required: "",
                          tab_1_specify_email_requirment: "",
                          tab_1_type_of_email: "",
                          tab_1_draw_required: "",
                        });
                      } else {
                        this.setState({
                          tab_1_survey_name: event.target.value,
                        });
                      }
                    }}
                  />
                  {this.state.tab_1_survey_name.search(regex) !== -1 ? (
                    <p style={{ color: "#B00020" }}>
                      To continue please avoid using special characters.
                    </p>
                  ) : null}
                </div>

                <div style={{ paddingTop: 10 }}>
                  {this.state.tab_1_survey_name !== "" &&
                  this.state.tab_1_survey_name.search(regex) === -1 ? (
                    <Button
                      type="submit"
                      color="primary"
                      onClick={(event) => {
                        event.preventDefault();

                        const template_name = this.state.tab_1_survey_name;
                        const { questionnaire } = this.state;
                        var regex = /[^\w\s]/g;

                        if (template_name.search(regex) !== -1) {
                          alert("Please avoid using Special Characters.");
                        } else {
                          const draw = true,
                            email_required = true,
                            general_prize_draw = "5",
                            in_person_draw = true,
                            questions = questionnaire,
                            subText =
                              "How did you like the Workshop? Tell us in this super quick survey, and get a chance to win a surprise draw!",
                            surveyFor = "Student",
                            survey_expiry =
                              "Tuesday, September 17, 2019 12:00 AM",
                            type_of_email = "",
                            unique_entry_required = true,
                            user_prize_draw = "5";

                          var data = {
                            template_name,
                            draw,
                            email_required,
                            general_prize_draw,
                            in_person_draw,
                            questions,
                            subText,
                            surveyFor,
                            survey_expiry,
                            type_of_email,
                            unique_entry_required,
                            user_prize_draw,
                          };

                          if (this.props.user_details.user_type === "retail") {
                            data.survey_expiry = "never";
                            data.custom_expiry = "Never Expiry";
                          }

                          firebase
                            .database()
                            .ref(
                              `${this.props.questionnaire_template[0].main_url}/${template_name}/`
                            )
                            .once("value", (snapshot) => {
                              if (snapshot.val() === null) {
                                firebase
                                  .database()
                                  .ref(
                                    `${this.props.questionnaire_template[0].main_url}/${template_name}/`
                                  )
                                  .update(data)
                                  .then(() => {
                                    this.setState((prevState) => ({
                                      modal_template_save: !prevState.modal_template_save,
                                      tab_1_template_select: "",
                                      tab_1_survey_name: "",
                                      tab_1_email_required: "",
                                      tab_1_specify_email_requirment: "",
                                      tab_1_type_of_email: "",
                                      tab_1_draw_required: "",
                                    }));

                                    alert("Template is succesfully created!");
                                    // this.props.current_questionnaire_request();
                                    // this.props.current_question_template_request();
                                    this.props.questionnaire_read();
                                    this.props.questionnaire_template_read();
                                  });
                              } else {
                                alert(
                                  "You already have a Template with the same name, please enter a different name!"
                                );
                              }
                            });
                        }
                      }}
                    >
                      Create
                    </Button>
                  ) : (
                    <Button type="submit" color="primary" disabled>
                      Create
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  render_update_survey_content() {
    const {
      tab_1_template_select,
      tab_1_email_required,
      tab_1_specify_email_requirment,
      tab_1_type_of_email,
      tab_1_draw_required,
      questionaire_details,
    } = this.state;

    var survey_name = questionaire_details.survey_name;
    var tab_1_survey_name = survey_name;

    return (
      <div>
        <div>
          <div className="form-group">
            <Label htmlFor="formname">
              Survey Name :{" "}
              <span style={{ color: "#B00020" }}>(cannot be changed)</span>
            </Label>
            <Input
              type="text"
              id="formname"
              placeholder="Enter Survey Name"
              className="form-control"
              value={survey_name}
              disabled
              onChange={(event) => {
                event.preventDefault();

                if (event.target.value === "") {
                  this.setState({
                    tab_1_email_required: "",
                    tab_1_specify_email_requirment: "",
                    tab_1_type_of_email: "",
                    tab_1_draw_required: "",
                  });
                } else {
                  var temp_details = questionaire_details;

                  temp_details.survey_name = event.target.value;
                  this.setState({
                    tab_1_survey_name: event.target.value,
                    questionaire_details: temp_details,
                  });
                }
              }}
            />
          </div>

          <FormGroup
            onChange={(event) => {
              // event.preventDefault();
              if (event.target.value === "No") {
                this.setState({
                  tab_1_draw_required: "No",
                  tab_1_email_required: "",
                  tab_1_type_of_email: "",
                });
              } else {
                this.setState({
                  tab_1_draw_required: "",
                  tab_1_email_required: "",
                  tab_1_type_of_email: "",
                });
              }
              this.setState({
                tab_1_survey_expiry: event.target.value,
              });
            }}
          >
            <Label className="d-block mb-3">
              Would you like the survey to expire? (By default all surveys
              expire automatically after 24 hours of creating a new session)
            </Label>
            <div className="custom-control custom-radio custom-control-inline">
              <Input
                type="radio"
                id="yesExpiryButton"
                name="customRadioExpiry3"
                className="custom-control-input"
                value="Yes"
              />
              <Label className="custom-control-label" htmlFor="yesExpiryButton">
                Yes
              </Label>
            </div>
            &nbsp;
            <div className="custom-control custom-radio custom-control-inline">
              <Input
                type="radio"
                id="noExpiryButton"
                name="customRadioExpiry3"
                className="custom-control-input"
                value="No"
              />
              <Label className="custom-control-label" htmlFor="noExpiryButton">
                No
              </Label>
            </div>
          </FormGroup>
          {this.state.tab_1_survey_expiry === "" ? (
            <p style={{ color: "#B00020" }}>
              Note: If you select "No", you will not be able to do a prize draw.
            </p>
          ) : null}

          {this.state.tab_1_survey_expiry !== "" ? (
            <div>
              {this.state.tab_1_survey_expiry === "Yes" ? (
                <FormGroup
                  onChange={(event) => {
                    // event.preventDefault();

                    this.setState({
                      tab_1_draw_required: event.target.value,
                    });
                  }}
                >
                  <Label className="d-block mb-3">
                    Would you like a draw/prize at the end of the survey? :
                  </Label>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Input
                      type="radio"
                      id="yesButtonDraw"
                      name="customRadioInline3"
                      className="custom-control-input"
                      value="Yes"
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="yesButtonDraw"
                    >
                      Yes
                    </Label>
                  </div>
                  &nbsp;
                  <div className="custom-control custom-radio custom-control-inline">
                    <Input
                      type="radio"
                      id="noButtonDraw"
                      name="customRadioInline3"
                      className="custom-control-input"
                      value="No"
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="noButtonDraw"
                    >
                      No
                    </Label>
                  </div>
                </FormGroup>
              ) : null}
            </div>
          ) : null}
        </div>
        {tab_1_survey_name !== "" && tab_1_draw_required !== "" ? (
          <div>
            <FormGroup
              onChange={(event) => {
                // event.preventDefault();

                this.setState({
                  tab_1_email_required: event.target.value,
                });
              }}
            >
              <Label className="d-block mb-3">Do you require an email? :</Label>
              <div className="custom-control custom-radio custom-control-inline">
                <Input
                  type="radio"
                  id="yesButton"
                  name="customRadioInline1"
                  className="custom-control-input"
                  value="Yes"
                />
                <Label className="custom-control-label" htmlFor="yesButton">
                  Yes
                </Label>
              </div>
              &nbsp;
              <div className="custom-control custom-radio custom-control-inline">
                <Input
                  type="radio"
                  id="noButton"
                  name="customRadioInline1"
                  className="custom-control-input"
                  value="No"
                />
                <Label className="custom-control-label" htmlFor="noButton">
                  No
                </Label>
              </div>
            </FormGroup>

            {tab_1_email_required === "Yes" && tab_1_email_required !== "" ? (
              <div>
                <FormGroup
                  onChange={(event) => {
                    //   event.preventDefault();

                    this.setState({
                      tab_1_specify_email_requirment: event.target.value,
                    });
                  }}
                >
                  <Label className="d-block mb-3">
                    Do you require specific type of email (e.g ryerson.ca,
                    gmail.com, etc.)? :
                  </Label>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Input
                      type="radio"
                      id="yesButton1"
                      name="customRadioInline2"
                      className="custom-control-input"
                      value="Yes"
                    />
                    <Label
                      className="custom-control-label"
                      htmlFor="yesButton1"
                    >
                      Yes
                    </Label>
                  </div>
                  &nbsp;
                  <div className="custom-control custom-radio custom-control-inline">
                    <Input
                      type="radio"
                      id="noButton2"
                      name="customRadioInline2"
                      className="custom-control-input"
                      value="No"
                    />
                    <Label className="custom-control-label" htmlFor="noButton2">
                      No
                    </Label>
                  </div>
                </FormGroup>

                {tab_1_specify_email_requirment === "Yes" &&
                tab_1_specify_email_requirment !== "" ? (
                  <div>
                    <div className="form-group">
                      <Label htmlFor="formname">Provide email type : </Label>
                      <Input
                        onChange={(event) => {
                          event.preventDefault();
                          this.setState({
                            tab_1_type_of_email: event.target.value,
                          });
                        }}
                        id="formname"
                        placeholder="gmail.com, hotmail.com, ryerson.ca, etc..."
                        className="form-control"
                      />
                    </div>

                    {tab_1_type_of_email === "" ? null : (
                      <Button
                        type="submit"
                        color="primary"
                        onClick={(event) => {
                          event.preventDefault();

                          var questionnaire_template = this.props
                            .questionnaire_template;
                          var template = {
                            questions: this.state.questionnaire,
                          };

                          var survey_name = tab_1_survey_name;
                          var regex = /[^\w\s]/g;

                          if (survey_name.search(regex) !== -1) {
                            alert("Please avoid using Special Characters.");
                          } else {
                            //var template = tab_1_template_select;
                            var draw = "";
                            var email_required = "";
                            var subText = "";
                            var unique_entry_required = "";
                            var type_of_email = tab_1_type_of_email;

                            if (tab_1_draw_required === "Yes") {
                              draw = true;

                              subText =
                                "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                            } else {
                              draw = false;
                              subText =
                                "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                            }

                            if (tab_1_specify_email_requirment === "Yes") {
                              email_required = true;
                            } else {
                              email_required = false;
                            }

                            ///  if (tab_1_email_required === "Yes") {

                            if (
                              tab_1_email_required === "Yes" ||
                              tab_1_draw_required === "Yes"
                              //draw === true
                            ) {
                              unique_entry_required = true;
                            } else {
                              unique_entry_required = false;
                            }

                            if (
                              // questionnaire_template[0].user_details
                              //   .getUserDeatils.user_type === "institute"
                              this.props.user_details.user_type === "institute"
                            ) {
                              template.in_person_draw = draw;
                            } else {
                              template.in_person_draw = false;
                            }

                            if (this.state.tab_1_survey_expiry === "No") {
                              unique_entry_required = false;
                              template.survey_expiry = "never";
                              template.custom_expiry = "Never Expiry";
                            }

                            if (this.state.tab_1_survey_expiry === "Yes") {
                              template.custom_expiry = "";
                            }

                            template.draw = draw;
                            template.email_required = email_required;
                            template.type_of_email = type_of_email;
                            template.subText = subText;
                            template.unique_entry_required = unique_entry_required;
                            template.survey_name = survey_name;

                            template.company_or_institution_name =
                              questionnaire_template[0].company_or_institution_name;
                            template.department =
                              questionnaire_template[0].program_name;

                            var user_prize_draw = "5";
                            var general_prize_draw = "5";
                            template.user_prize_draw = user_prize_draw;
                            template.general_prize_draw = general_prize_draw;

                            if (
                              this.props.user_details.user_type === "retail"
                            ) {
                              template.survey_expiry = "never";
                              template.custom_expiry = "Never Expiry";
                            }

                            var pushID =
                              questionaire_details.Dynamic_Link_Details
                                .survey_id_for_QR_creation_push_id;

                            firebase
                              .database()
                              .ref(
                                `${questionnaire_template[0].questionnaire_url}/${survey_name}/${pushID}`
                              )
                              .update(template)
                              .then(() => {
                                this.setState((prevState) => ({
                                  modal_survey_update: !prevState.modal_survey_update,
                                  tab_1_template_select: "",
                                  tab_1_survey_name: "",
                                  tab_1_email_required: "",
                                  tab_1_specify_email_requirment: "",
                                  tab_1_type_of_email: "",
                                  tab_1_draw_required: "",
                                  tab_1_survey_expiry: "",
                                }));
                                alert("Success!");
                                // this.props.current_questionnaire_request();
                                // this.props.current_question_template_request();
                                this.props.questionnaire_read();
                                this.props.questionnaire_template_read();
                              })
                              .catch((error) => {
                                // console.log(error);
                                alert("Error");
                              });
                          }
                        }}
                      >
                        Create
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    {tab_1_specify_email_requirment === "No" &&
                    tab_1_specify_email_requirment !== "" ? (
                      <Button
                        type="submit"
                        color="primary"
                        onClick={(event) => {
                          event.preventDefault();

                          var questionnaire_template = this.props
                            .questionnaire_template;
                          var template = {
                            questions: this.state.questionnaire,
                          };

                          var survey_name = tab_1_survey_name;
                          var regex = /[^\w\s]/g;

                          if (survey_name.search(regex) !== -1) {
                            alert("Please avoid using Special Characters.");
                          } else {
                            // var template = tab_1_template_select;
                            var draw = "";
                            var email_required = "";
                            var subText = "";
                            var unique_entry_required = "";
                            var type_of_email = tab_1_type_of_email;

                            if (tab_1_draw_required === "Yes") {
                              draw = true;
                              subText =
                                "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                            } else {
                              draw = false;
                              subText =
                                "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                            }

                            if (tab_1_specify_email_requirment === "Yes") {
                              email_required = true;
                            } else {
                              email_required = false;
                            }

                            // if (tab_1_email_required === "Yes") {

                            if (
                              tab_1_email_required === "Yes" ||
                              tab_1_draw_required === "Yes"
                              //draw === true
                            ) {
                              unique_entry_required = true;
                            } else {
                              unique_entry_required = false;
                            }

                            if (
                              // questionnaire_template[0].user_details
                              //   .getUserDeatils.user_type === "institute"
                              this.props.user_details.user_type === "institute"
                            ) {
                              template.in_person_draw = draw;
                            } else {
                              template.in_person_draw = false;
                            }

                            if (this.state.tab_1_survey_expiry === "No") {
                              unique_entry_required = false;
                              template.survey_expiry = "never";
                              template.custom_expiry = "Never Expiry";
                            }

                            if (this.state.tab_1_survey_expiry === "Yes") {
                              template.custom_expiry = "";
                            }

                            template.draw = draw;
                            template.email_required = email_required;
                            template.type_of_email = type_of_email;
                            template.subText = subText;
                            template.unique_entry_required = unique_entry_required;
                            template.survey_name = survey_name;

                            template.company_or_institution_name =
                              questionnaire_template[0].company_or_institution_name;
                            template.department =
                              questionnaire_template[0].program_name;
                            var user_prize_draw = "5";
                            var general_prize_draw = "5";
                            template.user_prize_draw = user_prize_draw;
                            template.general_prize_draw = general_prize_draw;

                            if (
                              this.props.user_details.user_type === "retail"
                            ) {
                              template.survey_expiry = "never";
                              template.custom_expiry = "Never Expiry";
                            }

                            var pushID =
                              questionaire_details.Dynamic_Link_Details
                                .survey_id_for_QR_creation_push_id;

                            firebase
                              .database()
                              .ref(
                                `${questionnaire_template[0].questionnaire_url}/${survey_name}/${pushID}`
                              )
                              .update(template)
                              .then(() => {
                                this.setState((prevState) => ({
                                  modal_survey_update: !prevState.modal_survey_update,
                                  tab_1_template_select: "",
                                  tab_1_survey_name: "",
                                  tab_1_email_required: "",
                                  tab_1_specify_email_requirment: "",
                                  tab_1_type_of_email: "",
                                  tab_1_draw_required: "",
                                  tab_1_survey_expiry: "",
                                }));
                                alert("Success!");
                                // this.props.current_questionnaire_request();
                                // this.props.current_question_template_request();
                                this.props.questionnaire_read();
                                this.props.questionnaire_template_read();
                              })
                              .catch((error) => {
                                // console.log(error);
                                alert("Error");
                              });
                          }
                        }}
                      >
                        Create
                      </Button>
                    ) : null}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {tab_1_email_required === "No" ? (
                  <Button
                    type="submit"
                    color="primary"
                    onClick={(event) => {
                      event.preventDefault();
                      // console.log("THREE");
                      var questionnaire_template = this.props
                        .questionnaire_template;
                      var template = {
                        questions: this.state.questionnaire,
                      };

                      const {
                        tab_1_survey_name,
                        tab_1_email_required,
                        tab_1_specify_email_requirment,
                        tab_1_type_of_email,
                        tab_1_draw_required,
                      } = this.state;

                      // var survey_name = tab_1_survey_name;
                      var regex = /[^\w\s]/g;

                      if (survey_name.search(regex) !== -1) {
                        alert("Please avoid using Special Characters.");
                      } else {
                        var template = {
                          questions: this.state.questionnaire,
                        };
                        // tab_1_template_select;
                        var draw = "";
                        var email_required = "";
                        var subText = "";
                        var unique_entry_required = "";
                        var type_of_email = tab_1_type_of_email;
                        if (tab_1_draw_required === "Yes") {
                          draw = true;
                          subText =
                            "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                        } else {
                          draw = false;
                          subText =
                            "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                        }
                        email_required = false;

                        //  if (tab_1_email_required === "Yes") {
                        if (tab_1_specify_email_requirment === "Yes") {
                          email_required = true;
                        } else {
                          email_required = false;
                        }

                        if (
                          tab_1_email_required === "Yes" ||
                          tab_1_draw_required === "Yes"
                          //draw === true
                        ) {
                          unique_entry_required = true;
                        } else {
                          unique_entry_required = false;
                        }

                        if (
                          // questionnaire_template[0].user_details.getUserDeatils
                          //   .user_type === "institute"
                          this.props.user_details.user_type === "institute"
                        ) {
                          template.in_person_draw = draw;
                        } else {
                          template.in_person_draw = false;
                        }

                        if (this.state.tab_1_survey_expiry === "No") {
                          unique_entry_required = false;
                          template.survey_expiry = "never";
                          template.custom_expiry = "Never Expiry";
                        }

                        if (this.state.tab_1_survey_expiry === "Yes") {
                          template.custom_expiry = "";
                        }

                        template.draw = draw;
                        template.email_required = email_required;
                        template.type_of_email = type_of_email;
                        template.subText = subText;
                        template.unique_entry_required = unique_entry_required;
                        template.survey_name = survey_name;

                        template.company_or_institution_name =
                          questionnaire_template[0].company_or_institution_name;
                        template.department =
                          questionnaire_template[0].program_name;
                        var user_prize_draw = "5";
                        var general_prize_draw = "5";
                        template.user_prize_draw = user_prize_draw;
                        template.general_prize_draw = general_prize_draw;

                        if (this.props.user_details.user_type === "retail") {
                          template.survey_expiry = "never";
                          template.custom_expiry = "Never Expiry";
                        }

                        var pushID =
                          questionaire_details.Dynamic_Link_Details
                            .survey_id_for_QR_creation_push_id;

                        firebase
                          .database()
                          .ref(
                            `${questionnaire_template[0].questionnaire_url}/${survey_name}/${pushID}`
                          )
                          .update(template)
                          .then(() => {
                            this.setState((prevState) => ({
                              modal_survey_update: !prevState.modal_survey_update,
                              tab_1_template_select: "",
                              tab_1_survey_name: "",
                              tab_1_email_required: "",
                              tab_1_specify_email_requirment: "",
                              tab_1_type_of_email: "",
                              tab_1_draw_required: "",
                              tab_1_survey_expiry: "",
                            }));
                            alert("Success!");
                            // this.props.current_questionnaire_request();
                            // this.props.current_question_template_request();
                            this.props.questionnaire_read();
                            this.props.questionnaire_template_read();
                          })
                          .catch((error) => {
                            // console.log(error);
                            alert("Error");
                          });
                      }
                      //DONT REQUIRE EMAIL ADDRESS
                    }}
                  >
                    Create
                  </Button>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
  renderModal_update_survey() {
    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal_survey_update}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={() => {
          this.setState((prevState) => ({
            modal_survey_update: !prevState.modal_survey_update,
            tab_1_template_select: "",
            tab_1_survey_name: "",
            tab_1_email_required: "",
            tab_1_specify_email_requirment: "",
            tab_1_type_of_email: "",
            tab_1_draw_required: "",
            tab_1_survey_expiry: "",
          }));
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              this.setState((prevState) => ({
                modal_survey_update: !prevState.modal_survey_update,
                tab_1_template_select: "",
                tab_1_survey_name: "",
                tab_1_email_required: "",
                tab_1_specify_email_requirment: "",
                tab_1_type_of_email: "",
                tab_1_draw_required: "",
                tab_1_survey_expiry: "",
              }));
            }}
          >
            Building Survey
          </ModalHeader>
          <ModalBody>
            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              {this.render_update_survey_content()}
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  renderModal_update_template() {
    var template_name = this.state.questionaire_details.template_name;
    var tab_1_survey_name = template_name;

    return (
      <Modal
        isOpen={this.state.modal_template_update}
        className="outline_class"
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={() => {
          this.setState((prevState) => ({
            modal_template_update: !prevState.modal_template_update,
            tab_1_template_select: "",
            tab_1_survey_name: "",
            tab_1_email_required: "",
            tab_1_specify_email_requirment: "",
            tab_1_type_of_email: "",
            tab_1_draw_required: "",
            tab_1_survey_expiry: "",
          }));
        }}
      >
        <div className="modal-content">
          <ModalBody>
            <div className="text-center">
              <Row className="justify-content-center">
                <Col
                //xl="5" sm="8"
                >
                  <div>
                    <h1 className="mt-4 font-weight-semibold">Are you sure?</h1>
                    <div className="mt-4">
                      <Row className="justify-content-center">
                        <div style={{ padding: 5 }}>
                          <Button
                            type="button"
                            color="primary"
                            className="btn-lg"
                            onClick={() => {
                              //this.togglemodal_pressed_yes();

                              var data = {
                                questions: this.state.questionnaire,
                              };

                              // console.log(
                              //   `${this.props.questionnaire_template[0].main_url}/${template_name}/`
                              // );

                              firebase
                                .database()
                                .ref(
                                  `${this.props.questionnaire_template[0].main_url}/${template_name}/`
                                )
                                .update(data)
                                .then(() => {
                                  this.setState((prevState) => ({
                                    modal_template_update: !prevState.modal_template_update,
                                    tab_1_template_select: "",
                                    tab_1_survey_name: "",
                                    tab_1_email_required: "",
                                    tab_1_specify_email_requirment: "",
                                    tab_1_type_of_email: "",
                                    tab_1_draw_required: "",
                                  }));

                                  alert("Template is succesfully Updated!");
                                  // this.props.current_questionnaire_request();
                                  // this.props.current_question_template_request();
                                  this.props.questionnaire_read();
                                  this.props.questionnaire_template_read();
                                })
                                .catch(() => {
                                  alert("There was an error!");
                                });
                            }}
                          >
                            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                              Yes
                            </span>
                          </Button>
                        </div>
                        <div style={{ padding: 5 }}>
                          <Button
                            type="button"
                            color="light"
                            className="btn-lg"
                            onClick={() => {
                              this.setState((prevState) => ({
                                modal_template_update: !prevState.modal_template_update,
                              }));
                            }}
                          >
                            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                              Cancel
                            </span>
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  renderNavBar() {
    // var role = "";
    // if (this.props.user_details) {
    //   role = this.props.user_details.role;
    // }
    // if (role === "Facilitator")

    return (
      <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "1",
            })}
            onClick={() => {
              this.toggleTab("1");
              this.setState({
                title: "Build Survey Using Template",

                //////////////
                tab_1_template_select: "",
                tab_1_survey_name: "",
                tab_1_email_required: "",
                tab_1_specify_email_requirment: "",
                tab_1_type_of_email: "",
                tab_1_draw_required: "",
                tab_1_survey_expiry: "",
                tab_1_survey_expiry: "",

                tab_4_survey_name: "",
                tab_5_template_name: "",

                modal_survey_save: false,
                modal_template_save: false,
                template_url: "",

                rows: [],
                show_editing: false,
                custom: false,
                questionnaire: [
                  {
                    0: { question: "" },
                    1: { questionnaire_type: "" },
                  },
                ],
                ////////////////
              });
            }}
          >
            Build Survey Using Template
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "2",
            })}
            onClick={() => {
              this.toggleTab("2");
              this.setState({
                title: "Build New Survey & Template",
                //////////////
                tab_1_template_select: "",
                tab_1_survey_name: "",
                tab_1_email_required: "",
                tab_1_specify_email_requirment: "",
                tab_1_type_of_email: "",
                tab_1_draw_required: "",
                tab_1_survey_expiry: "",

                tab_4_survey_name: "",
                tab_5_template_name: "",

                modal_survey_save: false,
                modal_template_save: false,
                template_url: "",

                rows: [],
                show_editing: false,
                custom: false,
                questionnaire: [
                  {
                    0: { question: "" },
                    1: { questionnaire_type: "" },
                  },
                ],
                ////////////////
              });
            }}
          >
            Build New Survey & Template
          </NavLink>
        </NavItem>
      </ul>
    );
  }

  renderTemplateSection() {
    const { questionnaire, questionnaire_template, loading } = this.props;
    var regex = /[^\w\s]/g;

    if (loading === true) {
      return null;
    } else {
      if (questionnaire_template.length === 0) {
        return (
          <div>
            <div className="outer-repeater">
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <div className="form-group">
                    <p className="formname text-center">
                      There are no saved templates!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      var show_options = [];
      questionnaire_template[0].data_array.forEach((element, i) => {
        show_options.push(
          <option key={i} value={i}>
            {element.template_name}
          </option>
        );
      });

      const {
        tab_1_template_select,
        tab_1_survey_name,
        tab_1_email_required,
        tab_1_specify_email_requirment,
        tab_1_type_of_email,
        tab_1_draw_required,
      } = this.state;

      if (questionnaire_template[0].data_array.length === 0) {
        return (
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group text-center">
                  <p style={{ color: "#B00020" }}>No Template Created Yet!</p>
                  <Button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ activeTab: "2" });
                    }}
                  >
                    Create Template Now!
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      } else {
        /*
          var questionnaire_template = this.props.questionnaire_template;
            var template = {
              questions: this.state.questionnaire,
            };

            var survey_name = tab_1_survey_name;
            var regex = /[^\w\s]/g;

            if (survey_name.search(regex) !== -1) {
              alert("Please avoid using Special Characters.");
            } else {
              template.surveyFor = "Customer";
              template.unique_entry_required = true;
              template.type_of_email = "any email";
              template.survey_type = "retail";
             // template.survey_expiry = "never";
            // if(this.props.user_details.user_type==="retail"){
              template.survey_expiry="never"
              template.custom_expiry="Never Expiry"
              template.subText="Add Something Here for Retail"
           // }
              template.in_person_draw = false;
              template.email_required = false;
              template.instant_draw = true;
              template.giftcard_with_each_feedback = false;
              template.survey_name = survey_name;

              template.draw = false;
              template.company_or_institution_name =
                questionnaire_template[0].company_or_institution_name;
              template.department = questionnaire_template[0].program_name;

              this.process_save_survey_content(template, survey_name);
               */
        if (this.props.user_details.user_type === "retail") {
          return (
            <div>
              <Form className="outer-repeater">
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    <div className="form-group">
                      <Label htmlFor="formname">Choose Template :</Label>
                      <Input
                        type="select"
                        name="type_of_question"
                        id="type_of_question"
                        onChange={(event) => {
                          event.preventDefault();
                          if (event.target.value === "") {
                            this.setState({
                              tab_1_template_select: "",
                            });
                          } else {
                            this.setState({
                              tab_1_template_select:
                                questionnaire_template[0]
                                  .data_array_without_uid[event.target.value],
                            });
                          }
                        }}
                      >
                        <option value="">Select..</option>
                        {show_options}
                      </Input>
                    </div>

                    {tab_1_template_select !== "" ? (
                      <div className="form-group">
                        <Label htmlFor="formname">Survey Name : </Label>
                        <Input
                          type="text"
                          id="formname"
                          placeholder="Enter Survey Name"
                          className="form-control"
                          onChange={(event) => {
                            event.preventDefault();

                            if (event.target.value === "") {
                              this.setState({
                                tab_1_email_required: "",
                                tab_1_specify_email_requirment: "",
                                tab_1_type_of_email: "",
                                tab_1_draw_required: "",
                              });
                            }
                            //else {
                            //                                          special_characters_used:false

                            this.setState({
                              tab_1_survey_name: event.target.value,
                            });
                            // }
                          }}
                        />
                        {tab_1_survey_name.search(regex) !== -1 ? (
                          <p style={{ color: "#B00020" }}>
                            To continue please avoid using special characters.
                          </p>
                        ) : null}

                        <div style={{ paddingTop: 10 }}>
                          {this.state.tab_1_survey_name !== "" ? (
                            <Button
                              type="submit"
                              color="primary"
                              onClick={(event) => {
                                event.preventDefault();
                                var survey_name = this.state.tab_1_survey_name;
                                var regex = /[^\w\s]/g;

                                if (survey_name.search(regex) !== -1) {
                                  alert(
                                    "Please avoid using Special Characters."
                                  );
                                } else {
                                  /*
                                    var template = tab_1_template_select;
                                    var draw = "";
                                    var email_required = "";
                                    var subText = "";
                                    var unique_entry_required = "";
                                    var type_of_email = tab_1_type_of_email;
                                    if (tab_1_draw_required === "Yes") {
                                      draw = true;
                                      subText =
                                        "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                                    } else {
                                      draw = false;
                                      subText =
                                        "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                                    }

                                    email_required = false;

                                    if (
                                      this.state
                                        .tab_1_specify_email_requirment ===
                                      "Yes"
                                    ) {
                                      email_required = true;
                                    } else {
                                      email_required = false;
                                    }

                                    if (
                                      tab_1_email_required === "Yes" ||
                                      tab_1_draw_required === "Yes"
                                      //draw === true
                                    ) {
                                      unique_entry_required = true;
                                    } else {
                                      unique_entry_required = false;
                                    }

                                    if (
                                      questionnaire_template[0].user_details
                                        .getUserDeatils.user_type ===
                                      "institute"
                                    ) {
                                      template.in_person_draw = draw;
                                    } else {
                                      template.in_person_draw = false;
                                    }

                                    template.draw = draw;
                                    template.email_required = email_required;
                                    template.type_of_email = type_of_email;
                                    template.subText = subText;
                                    template.unique_entry_required = unique_entry_required;
                                    template.survey_name = survey_name;

                                    template.company_or_institution_name =
                                      questionnaire_template[0].company_or_institution_name;
                                    template.department =
                                      questionnaire_template[0].program_name;
                                    var user_prize_draw = "5";
                                    var general_prize_draw = "5";
                                    template.user_prize_draw = user_prize_draw;
                                    template.general_prize_draw = general_prize_draw;

                                    if(this.props.user_details.user_type==="retail"){
                                      template.survey_expiry="never"
                                      template.custom_expiry="Never Expiry"
                                    }

                                */

                                  var template = tab_1_template_select;

                                  template.surveyFor = "Customer";
                                  template.unique_entry_required = true;
                                  template.type_of_email = "any email";
                                  template.survey_type = "retail";
                                  template.survey_expiry = "never";
                                  template.custom_expiry = "Never Expiry";
                                  template.subText =
                                    "Add Something Here for Retail";
                                  template.in_person_draw = false;
                                  template.email_required = false;
                                  template.instant_draw = true;
                                  template.giftcard_with_each_feedback = false;
                                  template.survey_name = survey_name;

                                  template.draw = false;
                                  template.company_or_institution_name =
                                    questionnaire_template[0].company_or_institution_name;
                                  template.department =
                                    questionnaire_template[0].program_name;

                                  firebase
                                    .database()
                                    .ref(
                                      `${questionnaire_template[0].questionnaire_url}/${survey_name}`
                                    )
                                    .once("value", (snapshot) => {
                                      if (snapshot.val() === null) {
                                        var surveyID = firebase
                                          .database()
                                          .ref(
                                            `${questionnaire_template[0].questionnaire_url}/${survey_name}`
                                          )
                                          .push().key;

                                        firebase
                                          .database()
                                          .ref(
                                            `${questionnaire_template[0].questionnaire_url}/${survey_name}/${surveyID}`
                                          )
                                          .update(template)
                                          .then(() => {
                                            var deep_link_url =
                                              "https://survey.getluup.com/?survey=" +
                                              surveyID;
                                            var dynamic_link_name =
                                              "surveyID: " + surveyID;
                                            var data = template;

                                            firebase
                                              .database()
                                              .ref(
                                                `QR_Code/survey_id_for_QR_creation/${surveyID}`
                                              )
                                              .update({
                                                surveyID,
                                                data,
                                                deep_link_url,
                                                dynamic_link_name, //, link_part
                                              })
                                              .then(() => {
                                                if (
                                                  this.props.user_details
                                                    .role === "Facilitator"
                                                ) {
                                                  firebase
                                                    .database()
                                                    .ref(
                                                      `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}`
                                                    )
                                                    .once(
                                                      "value",
                                                      (snap_data) => {
                                                        var snap_data_array = _.map(
                                                          snap_data.val(),
                                                          (val, uid) => {
                                                            return {
                                                              ...val,
                                                              uid,
                                                            };
                                                          }
                                                        );

                                                        var user_uid = firebase.auth()
                                                          .currentUser.uid;
                                                        var found = snap_data_array.find(
                                                          (ele) => {
                                                            if (
                                                              ele.user_id ===
                                                              user_uid
                                                            ) {
                                                              return ele;
                                                            }
                                                          }
                                                        );

                                                        firebase
                                                          .database()
                                                          .ref(
                                                            `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}/${found.uid}/added_programs`
                                                          )
                                                          .push({
                                                            access: survey_name,
                                                          })
                                                          .then(() => {
                                                            firebase
                                                              .database()
                                                              .ref(
                                                                `partner/users/${user_uid}/all_programs/${questionnaire_template[0].program_name}/survey_access/${survey_name}`
                                                              )
                                                              .update({
                                                                access: true,
                                                              })
                                                              .then(() => {
                                                                alert(
                                                                  "Success!"
                                                                );
                                                                this.setState({
                                                                  tab_1_template_select:
                                                                    "",
                                                                  tab_1_survey_name:
                                                                    "",
                                                                  tab_1_email_required:
                                                                    "",
                                                                  tab_1_specify_email_requirment:
                                                                    "",
                                                                  tab_1_type_of_email:
                                                                    "",
                                                                  tab_1_draw_required:
                                                                    "",
                                                                });
                                                                // this.props.current_questionnaire_request();
                                                                // this.props.current_question_template_request();
                                                                this.props.questionnaire_read();
                                                                this.props.questionnaire_template_read();
                                                              });
                                                          });
                                                      }
                                                    );
                                                } else {
                                                  alert("Success!");
                                                  this.setState({
                                                    tab_1_template_select: "",
                                                    tab_1_survey_name: "",
                                                    tab_1_email_required: "",
                                                    tab_1_specify_email_requirment:
                                                      "",
                                                    tab_1_type_of_email: "",
                                                    tab_1_draw_required: "",
                                                  });
                                                }
                                              })
                                              .catch((error) => {
                                                // console.log(error);
                                                alert("Error");
                                              });
                                          });
                                      } else {
                                        alert(
                                          "You already have a QR and Survey built!"
                                        );
                                      }
                                    });
                                }
                                //DONT REQUIRE EMAIL ADDRESS
                              }}
                            >
                              Create
                            </Button>
                          ) : (
                            <Button type="submit" color="primary" disabled>
                              Create
                            </Button>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Form>
            </div>
          );
        }
        return (
          <div>
            <Form className="outer-repeater">
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <div className="form-group">
                    <Label htmlFor="formname">Choose Template :</Label>
                    <Input
                      type="select"
                      name="type_of_question"
                      id="type_of_question"
                      onChange={(event) => {
                        event.preventDefault();
                        if (event.target.value === "") {
                          this.setState({
                            tab_1_template_select: "",
                          });
                        } else {
                          this.setState({
                            tab_1_template_select:
                              questionnaire_template[0].data_array_without_uid[
                                event.target.value
                              ],
                          });
                        }
                      }}
                    >
                      <option value="">Select..</option>
                      {show_options}
                    </Input>
                  </div>

                  {tab_1_template_select !== "" ? (
                    <div>
                      <div>
                        <div className="form-group">
                          <Label htmlFor="formname">Survey Name : </Label>
                          <Input
                            type="text"
                            id="formname"
                            placeholder="Enter Survey Name"
                            className="form-control"
                            onChange={(event) => {
                              event.preventDefault();

                              if (event.target.value === "") {
                                this.setState({
                                  tab_1_email_required: "",
                                  tab_1_specify_email_requirment: "",
                                  tab_1_type_of_email: "",
                                  tab_1_draw_required: "",
                                });
                              }
                              //else {
                              //                                          special_characters_used:false

                              this.setState({
                                tab_1_survey_name: event.target.value,
                              });
                              // }
                            }}
                          />
                          {tab_1_survey_name.search(regex) !== -1 ? (
                            <p style={{ color: "#B00020" }}>
                              To continue please avoid using special characters.
                            </p>
                          ) : null}
                        </div>

                        <FormGroup
                          onChange={(event) => {
                            // event.preventDefault();
                            if (event.target.value === "No") {
                              this.setState({
                                tab_1_draw_required: "No",
                                tab_1_email_required: "",
                                tab_1_type_of_email: "",
                              });
                            } else {
                              this.setState({
                                tab_1_draw_required: "",
                                tab_1_email_required: "",
                                tab_1_type_of_email: "",
                              });
                            }
                            this.setState({
                              tab_1_survey_expiry: event.target.value,
                            });
                          }}
                        >
                          <Label className="d-block mb-3">
                            Would you like the survey to expire? (By default all
                            surveys expire automatically after 24 hours of
                            creating a new session)
                          </Label>
                          <div className="custom-control custom-radio custom-control-inline">
                            <Input
                              type="radio"
                              id="yesExpiryButton"
                              name="customRadioExpiry3"
                              className="custom-control-input"
                              value="Yes"
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="yesExpiryButton"
                            >
                              Yes
                            </Label>
                          </div>
                          &nbsp;
                          <div className="custom-control custom-radio custom-control-inline">
                            <Input
                              type="radio"
                              id="noExpiryButton"
                              name="customRadioExpiry3"
                              className="custom-control-input"
                              value="No"
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="noExpiryButton"
                            >
                              No
                            </Label>
                          </div>
                        </FormGroup>
                        {this.state.tab_1_survey_expiry === "" ? (
                          <p style={{ color: "#B00020" }}>
                            Note: If you select "No", you will not be able to do
                            a prize draw.
                          </p>
                        ) : null}

                        {this.state.tab_1_survey_expiry !== "" ? (
                          <div>
                            {this.state.tab_1_survey_expiry === "Yes" ? (
                              <FormGroup
                                onChange={(event) => {
                                  // event.preventDefault();

                                  this.setState({
                                    tab_1_draw_required: event.target.value,
                                  });
                                }}
                              >
                                <Label className="d-block mb-3">
                                  Would you like a draw/prize at the end of the
                                  survey? :
                                </Label>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <Input
                                    type="radio"
                                    id="yesButtonDraw"
                                    name="customRadioInline3"
                                    className="custom-control-input"
                                    value="Yes"
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor="yesButtonDraw"
                                  >
                                    Yes
                                  </Label>
                                </div>
                                &nbsp;
                                <div className="custom-control custom-radio custom-control-inline">
                                  <Input
                                    type="radio"
                                    id="noButtonDraw"
                                    name="customRadioInline3"
                                    className="custom-control-input"
                                    value="No"
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor="noButtonDraw"
                                  >
                                    No
                                  </Label>
                                </div>
                              </FormGroup>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      {tab_1_survey_name !== "" &&
                      tab_1_draw_required !== "" &&
                      tab_1_survey_name.search(regex) === -1 ? (
                        <div>
                          <FormGroup
                            onChange={(event) => {
                              // event.preventDefault();

                              this.setState({
                                tab_1_email_required: event.target.value,
                              });
                            }}
                          >
                            <Label className="d-block mb-3">
                              Do you require an email? :
                            </Label>
                            <div className="custom-control custom-radio custom-control-inline">
                              <Input
                                type="radio"
                                id="yesButton"
                                name="customRadioInline1"
                                className="custom-control-input"
                                value="Yes"
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="yesButton"
                              >
                                Yes
                              </Label>
                            </div>
                            &nbsp;
                            <div className="custom-control custom-radio custom-control-inline">
                              <Input
                                type="radio"
                                id="noButton"
                                name="customRadioInline1"
                                className="custom-control-input"
                                value="No"
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor="noButton"
                              >
                                No
                              </Label>
                            </div>
                          </FormGroup>

                          {tab_1_email_required === "Yes" &&
                          tab_1_email_required !== "" ? (
                            <div>
                              <FormGroup
                                onChange={(event) => {
                                  //   event.preventDefault();

                                  this.setState({
                                    tab_1_specify_email_requirment:
                                      event.target.value,
                                  });
                                }}
                              >
                                <Label className="d-block mb-3">
                                  Do you require specific type of email (e.g
                                  ryerson.ca, gmail.com, etc.)? :
                                </Label>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <Input
                                    type="radio"
                                    id="yesButton1"
                                    name="customRadioInline2"
                                    className="custom-control-input"
                                    value="Yes"
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor="yesButton1"
                                  >
                                    Yes
                                  </Label>
                                </div>
                                &nbsp;
                                <div className="custom-control custom-radio custom-control-inline">
                                  <Input
                                    type="radio"
                                    id="noButton2"
                                    name="customRadioInline2"
                                    className="custom-control-input"
                                    value="No"
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor="noButton2"
                                  >
                                    No
                                  </Label>
                                </div>
                              </FormGroup>

                              {tab_1_specify_email_requirment === "Yes" &&
                              tab_1_specify_email_requirment !== "" ? (
                                <div>
                                  <div className="form-group">
                                    <Label htmlFor="formname">
                                      Provide email type :{" "}
                                    </Label>
                                    <Input
                                      onChange={(event) => {
                                        event.preventDefault();
                                        this.setState({
                                          tab_1_type_of_email:
                                            event.target.value,
                                        });
                                      }}
                                      id="formname"
                                      placeholder="gmail.com, hotmail.com, ryerson.ca, etc..."
                                      className="form-control"
                                    />
                                  </div>

                                  {tab_1_type_of_email === "" ? null : (
                                    <Button
                                      type="submit"
                                      color="primary"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        var survey_name = tab_1_survey_name;
                                        var regex = /[^\w\s]/g;

                                        if (survey_name.search(regex) !== -1) {
                                          alert(
                                            "Please avoid using Special Characters."
                                          );
                                        } else {
                                          var template = tab_1_template_select;
                                          var draw = "";
                                          var email_required = "";
                                          var subText = "";
                                          var unique_entry_required = "";
                                          var type_of_email = tab_1_type_of_email;

                                          if (tab_1_draw_required === "Yes") {
                                            draw = true;
                                            subText =
                                              "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                                          } else {
                                            draw = false;
                                            subText =
                                              "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                                          }

                                          if (
                                            tab_1_specify_email_requirment ===
                                            "Yes"
                                          ) {
                                            email_required = true;
                                          } else {
                                            email_required = false;
                                          }

                                          if (
                                            tab_1_email_required === "Yes" ||
                                            tab_1_draw_required === "Yes"
                                            //draw === true
                                          ) {
                                            unique_entry_required = true;
                                          } else {
                                            unique_entry_required = false;
                                          }

                                          if (
                                            // questionnaire_template[0]
                                            //   .user_details.getUserDeatils
                                            //   .user_type === "institute"
                                            this.props.user_details
                                              .user_type === "institute"
                                          ) {
                                            template.in_person_draw = draw;
                                          } else {
                                            template.in_person_draw = false;
                                          }

                                          if (
                                            this.state.tab_1_survey_expiry ===
                                            "No"
                                          ) {
                                            unique_entry_required = false;
                                            template.survey_expiry = "never";
                                            template.custom_expiry =
                                              "Never Expiry";
                                          }

                                          template.draw = draw;
                                          template.email_required = email_required;
                                          template.type_of_email = type_of_email;
                                          template.subText = subText;
                                          template.unique_entry_required = unique_entry_required;
                                          template.survey_name = survey_name;

                                          template.company_or_institution_name =
                                            questionnaire_template[0].company_or_institution_name;
                                          template.department =
                                            questionnaire_template[0].program_name;
                                          var user_prize_draw = "5";
                                          var general_prize_draw = "5";
                                          template.user_prize_draw = user_prize_draw;
                                          template.general_prize_draw = general_prize_draw;

                                          if (
                                            this.props.user_details
                                              .user_type === "retail"
                                          ) {
                                            template.survey_expiry = "never";
                                            template.custom_expiry =
                                              "Never Expiry";
                                          }

                                          firebase
                                            .database()
                                            .ref(
                                              `${questionnaire_template[0].questionnaire_url}/${survey_name}`
                                            )
                                            .once("value", (snapshot) => {
                                              if (snapshot.val() === null) {
                                                var surveyID = firebase
                                                  .database()
                                                  .ref(
                                                    `${questionnaire_template[0].questionnaire_url}/${survey_name}`
                                                  )
                                                  .push().key;

                                                firebase
                                                  .database()
                                                  .ref(
                                                    `${questionnaire_template[0].questionnaire_url}/${survey_name}/${surveyID}`
                                                  )
                                                  .update(template)
                                                  .then(() => {
                                                    var deep_link_url =
                                                      "https://survey.getluup.com/?survey=" +
                                                      surveyID;
                                                    var dynamic_link_name =
                                                      "surveyID: " + surveyID;
                                                    var data = template;

                                                    firebase
                                                      .database()
                                                      .ref(
                                                        `QR_Code/survey_id_for_QR_creation/${surveyID}`
                                                      )
                                                      .update({
                                                        surveyID,
                                                        data,
                                                        deep_link_url,
                                                        dynamic_link_name, //, link_part
                                                      })
                                                      .then(() => {
                                                        if (
                                                          this.props
                                                            .user_details
                                                            .role ===
                                                          "Facilitator"
                                                        ) {
                                                          firebase
                                                            .database()
                                                            .ref(
                                                              `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}`
                                                            )
                                                            .once(
                                                              "value",
                                                              (snap_data) => {
                                                                var snap_data_array = _.map(
                                                                  snap_data.val(),
                                                                  (
                                                                    val,
                                                                    uid
                                                                  ) => {
                                                                    return {
                                                                      ...val,
                                                                      uid,
                                                                    };
                                                                  }
                                                                );

                                                                var user_uid = firebase.auth()
                                                                  .currentUser
                                                                  .uid;
                                                                var found = snap_data_array.find(
                                                                  (ele) => {
                                                                    if (
                                                                      ele.user_id ===
                                                                      user_uid
                                                                    ) {
                                                                      return ele;
                                                                    }
                                                                  }
                                                                );

                                                                firebase
                                                                  .database()
                                                                  .ref(
                                                                    `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}/${found.uid}/added_programs`
                                                                  )
                                                                  .push({
                                                                    access: survey_name,
                                                                  })
                                                                  .then(() => {
                                                                    firebase
                                                                      .database()
                                                                      .ref(
                                                                        `partner/users/${user_uid}/all_programs/${questionnaire_template[0].program_name}/survey_access/${survey_name}`
                                                                      )
                                                                      .update({
                                                                        access: true,
                                                                      })
                                                                      .then(
                                                                        () => {
                                                                          alert(
                                                                            "Success!"
                                                                          );
                                                                          this.setState(
                                                                            {
                                                                              tab_1_template_select:
                                                                                "",
                                                                              tab_1_survey_name:
                                                                                "",
                                                                              tab_1_email_required:
                                                                                "",
                                                                              tab_1_specify_email_requirment:
                                                                                "",
                                                                              tab_1_type_of_email:
                                                                                "",
                                                                              tab_1_draw_required:
                                                                                "",
                                                                            }
                                                                          );
                                                                          // this.props.current_questionnaire_request();
                                                                          // this.props.current_question_template_request();
                                                                          this.props.questionnaire_read();
                                                                          this.props.questionnaire_template_read();
                                                                        }
                                                                      );
                                                                  });
                                                              }
                                                            );
                                                        } else {
                                                          alert("Success!");
                                                          this.setState({
                                                            tab_1_template_select:
                                                              "",
                                                            tab_1_survey_name:
                                                              "",
                                                            tab_1_email_required:
                                                              "",
                                                            tab_1_specify_email_requirment:
                                                              "",
                                                            tab_1_type_of_email:
                                                              "",
                                                            tab_1_draw_required:
                                                              "",
                                                          });
                                                          // this.props.current_questionnaire_request();
                                                          // this.props.current_question_template_request();
                                                          this.props.questionnaire_read();
                                                          this.props.questionnaire_template_read();
                                                        }

                                                        /*  alert("Success!");

                                                      this.setState({
                                                        tab_1_template_select:
                                                          "",
                                                        tab_1_survey_name: "",
                                                        tab_1_email_required:
                                                          "",
                                                        tab_1_specify_email_requirment:
                                                          "",
                                                        tab_1_type_of_email: "",
                                                        tab_1_draw_required: "",
                                                      });
                                                      */
                                                      })
                                                      .catch((error) => {
                                                        // console.log(error);
                                                        alert("Error");
                                                      });
                                                  });
                                              } else {
                                                alert(
                                                  "You already have a QR and Survey with the same name!"
                                                );
                                              }
                                            });
                                        }
                                      }}
                                    >
                                      Create
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {tab_1_specify_email_requirment === "No" &&
                                  tab_1_specify_email_requirment !== "" ? (
                                    <Button
                                      type="submit"
                                      color="primary"
                                      onClick={(event) => {
                                        event.preventDefault();

                                        var survey_name = tab_1_survey_name;
                                        var regex = /[^\w\s]/g;

                                        if (survey_name.search(regex) !== -1) {
                                          alert(
                                            "Please avoid using Special Characters."
                                          );
                                        } else {
                                          var template = tab_1_template_select;
                                          var draw = "";
                                          var email_required = "";
                                          var subText = "";
                                          var unique_entry_required = "";
                                          var type_of_email = tab_1_type_of_email;

                                          if (tab_1_draw_required === "Yes") {
                                            draw = true;
                                            subText =
                                              "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                                          } else {
                                            draw = false;
                                            subText =
                                              "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                                          }

                                          if (
                                            tab_1_specify_email_requirment ===
                                            "Yes"
                                          ) {
                                            email_required = true;
                                          } else {
                                            email_required = false;
                                          }

                                          if (
                                            tab_1_email_required === "Yes" ||
                                            tab_1_draw_required === "Yes"
                                            //draw === true
                                          ) {
                                            unique_entry_required = true;
                                          } else {
                                            unique_entry_required = false;
                                          }

                                          if (
                                            // questionnaire_template[0]
                                            //   .user_details.getUserDeatils
                                            //   .user_type === "institute"
                                            this.props.user_details
                                              .user_type === "institute"
                                          ) {
                                            template.in_person_draw = draw;
                                          } else {
                                            template.in_person_draw = false;
                                          }

                                          template.draw = draw;
                                          template.email_required = email_required;
                                          template.type_of_email = type_of_email;
                                          template.subText = subText;
                                          template.unique_entry_required = unique_entry_required;
                                          template.survey_name = survey_name;

                                          if (
                                            this.state.tab_1_survey_expiry ===
                                            "No"
                                          ) {
                                            unique_entry_required = false;
                                            template.survey_expiry = "never";
                                            template.custom_expiry =
                                              "Never Expiry";
                                          }

                                          template.company_or_institution_name =
                                            questionnaire_template[0].company_or_institution_name;
                                          template.department =
                                            questionnaire_template[0].program_name;
                                          var user_prize_draw = "5";
                                          var general_prize_draw = "5";
                                          template.user_prize_draw = user_prize_draw;
                                          template.general_prize_draw = general_prize_draw;

                                          if (
                                            this.props.user_details
                                              .user_type === "retail"
                                          ) {
                                            template.survey_expiry = "never";
                                            template.custom_expiry =
                                              "Never Expiry";
                                          }

                                          firebase
                                            .database()
                                            .ref(
                                              `${questionnaire_template[0].questionnaire_url}/${survey_name}`
                                            )
                                            .once("value", (snapshot) => {
                                              if (snapshot.val() === null) {
                                                var surveyID = firebase
                                                  .database()
                                                  .ref(
                                                    `${questionnaire_template[0].questionnaire_url}/${survey_name}`
                                                  )
                                                  .push().key;

                                                firebase
                                                  .database()
                                                  .ref(
                                                    `${questionnaire_template[0].questionnaire_url}/${survey_name}/${surveyID}`
                                                  )
                                                  .update(template)
                                                  .then(() => {
                                                    var deep_link_url =
                                                      "https://survey.getluup.com/?survey=" +
                                                      surveyID;
                                                    var dynamic_link_name =
                                                      "surveyID: " + surveyID;
                                                    var data = template;

                                                    firebase
                                                      .database()
                                                      .ref(
                                                        `QR_Code/survey_id_for_QR_creation/${surveyID}`
                                                      )
                                                      .update({
                                                        surveyID,
                                                        data,
                                                        deep_link_url,
                                                        dynamic_link_name, //, link_part
                                                      })
                                                      .then(() => {
                                                        if (
                                                          this.props
                                                            .user_details
                                                            .role ===
                                                          "Facilitator"
                                                        ) {
                                                          firebase
                                                            .database()
                                                            .ref(
                                                              `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}`
                                                            )
                                                            .once(
                                                              "value",
                                                              (snap_data) => {
                                                                var snap_data_array = _.map(
                                                                  snap_data.val(),
                                                                  (
                                                                    val,
                                                                    uid
                                                                  ) => {
                                                                    return {
                                                                      ...val,
                                                                      uid,
                                                                    };
                                                                  }
                                                                );

                                                                var user_uid = firebase.auth()
                                                                  .currentUser
                                                                  .uid;
                                                                var found = snap_data_array.find(
                                                                  (ele) => {
                                                                    if (
                                                                      ele.user_id ===
                                                                      user_uid
                                                                    ) {
                                                                      return ele;
                                                                    }
                                                                  }
                                                                );

                                                                firebase
                                                                  .database()
                                                                  .ref(
                                                                    `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}/${found.uid}/added_programs`
                                                                  )
                                                                  .push({
                                                                    access: survey_name,
                                                                  })
                                                                  .then(() => {
                                                                    firebase
                                                                      .database()
                                                                      .ref(
                                                                        `partner/users/${user_uid}/all_programs/${questionnaire_template[0].program_name}/survey_access/${survey_name}`
                                                                      )
                                                                      .update({
                                                                        access: true,
                                                                      })
                                                                      .then(
                                                                        () => {
                                                                          alert(
                                                                            "Success!"
                                                                          );
                                                                          this.setState(
                                                                            {
                                                                              tab_1_template_select:
                                                                                "",
                                                                              tab_1_survey_name:
                                                                                "",
                                                                              tab_1_email_required:
                                                                                "",
                                                                              tab_1_specify_email_requirment:
                                                                                "",
                                                                              tab_1_type_of_email:
                                                                                "",
                                                                              tab_1_draw_required:
                                                                                "",
                                                                            }
                                                                          );
                                                                          // this.props.current_questionnaire_request();
                                                                          // this.props.current_question_template_request();
                                                                          this.props.questionnaire_read();
                                                                          this.props.questionnaire_template_read();
                                                                        }
                                                                      );
                                                                  });
                                                              }
                                                            );
                                                        } else {
                                                          alert("Success!");
                                                          this.setState({
                                                            tab_1_template_select:
                                                              "",
                                                            tab_1_survey_name:
                                                              "",
                                                            tab_1_email_required:
                                                              "",
                                                            tab_1_specify_email_requirment:
                                                              "",
                                                            tab_1_type_of_email:
                                                              "",
                                                            tab_1_draw_required:
                                                              "",
                                                          });
                                                        }

                                                        /* alert("Success!");
                                                      this.setState({
                                                        tab_1_template_select:
                                                          "",
                                                        tab_1_survey_name: "",
                                                        tab_1_email_required:
                                                          "",
                                                        tab_1_specify_email_requirment:
                                                          "",
                                                        tab_1_type_of_email: "",
                                                        tab_1_draw_required: "",
                                                      });*/
                                                      })
                                                      .catch((error) => {
                                                        //  console.log(error);
                                                        alert("Error");
                                                      });
                                                  });
                                              } else {
                                                alert(
                                                  "You already have a QR and Survey built!"
                                                );
                                              }
                                            });
                                        }

                                        //DONT REQUIRE SPECIFIC EMAIL ADDRESS
                                      }}
                                    >
                                      Create
                                    </Button>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              {tab_1_email_required === "No" ? (
                                <Button
                                  type="submit"
                                  color="primary"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    var survey_name = tab_1_survey_name;
                                    var regex = /[^\w\s]/g;

                                    if (survey_name.search(regex) !== -1) {
                                      alert(
                                        "Please avoid using Special Characters."
                                      );
                                    } else {
                                      var template = tab_1_template_select;
                                      var draw = "";
                                      var email_required = "";
                                      var subText = "";
                                      var unique_entry_required = "";
                                      var type_of_email = tab_1_type_of_email;
                                      if (tab_1_draw_required === "Yes") {
                                        draw = true;
                                        subText =
                                          "How did you like today’s Session? Tell us in this super quick survey, and get a chance to win a surprise draw!";
                                      } else {
                                        draw = false;
                                        subText =
                                          "How did you like today’s Session? Tell us in this super quick survey, and help us make our future events even better!";
                                      }

                                      email_required = false;

                                      if (
                                        this.state
                                          .tab_1_specify_email_requirment ===
                                        "Yes"
                                      ) {
                                        email_required = true;
                                      } else {
                                        email_required = false;
                                      }

                                      if (
                                        tab_1_email_required === "Yes" ||
                                        tab_1_draw_required === "Yes"
                                        //draw === true
                                      ) {
                                        unique_entry_required = true;
                                      } else {
                                        unique_entry_required = false;
                                      }

                                      if (
                                        // questionnaire_template[0].user_details
                                        //   .getUserDeatils.user_type ===
                                        // "institute"
                                        this.props.user_details.user_type ===
                                        "institute"
                                      ) {
                                        template.in_person_draw = draw;
                                      } else {
                                        template.in_person_draw = false;
                                      }

                                      template.draw = draw;
                                      template.email_required = email_required;
                                      template.type_of_email = type_of_email;
                                      template.subText = subText;
                                      template.unique_entry_required = unique_entry_required;
                                      template.survey_name = survey_name;

                                      if (
                                        this.state.tab_1_survey_expiry === "No"
                                      ) {
                                        unique_entry_required = false;
                                        template.survey_expiry = "never";
                                        template.custom_expiry = "Never Expiry";
                                      }

                                      template.company_or_institution_name =
                                        questionnaire_template[0].company_or_institution_name;
                                      template.department =
                                        questionnaire_template[0].program_name;
                                      var user_prize_draw = "5";
                                      var general_prize_draw = "5";
                                      template.user_prize_draw = user_prize_draw;
                                      template.general_prize_draw = general_prize_draw;

                                      if (
                                        this.props.user_details.user_type ===
                                        "retail"
                                      ) {
                                        template.survey_expiry = "never";
                                        template.custom_expiry = "Never Expiry";
                                      }

                                      //
                                      /*
                                    console.log(this.props.user_details);
                                    console.log(
                                      `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/all_programs/${questionnaire_template[0].program_name}`
                                    );
                                    */
                                      // /all_programs

                                      firebase
                                        .database()
                                        .ref(
                                          `${questionnaire_template[0].questionnaire_url}/${survey_name}`
                                        )
                                        .once("value", (snapshot) => {
                                          if (snapshot.val() === null) {
                                            var surveyID = firebase
                                              .database()
                                              .ref(
                                                `${questionnaire_template[0].questionnaire_url}/${survey_name}`
                                              )
                                              .push().key;

                                            firebase
                                              .database()
                                              .ref(
                                                `${questionnaire_template[0].questionnaire_url}/${survey_name}/${surveyID}`
                                              )
                                              .update(template)
                                              .then(() => {
                                                var deep_link_url =
                                                  "https://survey.getluup.com/?survey=" +
                                                  surveyID;
                                                var dynamic_link_name =
                                                  "surveyID: " + surveyID;
                                                var data = template;

                                                firebase
                                                  .database()
                                                  .ref(
                                                    `QR_Code/survey_id_for_QR_creation/${surveyID}`
                                                  )
                                                  .update({
                                                    surveyID,
                                                    data,
                                                    deep_link_url,
                                                    dynamic_link_name, //, link_part
                                                  })
                                                  .then(() => {
                                                    if (
                                                      this.props.user_details
                                                        .role === "Facilitator"
                                                    ) {
                                                      firebase
                                                        .database()
                                                        .ref(
                                                          `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}`
                                                        )
                                                        .once(
                                                          "value",
                                                          (snap_data) => {
                                                            var snap_data_array = _.map(
                                                              snap_data.val(),
                                                              (val, uid) => {
                                                                return {
                                                                  ...val,
                                                                  uid,
                                                                };
                                                              }
                                                            );

                                                            var user_uid = firebase.auth()
                                                              .currentUser.uid;
                                                            var found = snap_data_array.find(
                                                              (ele) => {
                                                                if (
                                                                  ele.user_id ===
                                                                  user_uid
                                                                ) {
                                                                  return ele;
                                                                }
                                                              }
                                                            );

                                                            firebase
                                                              .database()
                                                              .ref(
                                                                `partner/user_access/${this.props.user_details.user_type}/${this.props.user_details.company_or_institution_name}/${questionnaire_template[0].program_name}/${this.props.user_details.role}/${found.uid}/added_programs`
                                                              )
                                                              .push({
                                                                access: survey_name,
                                                              })
                                                              .then(() => {
                                                                firebase
                                                                  .database()
                                                                  .ref(
                                                                    `partner/users/${user_uid}/all_programs/${questionnaire_template[0].program_name}/survey_access/${survey_name}`
                                                                  )
                                                                  .update({
                                                                    access: true,
                                                                  })
                                                                  .then(() => {
                                                                    alert(
                                                                      "Success!"
                                                                    );
                                                                    this.setState(
                                                                      {
                                                                        tab_1_template_select:
                                                                          "",
                                                                        tab_1_survey_name:
                                                                          "",
                                                                        tab_1_email_required:
                                                                          "",
                                                                        tab_1_specify_email_requirment:
                                                                          "",
                                                                        tab_1_type_of_email:
                                                                          "",
                                                                        tab_1_draw_required:
                                                                          "",
                                                                      }
                                                                    );
                                                                    // this.props.current_questionnaire_request();
                                                                    // this.props.current_question_template_request();
                                                                    this.props.questionnaire_read();
                                                                    this.props.questionnaire_template_read();
                                                                  });
                                                              });
                                                          }
                                                        );
                                                    } else {
                                                      alert("Success!");
                                                      this.setState({
                                                        tab_1_template_select:
                                                          "",
                                                        tab_1_survey_name: "",
                                                        tab_1_email_required:
                                                          "",
                                                        tab_1_specify_email_requirment:
                                                          "",
                                                        tab_1_type_of_email: "",
                                                        tab_1_draw_required: "",
                                                      });
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    // console.log(error);
                                                    alert("Error");
                                                  });
                                              });
                                          } else {
                                            alert(
                                              "You already have a QR and Survey built!"
                                            );
                                          }
                                        });
                                    }
                                    //DONT REQUIRE EMAIL ADDRESS
                                  }}
                                >
                                  Create
                                </Button>
                              ) : null}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </Form>
          </div>
        );
      }
    }
  }

  updateInputValue({
    formSection,
    value,
    index,
    choice_position,
    data_conditional,
  }) {
    if (data_conditional === undefined) {
      if (formSection === "question") {
        const { questionnaire } = this.state;
        const state1 = questionnaire;

        var state2 = [];
        if (state1[index]) {
          state2 = update(state1, {
            [index]: { 0: { $set: { question: value } } },
          });

          this.setState({
            questionnaire: state2,
          });
        } else {
          state2 = {
            0: {
              question: value,
            },
          };

          this.setState((prevState) => ({
            questionnaire: [...prevState.questionnaire, state2],
          }));
        }
      } else if (formSection === "question_type") {
        const { questionnaire } = this.state;
        const state1 = questionnaire;

        var state2 = [];
        if (state1[index]) {
          state2 = update(state1, {
            [index]: { 1: { $set: { questionnaire_type: value } } },
          });

          var temp_test = state2;

          if (
            this.state.questionnaire[index][1].questionnaire_type ===
            "ConditionalMCQ"
          ) {
            temp_test.splice(index + 1, 1);
          }

          if (value === "MCQ") {
            if (!choice_position) {
              choice_position = 0;
            }

            temp_test[index][2] = { [choice_position]: { choice: "" } };
          } else if (value === "ConditionalMCQ") {
            if (!choice_position) {
              choice_position = 0;
            }

            temp_test[index][2] = { [choice_position]: { choice: "" } };

            //  temp_test[index + 1] = [{ skip: true }];
            temp_test.splice(index + 1, 0, [{ skip: true }]);
          } else {
            //  delete

            //  delete

            var temp_copy = state2;
            if (temp_copy[index][2]) {
              temp_copy[index][2] = "undefined";
              var temps = _.map(temp_copy, (val, uid) => {
                return val;
              });

              var new_choice_element = [];
              temps.forEach((element) => {
                // console.log(element);
                if (element[2] === "undefined") {
                  var temp_element = {
                    0: element[0],
                    1: element[1],
                  };
                  new_choice_element.push(temp_element);
                } else {
                  new_choice_element.push(element);
                }
              });

              temp_test = new_choice_element;
            } else {
              //nothing to delete
            }
          }

          this.setState({
            questionnaire: temp_test,
            // questionnaire: state2,
          });
        } else {
          state2 = {
            1: {
              questionnaire_type: value,
            },
          };

          var temp_test = state2;
          if (value === "MCQ") {
            if (!choice_position) {
              choice_position = 0;
            }

            temp_test[2] = { [choice_position]: { choice: "" } };
          } else {
            //  delete
          }

          this.setState((prevState) => ({
            questionnaire: [...prevState.questionnaire, temp_test],
            // questionnaire: [...prevState.questionnaire, state2],
          }));
          // }
        }
      } else if (formSection === "choices") {
        const { questionnaire } = this.state;
        const state1 = questionnaire;

        //  console.log(choice_position);
        if (!choice_position) {
          choice_position = 0;
        }

        if (state1[index]) {
          var tempCheck = _.map(
            this.state.questionnaire[index][2],
            (val, uid) => {
              return val;
            }
          );

          state2 = update(state1, {
            [index]: { 2: { [choice_position]: { $set: { choice: value } } } },
            //  [index]: { 2: { $set: { [choice_position]: { choice: value } } } },
          });

          this.setState({
            questionnaire: state2,
          });
        } else {
          state2 = {
            2: {
              [choice_position]: { choice: "" },
            },
          };

          this.setState((prevState) => ({
            questionnaire: [...prevState.questionnaire, state2],
          }));
        }
      } else {
        //do nothing
      }
    } else {
      if (formSection === "skip_question_selection") {
        var temp_data_for_processing = this.state.questionnaire;

        if (data_conditional.value_selected === "true") {
          //want to skip tthe question
          temp_data_for_processing[index][data_conditional.newidx] = {
            skip: true,
          };
        } else {
          //dont want to skip the question

          temp_data_for_processing[index][data_conditional.newidx] = {
            0: { question: "" },
            1: { questionnaire_type: "" },
          };
        }

        this.setState({
          questionnaire: temp_data_for_processing,
        });
      } else if (formSection === "question") {
        var temp_data_for_processing = this.state.questionnaire;

        temp_data_for_processing[index][
          data_conditional.newidx
        ][0].question = value;

        this.setState({
          questionnaire: temp_data_for_processing,
        });
      } else if (formSection === "question_type") {
        var temp_data_for_processing = this.state.questionnaire;

        if (value === "Open Field") {
          //

          temp_data_for_processing[index][data_conditional.newidx] = {
            0: {
              question:
                temp_data_for_processing[index][data_conditional.newidx][0]
                  .question,
            },
            1: { questionnaire_type: value },
          };
        } else {
          temp_data_for_processing[index][data_conditional.newidx] = {
            0: {
              question:
                temp_data_for_processing[index][data_conditional.newidx][0]
                  .question,
            },
            1: { questionnaire_type: value },

            2: [{ choice: "" }],
          };
        }
        this.setState({
          questionnaire: temp_data_for_processing,
        });
      } else if (formSection === "choices") {
        var temp_data_for_processing = this.state.questionnaire;

        temp_data_for_processing[data_conditional.idx][
          data_conditional.newidx
        ][2][choice_position].choice = value;

        this.setState({
          questionnaire: temp_data_for_processing,
        });
      } else {
        //do nothing
      }
    }
  }
  render_choices_section({ index, data }) {
    const { questionnaire } = this.state;

    // console.log(questionnaire);

    //            temp_test[index + 1] = { [choice_position]: { skip: true } };

    // console.log({ index, data, data_conditional });

    var newitem = [];

    var questionnaire_array = [];
    var show = false;
    if (this.state.questionnaire.length !== 0) {
      if (this.state.questionnaire[index]) {
        if (this.state.questionnaire[index][1]) {
          if (
            this.state.questionnaire[index][1].questionnaire_type === "MCQ" ||
            this.state.questionnaire[index][1].questionnaire_type ===
              "ConditionalMCQ"
          ) {
            show = true;

            questionnaire_array = _.map(
              this.state.questionnaire[index][2],
              (val, uid) => {
                return val;
              }
            );
          }
        }
      }
    }

    if (show === true) {
      data = _.map(data, (val, uid) => {
        return val;
      });
      newitem = data[index + 1];

      return (
        <div>
          <div>
            {this.state.questionnaire[index][2] ? (
              <div>
                {questionnaire_array.map((item, choice_position) => {
                  return (
                    <div>
                      <Row>
                        <Col id="choice1" lg="12" className="form-group">
                          <Label htmlFor="choices">Choice</Label>

                          <InputGroup>
                            <div className="input-group-append">
                              <Button
                                onClick={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                <i
                                  style={{ color: "black" }}
                                  className="mdi mdi-checkbox-blank-circle-outline"
                                ></i>
                              </Button>
                            </div>
                            <Input
                              type="text"
                              id="choices"
                              className="form-control"
                              value={
                                this.state.questionnaire[index][2][
                                  choice_position
                                ].choice
                              }
                              onChange={(event) => {
                                this.updateInputValue({
                                  formSection: "choices",
                                  value: event.target.value,
                                  index,
                                  // choice_position: choice_position + 1,
                                  choice_position: choice_position,
                                });
                              }}
                            />

                            {choice_position !== 0 ||
                            questionnaire_array.length !== 1 ? (
                              <div
                                className="input-group-append"
                                onClick={() => {
                                  var temp_copy = questionnaire;

                                  temp_copy[index][2][
                                    choice_position
                                  ] = undefined;

                                  var temps = _.map(
                                    temp_copy[index][2],
                                    (val, uid) => {
                                      return val;
                                    }
                                  );
                                  var new_choice_element = [];
                                  temps.forEach((element) => {
                                    if (element !== undefined) {
                                      new_choice_element.push(element);
                                    }
                                  });

                                  temp_copy[index][2] = new_choice_element;

                                  this.setState({
                                    questionnaire: temp_copy,
                                  });

                                  //  setTimeout(() => {
                                  if (
                                    questionnaire[index][1]
                                      .questionnaire_type === "ConditionalMCQ"
                                  ) {
                                    var removing_temp_copy =
                                      temp_copy[index + 1];

                                    removing_temp_copy.splice(
                                      choice_position,
                                      1
                                    );

                                    temp_copy[index + 1] = removing_temp_copy;
                                  }

                                  this.setState({
                                    questionnaire: temp_copy,
                                  });
                                }}
                              >
                                <Button type="button" color="danger">
                                  <i className="mdi mdi-minus"></i>
                                </Button>
                              </div>
                            ) : null}

                            {choice_position + 1 ===
                            questionnaire_array.length ? (
                              <div
                                className="input-group-append"
                                onClick={() => {
                                  var temp_test = questionnaire;
                                  temp_test[index][2][[choice_position + 1]] = {
                                    choice: "",
                                  };

                                  //setTimeout(() => {
                                  if (
                                    questionnaire[index][1]
                                      .questionnaire_type === "ConditionalMCQ"
                                  ) {
                                    if (
                                      questionnaire[index + 1] === undefined
                                    ) {
                                      //no conditional question
                                      temp_test[index + 1] = [
                                        { skip: true },
                                        { skip: true },
                                      ];
                                    } else {
                                      temp_test[index + 1][
                                        choice_position + 1
                                      ] = {
                                        skip: true,
                                      };
                                    }
                                  }
                                  //  }, 500);

                                  this.setState({
                                    questionnaire: temp_test,
                                  });
                                }}
                              >
                                <Button type="button" color="primary">
                                  <i className="mdi mdi-plus"></i>
                                </Button>
                              </div>
                            ) : null}

                            <div>
                              {newitem !== undefined &&
                              questionnaire[index][1].questionnaire_type ===
                                "ConditionalMCQ" ? (
                                <div>
                                  {newitem[choice_position].skip !== true ? (
                                    <div
                                      className="input-group-append"
                                      onClick={() => {
                                        this.updateInputValue({
                                          formSection:
                                            "skip_question_selection",
                                          value: "true",
                                          index: index + 1, // same thing as idx LONGEST ONE
                                          data_conditional: {
                                            data: data[index + 1],
                                            value_selected: "true",
                                            newidx: choice_position, //SMALEST NUMBER
                                            newitem: newitem[choice_position],
                                            idx: index + 1, // same thing as index LONGEST ONE
                                          },
                                        });
                                      }}
                                    >
                                      <Button
                                        outline
                                        type="button"
                                        color="danger"
                                      >
                                        Remove Conditional Question
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="input-group-append"
                                      onClick={() => {
                                        //

                                        //this.updateInputValue

                                        // console.log

                                        this.updateInputValue({
                                          formSection:
                                            "skip_question_selection",
                                          value: "false",
                                          index: index + 1, // same thing as idx LONGEST ONE
                                          data_conditional: {
                                            data: data[index + 1],
                                            value_selected: "false",
                                            newidx: choice_position, //SMALEST NUMBER
                                            newitem: newitem[choice_position],
                                            idx: index + 1, // same thing as index LONGEST ONE
                                          },
                                        });
                                      }}
                                    >
                                      <Button
                                        outline
                                        type="button"
                                        color="primary"
                                      >
                                        Add Conditional Question
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      {newitem !== undefined &&
                      questionnaire[index][1].questionnaire_type ===
                        "ConditionalMCQ" ? (
                        <Row style={{ paddingLeft: 25 }}>
                          <Col id="choice1" lg="12" className="form-group">
                            <Form
                              className="repeater"
                              encType="multipart/form-data"
                            >
                              <div>
                                {newitem[choice_position].skip !== true ? (
                                  <Col
                                    style={{
                                      background: "#EDE7F6",
                                      paddingBottom: 15,
                                      borderRadius: 10,
                                      borderWidth: 1,
                                      borderColor: "#fff",
                                    }}
                                    lg="10"
                                  >
                                    <div>
                                      <Row>
                                        <Col lg="8" className="form-group">
                                          <div style={{ paddingTop: 10 }}>
                                            <Label htmlFor="name_conditional">
                                              Question {index + 2}
                                            </Label>
                                            <Input
                                              type="text"
                                              id="name_conditional"
                                              name="untyped-input_conditional"
                                              className="form-control"
                                              value={
                                                newitem[choice_position][0]
                                                  .question
                                              }
                                              onChange={(event) => {
                                                this.updateInputValue({
                                                  formSection: "question",
                                                  value: event.target.value,
                                                  index: index + 1, //idx, //+ 1,
                                                  data_conditional: {
                                                    newidx: choice_position,
                                                    newitem,
                                                    data: data[index + 1],
                                                    idx: index + 1,
                                                  },
                                                });
                                              }}
                                            />
                                          </div>
                                        </Col>

                                        <Col lg="4" className="form-group">
                                          <div style={{ paddingTop: 10 }}>
                                            <Label htmlFor="question_type_conditional">
                                              Question Type :
                                            </Label>
                                            <Input
                                              type="select"
                                              name="question_type_conditional"
                                              id="question_type_conditional"
                                              value={
                                                newitem[choice_position][1]
                                                  .questionnaire_type
                                              }
                                              onChange={(event) => {
                                                if (event.target.value === "") {
                                                  alert(
                                                    "Please select 'Multiple Choice' or 'Open Text' to proceed forward!"
                                                  );
                                                }

                                                this.updateInputValue({
                                                  formSection: "question_type",
                                                  value: event.target.value,
                                                  index: index + 1, //idx, //+ 1,
                                                  data_conditional: {
                                                    newidx: choice_position,
                                                    newitem,
                                                    data: data[index + 1],
                                                    idx: index + 1,
                                                  },
                                                });
                                              }}
                                            >
                                              <option value="">Select..</option>
                                              <option value="MCQ">
                                                Multiple Choice
                                              </option>
                                              <option value="Open Field">
                                                Open Text
                                              </option>
                                            </Input>
                                          </div>
                                        </Col>
                                      </Row>

                                      {this.render_choices_section_for_conditional(
                                        {
                                          index: choice_position, // same thing as idx LONGEST ONE
                                          data: data[index + 1],
                                          data_conditional: {
                                            data: data[index + 1],
                                            newidx: choice_position, //SMALEST NUMBER
                                            newitem: newitem[choice_position],
                                            idx: index + 1, // same thing as index LONGEST ONE
                                          },
                                        }
                                      )}
                                    </div>
                                  </Col>
                                ) : null}
                              </div>
                            </Form>
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }

  render_choices_section_for_conditional({ index, data, data_conditional }) {
    const { questionnaire } = this.state;
    var show = false;

    if (data[index].skip !== true) {
      if (data[index][1].questionnaire_type === "MCQ") {
        show = true;
      }
    }

    if (show === false) {
      return null;
    } else {
      return (
        <div>
          <div>
            {data[index][2] ? (
              <div>
                {data[index][2].map((item, choice_position) => {
                  return (
                    <Col id="choice1" lg="12" className="form-group">
                      <Label htmlFor="choices">Choice</Label>

                      <InputGroup>
                        <div className="input-group-append">
                          <Button
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            <i
                              style={{ color: "black" }}
                              className="mdi mdi-checkbox-blank-circle-outline"
                            ></i>
                          </Button>
                        </div>
                        <Input
                          type="text"
                          id="choices"
                          className="form-control"
                          value={item.choice}
                          onChange={(event) => {
                            this.updateInputValue({
                              formSection: "choices",
                              value: event.target.value,
                              index,
                              choice_position: choice_position,

                              data_conditional,
                            });
                          }}
                        />

                        {choice_position !== 0 ||
                        data[index][2].length !== 1 ? (
                          <div
                            className="input-group-append"
                            onClick={() => {
                              var temp_data_for_processing = this.state
                                .questionnaire;
                              temp_data_for_processing[data_conditional.idx][
                                data_conditional.newidx
                              ][2][choice_position] = undefined;
                              var new_choice_element = [];
                              temp_data_for_processing[data_conditional.idx][
                                data_conditional.newidx
                              ][2].forEach((element) => {
                                if (element !== undefined) {
                                  new_choice_element.push(element);
                                }
                              });
                              temp_data_for_processing[data_conditional.idx][
                                data_conditional.newidx
                              ][2] = new_choice_element;
                              this.setState({
                                questionnaire: temp_data_for_processing,
                              });
                            }}
                          >
                            <Button type="button" color="danger">
                              <i className="mdi mdi-minus"></i>
                            </Button>
                          </div>
                        ) : null}

                        {choice_position + 1 === data[index][2].length ? (
                          <div
                            className="input-group-append"
                            onClick={() => {
                              var temp_data_for_processing = this.state
                                .questionnaire;
                              // console.log(
                              //   temp_data_for_processing[data_conditional.idx][
                              //     data_conditional.newidx
                              //   ][2][choice_position]
                              // );
                              if (
                                temp_data_for_processing[data_conditional.idx][
                                  data_conditional.newidx
                                ][2][choice_position].choice === ""
                              ) {
                                alert(
                                  "Oops! Please type a choice before adding a new one."
                                );
                              } else {
                                temp_data_for_processing[data_conditional.idx][
                                  data_conditional.newidx
                                ][2][choice_position + 1] = {
                                  choice: "",
                                };
                                this.setState({
                                  questionnaire: temp_data_for_processing,
                                });
                              }
                            }}
                          >
                            <Button type="button" color="primary">
                              <i className="mdi mdi-plus"></i>
                            </Button>
                          </div>
                        ) : null}
                      </InputGroup>
                    </Col>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }

  conditional_data_verify(data) {
    var temp_activation = true;

    var temp_activate_submit_button = false;

    data.forEach((element, i) => {
      if (temp_activation !== false) {
        if (element !== undefined) {
          if (element.skip !== undefined) {
            temp_activate_submit_button = true;
          } else if (element[0].question !== undefined) {
            if (element[0].question !== "") {
              if (element[1].questionnaire_type !== "") {
                if (element[1].questionnaire_type === "MCQ") {
                  var empty_temp = false;
                  var temp_data_temp = _.map(element[2], (val, uid) => {
                    return val;
                  });
                  temp_data_temp.forEach((element) => {
                    if (element.choice === "") {
                      empty_temp = true;
                    }
                    if (element.choice === undefined) {
                      empty_temp = true;
                    }
                  });

                  // console.log(empty_temp);
                  // console.log(temp_activation);

                  if (temp_activation !== false) {
                    if (empty_temp === true) {
                      temp_activate_submit_button = false;
                      temp_activation = false;
                    } else {
                      temp_activate_submit_button = true;
                    }
                  }
                } else {
                  temp_activate_submit_button = true;
                }
              } else {
                temp_activate_submit_button = false;
              }
            } else {
              temp_activate_submit_button = false;
            }
          } else {
            //nothing
          }
        }
      }
    });
    return temp_activate_submit_button;
  }

  check_empty_field() {
    var empty_field = false;

    var new_data = _.map(this.state.questionnaire, (val, uid) => {
      return val;
    });

    // console.log(new_data);
    if (this.state.questionnaire.length !== 0) {
      var conditional_data = [];
      var new_array_data_included_everything = [];

      var data = [];
      this.state.questionnaire.forEach((element) => {
        element = _.map(element, (val, uid) => {
          return val;
        });

        if (element[0].question === undefined) {
          conditional_data = conditional_data.concat(element);
        } else {
          data = data.concat(element);
        }
      });

      data = data.concat(conditional_data);

      var new_data = data;
      var secondary_data = [];

      new_data.forEach((element) => {
        if (element.length !== undefined) {
          secondary_data = secondary_data.concat(element);
        } else {
          new_array_data_included_everything.push(element);
        }
      });

      secondary_data.forEach((element) => {
        if (element.length !== undefined) {
          new_array_data_included_everything = new_array_data_included_everything.concat(
            element
          );
        } else {
          new_array_data_included_everything.push(element);
        }
      });

      var last_check_array = [];
      new_array_data_included_everything.forEach((element) => {
        if (Object.keys(element).length > 1) {
          element = _.map(element, (val, uid) => {
            return val;
          });
          last_check_array = last_check_array.concat(element);
        } else {
          last_check_array.push(element);
        }
      });
      //console.log(last_check_array);
      for (var k = 0; k < last_check_array.length; k++) {
        if (last_check_array[k].skip !== true) {
          if (last_check_array[k][0] !== undefined) {
            //   console.log(last_check_array[k]);
            if (Object.keys(last_check_array[k]).length > 0) {
              var new_element = _.map(last_check_array[k], (val, uid) => {
                return val;
              });
              //  console.log(new_element.length);
              for (var q = 0; q < new_element.length; q++) {
                if (last_check_array[k][q].choice === "") {
                  empty_field = true;
                  break;
                }
              }
            }
            if (empty_field === true) {
              break;
            }
          }
          if (last_check_array[k].choice === "") {
            empty_field = true;
          }
          if (last_check_array[k].questionnaire_type === "") {
            empty_field = true;
          }
          if (last_check_array[k].question === "") {
            empty_field = true;
          }
        }
      }
    }

    return empty_field;
  }
  renderBuilderSection() {
    // return null;
    var delete_button = false;
    if (this.state.questionnaire.length > 1) {
      delete_button = true;
    } else {
      delete_button = false;
    }

    if (this.state.questionnaire.length === 2) {
      if (
        this.state.questionnaire[0][1].questionnaire_type === "ConditionalMCQ"
      ) {
        delete_button = false;
      }
    }

    var activate_submit_button = false;
    var temp_activate_submit_button = false;
    var temp_activation = true;

    var result_empty_fields = this.check_empty_field();
    if (result_empty_fields === true) {
      activate_submit_button = false;
    } else {
      activate_submit_button = true;
    }

    var data = _.map(this.state.questionnaire, (val, uid) => {
      return val;
    });

    // console.log(data);
    var last_question_conditional = false;

    return (
      <Row>
        <Col xs="12">
          <table style={{ width: "100%" }}>
            <tbody>
              {data.map((item, idx) => {
                // console.log({ item, idx });

                last_question_conditional = false;

                var questionnaire_type_extracted = "";
                if (idx > 0) {
                  if (data[idx - 1][1] !== undefined) {
                    if (
                      data[idx - 1][1].questionnaire_type === "ConditionalMCQ"
                    ) {
                      last_question_conditional = true;
                    }
                  }
                }

                if (data[idx][1] !== undefined) {
                  if (data[idx][1].questionnaire_type !== undefined) {
                    questionnaire_type_extracted =
                      data[idx][1].questionnaire_type;
                  }
                }

                if (last_question_conditional === true) {
                  return null;
                }
                return (
                  <tr id={"addr" + idx} key={idx}>
                    <td>
                      <Card>
                        <div
                          style={{
                            padding: 1.5,
                            background: "#301b92",
                            borderRadius: 15,
                          }}
                        >
                          <div
                            style={{
                              padding: 20,
                              background: "#FAFAFA",
                              opacity: 3,
                              borderRadius: 15,
                            }}
                          >
                            <Form
                              className="repeater"
                              encType="multipart/form-data"
                            >
                              <div data-repeater-list="group-a">
                                {last_question_conditional !== true ? (
                                  <Row>
                                    <Col lg="10">
                                      <div>
                                        <Row>
                                          <Col lg="8" className="form-group">
                                            <Label htmlFor="name">
                                              Question {idx + 1}
                                            </Label>
                                            <Input
                                              type="text"
                                              id="name"
                                              name="untyped-input"
                                              className="form-control"
                                              value={
                                                this.state.questionnaire[idx][0]
                                                  .question
                                              }
                                              onChange={(event) => {
                                                this.updateInputValue({
                                                  formSection: "question",
                                                  value: event.target.value,
                                                  index: idx, // + 1,
                                                });
                                              }}
                                            />
                                          </Col>

                                          <Col lg="4" className="form-group">
                                            <Label htmlFor="question_type">
                                              Question Type :
                                            </Label>
                                            <Input
                                              type="select"
                                              name="question_type"
                                              id="question_type"
                                              value={
                                                questionnaire_type_extracted
                                              }
                                              onChange={(event) => {
                                                if (event.target.value === "") {
                                                  alert(
                                                    "Please select 'Multiple Choice' or 'Open Text' to proceed forward!"
                                                  );
                                                }
                                                this.updateInputValue({
                                                  formSection: "question_type",
                                                  value: event.target.value,
                                                  index: idx, //+ 1,
                                                });
                                              }}
                                            >
                                              <option value="">Select..</option>
                                              <option value="MCQ">
                                                Multiple Choice
                                              </option>
                                              <option value="Open Field">
                                                Open Text
                                              </option>
                                              <option value="ConditionalMCQ">
                                                Conditional Multiple Choice
                                              </option>
                                            </Input>
                                          </Col>
                                        </Row>

                                        {this.render_choices_section({
                                          index: idx, // + 1,
                                          data,
                                        })}
                                      </div>
                                    </Col>

                                    <Col
                                      lg="2"
                                      className="form-group align-self-center"
                                    >
                                      {delete_button ? (
                                        <Button
                                          // onClick={(e) =>
                                          //    this.handleRemoveRow(e, idx)
                                          //  }

                                          onClick={(event) => {
                                            event.preventDefault();
                                            var temp_delete = data;
                                            if (
                                              temp_delete[idx][1]
                                                .questionnaire_type ===
                                              "ConditionalMCQ"
                                            ) {
                                              temp_delete[idx] = undefined;
                                              temp_delete[idx + 1] = undefined;

                                              var temp_holder = [];
                                              temp_delete.forEach((element) => {
                                                if (element !== undefined) {
                                                  temp_holder.push(element);
                                                }
                                              });
                                            } else {
                                              temp_delete[idx] = undefined;

                                              var temp_holder = [];
                                              temp_delete.forEach((element) => {
                                                if (element !== undefined) {
                                                  temp_holder.push(element);
                                                }
                                              });
                                            }

                                            this.setState({
                                              questionnaire: temp_holder,
                                            });
                                          }}
                                          color="primary"
                                          className="mt-3"
                                          style={{ width: "100%" }}
                                          block
                                        >
                                          Delete Question
                                        </Button>
                                      ) : (
                                        <Button
                                          color="primary"
                                          className="mt-3"
                                          style={{ width: "100%" }}
                                          block
                                          disabled
                                        >
                                          Delete Question
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                ) : null}
                              </div>
                            </Form>
                          </div>
                        </div>
                      </Card>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div style={{ paddingBottom: 15 }} className="text-center">
            <Card className="text-center">
              <div className="text-center">
                <div className="text-center">
                  <div data-repeater-list="group-a">
                    <Row lg="12" className="text-center">
                      <Col
                        lg="12"
                        className="form-group"
                        className="text-center"
                      >
                        <Button
                          onClick={(event) => {
                            event.preventDefault();

                            if (result_empty_fields === true) {
                              alert(
                                "Please ensure no field is empty before adding a new question!"
                              );
                            } else {
                              var add_new_question = {
                                0: { question: "" },
                                1: { questionnaire_type: "" },
                              };

                              var temp_ = this.state.questionnaire;
                              temp_.push(add_new_question);

                              this.setState({
                                questionnaire: temp_,
                              });
                            }
                          }}
                          color="success"
                        >
                          <i className="mdi mdi-plus"></i> <br />
                          ADD QUESTION
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <Row>
            {activate_submit_button === true ? (
              <div>
                <div style={{ padding: 5 }}>
                  <Button
                    // onClick={() => {
                    //   console.log(this.state.questionnaire);
                    //  }}

                    onClick={(event) => {
                      event.preventDefault();

                      this.setState((prevState) => ({
                        modal_survey_save: !prevState.modal_survey_save,
                        tab_1_template_select: "",
                        tab_1_survey_name: "",
                        tab_1_email_required: "",
                        tab_1_specify_email_requirment: "",
                        tab_1_type_of_email: "",
                        tab_1_draw_required: "",
                        tab_1_survey_expiry: "",
                      }));
                    }}
                    color="success"
                  >
                    Build Survey
                  </Button>
                </div>
                <div style={{ padding: 5 }}>
                  <Button
                    //onClick={this.handleAddRow}
                    onClick={(event) => {
                      event.preventDefault();
                      this.setState((prevState) => ({
                        modal_template_save: !prevState.modal_template_save,
                        tab_1_template_select: "",
                        tab_1_survey_name: "",
                        tab_1_email_required: "",
                        tab_1_specify_email_requirment: "",
                        tab_1_type_of_email: "",
                        tab_1_draw_required: "",
                        tab_1_survey_expiry: "",
                      }));
                    }}
                    color="success"
                  >
                    Save Survey Template
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ padding: 5 }}>
                  <Button
                    //onClick={this.handleAddRow}
                    color="success"
                    disabled
                  >
                    Build Survey
                  </Button>
                </div>
                <div style={{ padding: 5 }}>
                  <Button
                    //onClick={this.handleAddRow}
                    color="success"
                    disabled
                  >
                    Save Survey Template
                  </Button>
                </div>

                <p style={{ color: "#B00020" }}>
                  Please ensure no fields are left empty!
                </p>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    );
  }

  show_existing_questionaire_to_edit({ action }) {
    var delete_button = false;
    if (this.state.questionnaire.length > 1) {
      delete_button = true;
    } else {
      delete_button = false;
    }

    if (this.state.questionnaire.length === 2) {
      if (
        this.state.questionnaire[0][1].questionnaire_type === "ConditionalMCQ"
      ) {
        delete_button = false;
      }
    }

    var activate_submit_button = false;
    var temp_activate_submit_button = false;
    var temp_activation = true;
    var data = _.map(this.state.questionnaire, (val, uid) => {
      return val;
    });

    var result_empty_fields = this.check_empty_field();
    if (result_empty_fields === true) {
      activate_submit_button = false;
    } else {
      activate_submit_button = true;
    }

    var last_question_conditional = false;

    return (
      <Row>
        <Col xs="12">
          <table style={{ width: "100%" }}>
            <tbody>
              {data.map((item, idx) => {
                last_question_conditional = false;

                var questionnaire_type_extracted = "";
                if (idx > 0) {
                  if (data[idx - 1][1] !== undefined) {
                    if (
                      data[idx - 1][1].questionnaire_type === "ConditionalMCQ"
                    ) {
                      last_question_conditional = true;
                    }
                  }
                }

                if (data[idx][1] !== undefined) {
                  if (data[idx][1].questionnaire_type !== undefined) {
                    questionnaire_type_extracted =
                      data[idx][1].questionnaire_type;
                  }
                }

                if (last_question_conditional === true) {
                  return null;
                }
                return (
                  <tr id={"addr" + idx} key={idx}>
                    <td>
                      <Card>
                        <div
                          style={{
                            padding: 1.5,
                            background: "#301b92",
                            borderRadius: 15,
                          }}
                        >
                          <div
                            style={{
                              padding: 20,
                              background: "#FAFAFA",
                              opacity: 3,
                              borderRadius: 15,
                            }}
                          >
                            <Form
                              className="repeater"
                              encType="multipart/form-data"
                            >
                              <div data-repeater-list="group-a">
                                {last_question_conditional !== true ? (
                                  <Row>
                                    <Col lg="10">
                                      <div>
                                        <Row>
                                          <Col lg="8" className="form-group">
                                            <Label htmlFor="name">
                                              Question {idx + 1}
                                            </Label>
                                            <Input
                                              type="text"
                                              id="name"
                                              name="untyped-input"
                                              className="form-control"
                                              value={
                                                this.state.questionnaire[idx][0]
                                                  .question
                                              }
                                              onChange={(event) => {
                                                this.updateInputValue({
                                                  formSection: "question",
                                                  value: event.target.value,
                                                  index: idx, // + 1,
                                                });
                                              }}
                                            />
                                          </Col>

                                          <Col lg="4" className="form-group">
                                            <Label htmlFor="question_type">
                                              Question Type :
                                            </Label>
                                            <Input
                                              type="select"
                                              name="question_type"
                                              id="question_type"
                                              value={
                                                questionnaire_type_extracted
                                              }
                                              onChange={(event) => {
                                                if (event.target.value === "") {
                                                  alert(
                                                    "Please select 'Multiple Choice' or 'Open Text' to proceed forward!"
                                                  );
                                                }
                                                this.updateInputValue({
                                                  formSection: "question_type",
                                                  value: event.target.value,
                                                  index: idx, //+ 1,
                                                });
                                              }}
                                            >
                                              <option value="">Select..</option>
                                              <option value="MCQ">
                                                Multiple Choice
                                              </option>
                                              <option value="Open Field">
                                                Open Text
                                              </option>
                                              <option value="ConditionalMCQ">
                                                Conditional Multiple Choice
                                              </option>
                                            </Input>
                                          </Col>
                                        </Row>

                                        {this.render_choices_section({
                                          index: idx,
                                          data,
                                        })}
                                      </div>
                                    </Col>

                                    <Col
                                      lg="2"
                                      className="form-group align-self-center"
                                    >
                                      {delete_button ? (
                                        <Button
                                          // onClick={(e) =>
                                          //    this.handleRemoveRow(e, idx)
                                          //  }

                                          onClick={(event) => {
                                            event.preventDefault();
                                            var temp_delete = data;
                                            if (
                                              temp_delete[idx][1]
                                                .questionnaire_type ===
                                              "ConditionalMCQ"
                                            ) {
                                              temp_delete[idx] = undefined;
                                              temp_delete[idx + 1] = undefined;

                                              var temp_holder = [];
                                              temp_delete.forEach((element) => {
                                                if (element !== undefined) {
                                                  temp_holder.push(element);
                                                }
                                              });
                                            } else {
                                              temp_delete[idx] = undefined;

                                              var temp_holder = [];
                                              temp_delete.forEach((element) => {
                                                if (element !== undefined) {
                                                  temp_holder.push(element);
                                                }
                                              });
                                            }

                                            this.setState({
                                              questionnaire: temp_holder,
                                            });
                                          }}
                                          color="primary"
                                          className="mt-3"
                                          style={{ width: "100%" }}
                                          block
                                        >
                                          Delete Question
                                        </Button>
                                      ) : (
                                        <Button
                                          color="primary"
                                          className="mt-3"
                                          style={{ width: "100%" }}
                                          block
                                          disabled
                                        >
                                          Delete Question
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                ) : null}
                              </div>
                            </Form>
                          </div>
                        </div>
                      </Card>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div style={{ paddingBottom: 15 }} className="text-center">
            <Card className="text-center">
              <div className="text-center">
                <div className="text-center">
                  <div data-repeater-list="group-a">
                    <Row lg="12" className="text-center">
                      <Col
                        lg="12"
                        className="form-group"
                        className="text-center"
                      >
                        <Button
                          onClick={(event) => {
                            event.preventDefault();

                            if (result_empty_fields === true) {
                              alert(
                                "Please ensure no field is empty before adding a new question!"
                              );
                            } else {
                              var add_new_question = {
                                0: { question: "" },
                                1: { questionnaire_type: "" },
                              };

                              var temp_ = this.state.questionnaire;
                              temp_.push(add_new_question);

                              this.setState({
                                questionnaire: temp_,
                              });
                            }
                          }}
                          color="success"
                        >
                          <i className="mdi mdi-plus"></i> <br />
                          ADD QUESTION
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <Row>
            {activate_submit_button === true ? (
              <div>
                {action === "EditSurvey" ? (
                  <div style={{ padding: 5 }}>
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState((prevState) => ({
                          for_: "Update_Survey",
                          modal_survey_update: !prevState.modal_survey_update,
                          tab_1_template_select: "",
                          tab_1_survey_name: "",
                          tab_1_email_required: "",
                          tab_1_specify_email_requirment: "",
                          tab_1_type_of_email: "",
                          tab_1_draw_required: "",
                          tab_1_survey_expiry: "",
                        }));
                      }}
                      color="success"
                    >
                      Update Survey
                    </Button>
                  </div>
                ) : (
                  <div style={{ padding: 5 }}>
                    <Button
                      //onClick={this.handleAddRow}
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState((prevState) => ({
                          for_: "Update_Template",

                          modal_template_update: !prevState.modal_template_update,
                          tab_1_template_select: "",
                          tab_1_survey_name: "",
                          tab_1_email_required: "",
                          tab_1_specify_email_requirment: "",
                          tab_1_type_of_email: "",
                          tab_1_draw_required: "",
                          tab_1_survey_expiry: "",
                        }));
                      }}
                      color="success"
                    >
                      Update Template
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {action === "EditSurvey" ? (
                  <div style={{ padding: 5 }}>
                    <Button
                      //onClick={this.handleAddRow}
                      color="success"
                      disabled
                    >
                      Update Survey
                    </Button>
                  </div>
                ) : (
                  <div style={{ padding: 5 }}>
                    <Button
                      //onClick={this.handleAddRow}
                      color="success"
                      disabled
                    >
                      Update Template
                    </Button>

                    <p style={{ color: "#B00020" }}>
                      Please ensure no fields are left empty!
                    </p>
                  </div>
                )}
              </div>
            )}
          </Row>
        </Col>
      </Row>
    );
  }
  renderEditorSection() {
    const { questionnaire, questionnaire_template, loading } = this.props;
    const { tab_4_survey_name } = this.state;
    if (loading === true) {
      return null;
    } else {
      if (questionnaire.length === 0) {
        return (
          <div>
            <div className="outer-repeater">
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <div className="form-group">
                    <p className="formname text-center">
                      There are no Surveys!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      var show_options = [];
      questionnaire[0].data_array.forEach((element, i) => {
        show_options.push(
          <option
            key={i}
            value={i}
            //value={element.uid}
          >
            {element.uid}
          </option>
        );
      });

      if (questionnaire[0].data_array.length === 0) {
        return (
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group text-center">
                  <p style={{ color: "#B00020" }}>No Surveys Created Yet!</p>
                  <Button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ activeTab: "2" });
                    }}
                  >
                    Create Template Now!
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }

      return (
        <div>
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group">
                  <Label htmlFor="formname">Choose Survey :</Label>
                  <Input
                    type="select"
                    name="type_of_question"
                    id="type_of_question"
                    //  value={this.state.tab_4_survey_name}

                    onChange={(event) => {
                      event.preventDefault();

                      if (event.target.value === "") {
                        this.setState({
                          show_editing: false,
                          custom: false,
                        });
                      } else {
                        var snap = _.map(
                          questionnaire[0].data_array[event.target.value],
                          (val, uid) => {
                            return val;
                          }
                        );

                        if (snap[0].type_of_questionnaire === "branch") {
                          this.setState({
                            show_editing: false,
                            custom: true,
                          });
                        } else {
                          this.setState({
                            questionnaire: snap[0].questions,
                            show_editing: true,
                            questionaire_details: snap[0],
                          });
                        }
                      }
                    }}
                  >
                    <option value="">Select..</option>
                    {show_options}
                  </Input>
                </div>

                {this.state.show_editing === true ? (
                  <div>
                    {this.show_existing_questionaire_to_edit({
                      action: "EditSurvey",
                    })}
                  </div>
                ) : (
                  <div>
                    {this.state.custom === true ? (
                      <div>
                        <p style={{ color: "#B00020" }}>
                          This is a custom survey, please contact Luup Support
                          at support@getluup.com to make any changes!
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </Form>{" "}
        </div>
      );
    }
  }

  renderDeletingSurveySection() {
    const { questionnaire, questionnaire_template, loading } = this.props;
    const { tab_4_survey_name } = this.state;
    if (loading === true) {
      return null;
    } else {
      if (questionnaire.length === 0) {
        return (
          <div>
            <div className="outer-repeater">
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <div className="form-group">
                    <p className="formname text-center">
                      There are no Surveys!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      var show_options = [];
      questionnaire[0].data_array.forEach((element, i) => {
        show_options.push(
          <option key={i} value={element.uid}>
            {element.uid}
          </option>
        );
      });

      if (questionnaire[0].data_array.length === 0) {
        return (
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group text-center">
                  <p style={{ color: "#B00020" }}>No Surveys Created Yet!</p>
                  <Button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ activeTab: "2" });
                    }}
                  >
                    Create Survey Now!
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }

      return (
        <div>
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group">
                  <Label htmlFor="formname">
                    Choose Survey (Note: only QR Code gets deleted, not the
                    responses) :
                  </Label>
                  <Input
                    type="select"
                    name="type_of_question"
                    id="type_of_question"
                    value={this.state.tab_4_survey_name}
                    onChange={(event) => {
                      event.preventDefault();
                      this.setState({ tab_4_survey_name: event.target.value });
                    }}
                  >
                    <option value="">Select..</option>
                    {show_options}
                  </Input>
                </div>

                {tab_4_survey_name !== "" ? (
                  <Button
                    type="submit"
                    color="primary"
                    onClick={(event) => {
                      event.preventDefault();

                      if (tab_4_survey_name !== "") {
                        firebase
                          .database()
                          .ref(
                            `${questionnaire_template[0].questionnaire_url}/${tab_4_survey_name}`
                          )
                          .once("value", (snapshot) => {
                            var snap = _.map(snapshot.val(), (val, uid) => {
                              return val;
                            });

                            if (snap[0].Dynamic_Link_Details) {
                              var Dynamic_Link_Details =
                                snap[0].Dynamic_Link_Details;

                              var QR_Code_Link = Dynamic_Link_Details.QR_Code_Link.split(
                                "https://app.getluup.com/"
                              );
                              QR_Code_Link = QR_Code_Link[1];
                              var survey_id_for_QR_creation_push_id =
                                Dynamic_Link_Details.survey_id_for_QR_creation_push_id;

                              this.setState({
                                modal_request: {
                                  for_: "Delete_Survey",
                                  data: {
                                    QR_Code_Link: `QR_Code/survey_id_search/${QR_Code_Link}`,
                                    survey_id_for_QR_creation_push_id: `QR_Code/survey_id_for_QR_creation/${survey_id_for_QR_creation_push_id}`,
                                    questionnaire_link: `${questionnaire_template[0].questionnaire_url}/${tab_4_survey_name}`,
                                  },
                                },
                              });

                              this.togglemodal();
                            } else {
                              alert("Something went wrong!");
                            }
                          });
                      }
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </div>
            </div>
          </Form>{" "}
        </div>
      );
    }
  }

  renderEditingTemplateSection() {
    const { questionnaire, questionnaire_template, loading } = this.props;
    if (loading === true) {
      return null;
    } else {
      if (questionnaire_template.length === 0) {
        return (
          <div>
            <div className="outer-repeater">
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <div className="form-group">
                    <p className="formname text-center">
                      There are no Templates!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      var show_options = [];
      questionnaire_template[0].data_array.forEach((element, i) => {
        // console.log(element);
        show_options.push(
          <option key={i} value={i}>
            {element.template_name}
          </option>
        );
      });

      if (questionnaire_template[0].data_array.length === 0) {
        return (
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group text-center">
                  <p style={{ color: "#B00020" }}>No Template Created Yet!</p>
                  <Button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ activeTab: "2" });
                    }}
                  >
                    Create Template Now!
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }
      return (
        <div>
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group">
                  <Label htmlFor="formname">Choose Template :</Label>
                  <Input
                    type="select"
                    name="type_of_question"
                    id="type_of_question"
                    onChange={(event) => {
                      event.preventDefault();

                      if (event.target.value === "") {
                        this.setState({
                          show_editing: false,
                          custom: false,
                        });
                      } else {
                        var snap =
                          questionnaire_template[0].data_array[
                            event.target.value
                          ];

                        if (snap.type_of_questionnaire === "branch") {
                          this.setState({
                            show_editing: false,
                            custom: true,
                          });
                        } else {
                          this.setState({
                            questionnaire: snap.questions,
                            questionaire_details: snap,
                            show_editing: true,
                          });
                        }
                      }
                    }}
                  >
                    <option value="">Select..</option>
                    {show_options}
                  </Input>
                </div>

                {this.state.show_editing === true ? (
                  <div>
                    {this.show_existing_questionaire_to_edit({
                      action: "EditTemplate",
                    })}
                  </div>
                ) : (
                  <div>
                    {this.state.custom === true ? (
                      <div>
                        <p style={{ color: "#B00020" }}>
                          This is a custom template, please contact Luup Support
                          at support@getluup.com to make any changes!
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </Form>{" "}
        </div>
      );
    }
  }

  renderDeletingTemplateSection() {
    const { questionnaire, questionnaire_template, loading } = this.props;

    if (loading === true) {
      return null;
    } else {
      if (questionnaire_template.length === 0) {
        return (
          <div>
            <div className="outer-repeater">
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <div className="form-group">
                    <p className="formname text-center">
                      There are no Surveys!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      var show_options = [];
      questionnaire_template[0].data_array.forEach((element, i) => {
        show_options.push(
          <option key={i} value={i}>
            {element.template_name}
          </option>
        );
      });
      if (questionnaire_template[0].data_array.length === 0) {
        return (
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group text-center">
                  <p style={{ color: "#B00020" }}>No Template Created Yet!</p>
                  <Button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ activeTab: "2" });
                    }}
                  >
                    Create Template Now!
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }
      return (
        <div>
          <Form className="outer-repeater">
            <div data-repeater-list="outer-group" className="outer">
              <div data-repeater-item className="outer">
                <div className="form-group">
                  <Label htmlFor="formname">Choose Template :</Label>
                  <Input
                    type="select"
                    name="type_of_question"
                    id="type_of_question"
                    onChange={(event) => {
                      event.preventDefault();

                      var value = event.target.value;

                      // console.log(
                      //   `${questionnaire_template[0].main_url}/${questionnaire_template[0].data_array[value].uid}`
                      // );

                      // console.log(
                      //   `${questionnaire_template[0].questionnaire_url}/${tab_4_survey_name}`
                      // );

                      this.setState({
                        template_url: `${questionnaire_template[0].main_url}/${questionnaire_template[0].data_array[value].uid}`,
                        // questionnaire_template[0].data_array[value].uid,
                      });
                    }}
                  >
                    <option value="">Select..</option>
                    {show_options}
                  </Input>
                </div>

                <Button
                  onClick={(event) => {
                    event.preventDefault();

                    this.setState((prevState) => ({
                      modal_delete_template: !prevState.modal_delete_template,
                    }));
                  }}
                  type="submit"
                  color="primary"
                >
                  Delete
                </Button>
              </div>
            </div>
          </Form>{" "}
        </div>
      );
    }
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
  render_no_access_screen() {
    return <Redirect to={{ pathname: "/" }} />;
  }

  render_option_one() {
    const performance_json = {
      loop: false,
      autoplay: true,
      animationData: require("./JSON/performance.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <Col sm="3">
        <Card
          className="card_hover"
          onClick={() => {
            firebase
              .database()
              .ref("QR_Code/admin_options/question_templates/store_performance")
              .on("value", (snapshot) => {
                this.setState((prevState) => ({
                  modal_pre_built: !prevState.modal_pre_built,
                  pre_built_survey: snapshot.val(),
                }));
              });
          }}
        >
          <CardBody>
            {/* <div className="d-flex align-items-center mb-3">*/}
            <div className="text-center">
              <h5 className="font-size-14 mb-0"> Store Performance Survey</h5>
            </div>
            <div className="text-muted mt-4 text-center">
              <Lottie options={performance_json} height={125} width={125} />
            </div>
            <div className="text-muted mt-4 text-center">
              <p className="text-muted font-size-13">
                Ask Maleeha to write something here. Yeah get this stuff done
                and make Luup a billion dollar company.
                {/*<i className="mdi mdi-chevron-up ml-1 text-success"></i>*/}
              </p>
              <div className="text-center">
                <Button
                  type="button"
                  color="primary"
                  className="btn-lg"
                  /* onClick={() => {
                    this.setState((prevState) => ({
                      modal_pre_built: !prevState.modal_pre_built,
                    }));
                  }}*/
                >
                  <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                    Select
                  </span>
                </Button>
                {/* <span
          className={
            "badge badge-soft-" + report.color + " font-size-12"
          }
        >
          {" "}
          {report.badgeValue}{" "}
        </span>{" "}
        <span className="ml-2 text-truncate">{report.desc}</span>
        */}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
  render_option_two() {
    const covid_json = {
      loop: false,
      autoplay: true,
      animationData: require("./JSON/covid.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Col sm="3">
        <Card
          className="card_hover"
          onClick={() => {
            firebase
              .database()
              .ref("QR_Code/admin_options/question_templates/contact_tracing")
              .on("value", (snapshot) => {
                this.setState((prevState) => ({
                  modal_pre_built: !prevState.modal_pre_built,
                  pre_built_survey: snapshot.val(),
                }));
              });
            //
          }}
        >
          <CardBody>
            {/* <div className="d-flex align-items-center mb-3">*/}
            <div className="text-center">
              <h5 className="font-size-14 mb-0"> Covid Contact Tracing</h5>
            </div>
            <div className="text-muted mt-4 text-center">
              <Lottie options={covid_json} height={125} width={125} />
            </div>
            <div className="text-muted mt-4 text-center">
              <p className="text-muted font-size-13">
                Ask Maleeha to write something here. Yeah get this stuff done
                and make Luup a billion dollar company.
                {/*<i className="mdi mdi-chevron-up ml-1 text-success"></i>*/}
              </p>
              <div className="text-center">
                <Button
                  type="button"
                  color="primary"
                  className="btn-lg"
                  onClick={() => {
                    //     this.togglemodal_pressed_yes();
                  }}
                >
                  <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                    Select
                  </span>
                </Button>
                {/* <span
          className={
            "badge badge-soft-" + report.color + " font-size-12"
          }
        >
          {" "}
          {report.badgeValue}{" "}
        </span>{" "}
        <span className="ml-2 text-truncate">{report.desc}</span>
        */}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
  render_option_three() {
    const survey_json = {
      loop: false,
      autoplay: true,
      animationData: require("./JSON/survey.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Col sm="3">
        <Card
          className="card_hover"
          onClick={() => {
            //this.state.activeTab === "1"

            this.setState({
              activeTab: "2",
              show_main_screen: false,
            });
          }}
        >
          <CardBody>
            {/* <div className="d-flex align-items-center mb-3">*/}
            <div className="text-center">
              <h5 className="font-size-14 mb-0">Build Your Own</h5>
            </div>
            <div className="text-muted mt-4 text-center">
              <Lottie options={survey_json} height={125} width={125} />
            </div>
            <div className="text-muted mt-4 text-center">
              <p className="text-muted font-size-13">
                Ask Maleeha to write something here. Yeah get this stuff done
                and make Luup a billion dollar company.
                {/*<i className="mdi mdi-chevron-up ml-1 text-success"></i>*/}
              </p>
              <div className="text-center">
                <Button
                  type="button"
                  color="primary"
                  className="btn-lg"
                  onClick={() => {
                    //     this.togglemodal_pressed_yes();
                  }}
                >
                  <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                    Select
                  </span>
                </Button>
                {/* <span
          className={
            "badge badge-soft-" + report.color + " font-size-12"
          }
        >
          {" "}
          {report.badgeValue}{" "}
        </span>{" "}
        <span className="ml-2 text-truncate">{report.desc}</span>
        */}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
  render_option_four() {
    const saved_json = {
      loop: false,
      autoplay: true,
      animationData: require("./JSON/saved.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Col sm="3">
        <Card
          className="card_hover"
          onClick={() => {
            //this.state.activeTab === "1"

            this.setState({
              activeTab: "1",
              show_main_screen: false,
            });
          }}
        >
          <CardBody>
            {/* <div className="d-flex align-items-center mb-3">*/}
            <div className="text-center">
              <h5 className="font-size-14 mb-0">Use Saved Templates</h5>
            </div>
            <div className="text-muted mt-4 text-center">
              <Lottie options={saved_json} height={125} width={125} />
            </div>
            <div className="text-muted mt-4 text-center">
              <p className="text-muted font-size-13">
                Ask Maleeha to write something here. Yeah get this stuff done
                and make Luup a billion dollar company.
                {/*<i className="mdi mdi-chevron-up ml-1 text-success"></i>*/}
              </p>
              <div className="text-center">
                <Button
                  type="button"
                  color="primary"
                  className="btn-lg"
                  onClick={() => {
                    //     this.togglemodal_pressed_yes();
                  }}
                >
                  <span style={{ paddingRight: 10, paddingLeft: 10 }}>
                    Select
                  </span>
                </Button>
                {/* <span
          className={
            "badge badge-soft-" + report.color + " font-size-12"
          }
        >
          {" "}
          {report.badgeValue}{" "}
        </span>{" "}
        <span className="ml-2 text-truncate">{report.desc}</span>
        */}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  render() {
    if (this.props.loading === true) {
      return <React.Fragment>{this.render_loading()}</React.Fragment>;
    } else {
      const { user_details } = this.props;

      var showing = false;
      if (user_details) {
        if (user_details.role === "Facilitator") {
          if (
            user_details.survey_builder_access.access === true ||
            user_details.survey_builder_access.access === "true"
          ) {
            showing = true;
          }
        }
        if (
          user_details.role === "Program Manager" ||
          user_details.role === "admin"
        ) {
          showing = true;
        }
      }

      // console.log(showing);
      if (showing === false) {
        // console.log("we here");
        return (
          <React.Fragment>{this.render_no_access_screen()}</React.Fragment>
        );
      } else {
        if (this.state.show_main_screen === true) {
          return (
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title="Survey Builder"
                  breadcrumbItem="Survey Builder"
                />
                {this.renderModal_to_build_survey_from_pre_built()}
                <div className="text-center" style={{ paddingBottom: 10 }}>
                  <h2>Please select one of the following options:</h2>
                </div>
                <Row>
                  {this.render_option_one()}
                  {this.render_option_two()}
                  {this.render_option_three()}
                  {this.render_option_four()}
                </Row>
              </Container>
            </div>
          );
        }

        return (
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                {/* <Breadcrumbs
                  title="Survey Builder"
                  breadcrumbItem="Survey Builder"
                />*/}

                <Row>
                  <Col xs="12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      {/*  <h4 className="mb-0 font-size-18">QR Code</h4>*/}

                      <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          this.setState({
                            show_main_screen: true,
                            activeTab: "",

                            rows: [],
                            show_editing: false,
                            custom: false,
                            modal_delete_template: false,

                            modal_pre_built: false,
                            show_main_screen: true,
                            pre_built_survey: {},
                            questionnaire: [
                              {
                                0: { question: "" },
                                1: { questionnaire_type: "" },
                              },
                            ],
                          });
                        }}
                      >
                        GO BACK
                      </Button>

                      <div className="page-title-right">
                        <Breadcrumb listClassName="m-0">
                          <BreadcrumbItem>Survey Builder</BreadcrumbItem>
                        </Breadcrumb>
                      </div>
                    </div>
                  </Col>
                </Row>

                {this.renderModal()}
                {this.renderModal_delete_template()}
                {this.renderModal_save_survey()}
                {this.renderModal_save_template()}

                {this.renderModal_update_survey()}
                {this.renderModal_update_template()}

                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <h4 className="card-title mb-3">{this.state.title}</h4>

                        {/*this.renderNavBar()*/}

                        <TabContent
                          activeTab={this.state.activeTab}
                          className="p-3"
                        >
                          <TabPane tabId="1" id="render_template_section">
                            {this.renderTemplateSection()}
                          </TabPane>
                          <TabPane tabId="2" id="render_builder_section">
                            {this.renderBuilderSection()}
                          </TabPane>
                          <TabPane tabId="3" id="render_editor_section">
                            {this.renderEditorSection()}
                          </TabPane>
                          <TabPane tabId="4" id="render_deleting_section">
                            {this.renderDeletingSurveySection()}
                          </TabPane>

                          <TabPane tabId="5" id="render_deleting_section">
                            {this.renderEditingTemplateSection()}
                          </TabPane>

                          <TabPane tabId="6" id="render_deleting_section">
                            {this.renderDeletingTemplateSection()}
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        );
      }
    }
  }
}

/*
const mapStatetoProps = (state) => {
  const {
    error,
    questionnaire,
    questionnaire_template,
    loading,
    user_details,
  } = state.Details;

  // const error = state;

  return {
    error,
    questionnaire,
    questionnaire_template,
    loading,
    user_details,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_reader_request,
    current_question_template_request,
    current_questionnaire_request,
  })(Retail_Builder)
);

*/

const mapStatetoProps = (state) => {
  const {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  } = state.Database;

  return {
    user_details,
    surveys_and_attendance,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    questionnaire_read,
    questionnaire_template_read,
  })(withNamespaces()(Retail_Builder))
);
