import _ from "lodash";

import React, { Component } from "react";
import Lottie from "react-lottie";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import MCQ_OpenField from "./MCQ_OpenField";
import MCQ_OpenField_Retail from "./MCQ_OpenField_Retail";
//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  render_page() {
    const {
      user_details,
      archive_surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;
    if (user_details.user_type === "institute") {
      return (
        <MCQ_OpenField
          role={user_details.role}
          data={archive_surveys_and_attendance}
          user_details={user_details}
        />
      );
    }
    return (
      <MCQ_OpenField_Retail
        role={user_details.role}
        data={archive_surveys_and_attendance}
        user_details={user_details}
      />
    );
  }

  process_status() {
    const { user_details } = this.props;

    if (user_details.role === "Facilitator") {
      const { dashboard_access, qr_access_granted, survey_builder_access } =
        user_details;

      if (dashboard_access || qr_access_granted || survey_builder_access) {
        var dash_access = false,
          qr_access = false,
          builder_access = false;

        if (dashboard_access) {
          dash_access = dashboard_access.access;
        }
        if (qr_access_granted) {
          qr_access = qr_access_granted.access;
        }
        if (survey_builder_access) {
          builder_access = survey_builder_access.access;
        }

        if (
          (dash_access === false || dash_access === "false") &&
          (qr_access === false || qr_access === "false") &&
          (builder_access === false || builder_access === "false")
        ) {
          return (
            <div className="page-content">
              <Container fluid>
                <Row className="justify-content-center mt-lg-5">
                  <Col xl="5" sm="8">
                    <Card>
                      <CardBody>
                        <div className="text-center">
                          <Row className="justify-content-center">
                            <Col lg="12">
                              <h4 className="mt-4 font-weight-semibold">
                                Dashboard
                              </h4>
                              <br />
                              <p style={{ color: "#B00020" }}>
                                You do not have permission to access this page,
                                please ask your manager/supervisor for
                                authorization
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        } else {
          if (dash_access === true || dash_access === "true") {
            return (
              <React.Fragment>
                <div>{this.render_page()}</div>
              </React.Fragment>
            );
          } else if (qr_access === true || qr_access === "true") {
            return <Redirect to={{ pathname: "/QR" }} />;
          } else if (builder_access === true || builder_access === "true") {
            return <Redirect to={{ pathname: "/surveyBuilder" }} />;
          } else {
            return (
              <div className="page-content">
                <Container fluid>
                  <Row className="justify-content-center mt-lg-5">
                    <Col xl="5" sm="8">
                      <Card>
                        <CardBody>
                          <div className="text-center">
                            <Row className="justify-content-center">
                              <Col lg="12">
                                <h4 className="mt-4 font-weight-semibold">
                                  Dashboard
                                </h4>
                                <br />
                                <p style={{ color: "#B00020" }}>
                                  You do not have permission to access this
                                  page, please ask your manager/supervisor for
                                  authorization
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          }
        }
      } else {
        return (
          <React.Fragment>
            <div>{this.render_page()}</div>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <div>{this.render_page()}</div>
        </React.Fragment>
      );
    }
  }
  render() {
    const {
      user_details,
      archive_surveys_and_attendance,
      qr_details,
      access,
      questionnaire,
      questionnaire_template,
      error,
      loading,
    } = this.props;
    // console.log({
    //   user_details,
    //   surveys_and_attendance,
    //   qr_details,
    //   access,
    //   questionnaire,
    //   questionnaire_template,
    //   error,
    //   loading,
    // });
    if (loading === true) {
      return (
        <React.Fragment>
          <div>{this.render_loading()}</div>
        </React.Fragment>
      );
    } else {
      if (
        //Object.keys(surveys_and_attendance).length === 0
        // surveys_and_attendance !== null ||
        user_details === null
      ) {
        return (
          <React.Fragment>
            <div>{this.render_loading()}</div>
          </React.Fragment>
        );
      } else {
        if (archive_surveys_and_attendance === null) {
          return (
            <div className="page-content">
              <Container fluid>
                <Row className="justify-content-center mt-lg-5">
                  <Col xl="5" sm="8">
                    <Card>
                      <CardBody>
                        <div className="text-center">
                          <Row className="justify-content-center">
                            <Col lg="12">
                              <h4 className="mt-4 font-weight-semibold">
                                Dashboard
                              </h4>
                              <br />
                              <p style={{ color: "#B00020" }}>
                                No Data Archived Yet!
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        } else {
          if (Object.keys(archive_surveys_and_attendance).length === 0) {
            return (
              <React.Fragment>
                <div>{this.render_loading()}</div>
              </React.Fragment>
            );
          }

          return <React.Fragment>{this.process_status()}</React.Fragment>;
        }
      }

      // this.process_status();
    }
  }
}
const mapStatetoProps = (state) => {
  const {
    user_details,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    archive_surveys_and_attendance,
    loading,
  } = state.Database;

  return {
    user_details,
    qr_details,
    access,
    questionnaire,
    questionnaire_template,
    error,
    archive_surveys_and_attendance,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, null)(withNamespaces()(Dashboard))
);
//
/*


       if (user_details === null) {
        return (
          <React.Fragment>
            <div>{this.render_loading()}</div>
          </React.Fragment>
        );
      } else if (surveys_and_attendance === null) {
        return (
          <div className="page-content">
            <Container fluid>
              <Row className="justify-content-center mt-lg-5">
                <Col xl="5" sm="8">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col lg="12">
                            <h4 className="mt-4 font-weight-semibold">
                              Attendance
                            </h4>
                            <br />
                            <p style={{ color: "#B00020" }}>
                              No Attendance Collected Yet!
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      } 
      
      */
